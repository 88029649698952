import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import {getUsers, initUsersSearch,initUsersToTempSearch} from "../actions";
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardSearchComponent from "../components/CardSearchComponent";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
    },
    mainDiv:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "space-evenly",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "width": "100%",
        [theme.breakpoints.up('md')]: {
            "width": "80%",
        },
    },
    mainDivInfinit:{
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "flex-start",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "width": "100%",
        [theme.breakpoints.up('md')]: {
            "width": "80%",
        },
    },
    coverSpinner:{
        "width": "100%",
        "display": "flex",
        "justifyContent": "center"
    },

});


class SearchResultContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {

        };
        this.componentCleanup = this.componentCleanup.bind(this);
    }

    componentWillMount(){
        if (this.props.usersSearch.nextPage===1){
            this.props.getUsers(1,this.props.auth.apiToken,this.props.history,this.props.userSearchData.data,true)
        }
    }

    componentDidMount(){
        window.addEventListener('beforeunload', this.componentCleanup);
    }

    componentWillUnmount(){
        // this.props.initUsersToTempSearch(this.props.usersSearch.data,this.props.usersSearch.nextPage,this.props.usersSearch.hasMore)
        // this.props.initUsersSearch()
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup() {
        this.props.initUsersSearch()
    }

    render() {
        const { classes,usersSearch } = this.props;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                    <InfiniteScroll
                        className={classes.mainDivInfinit}
                        pageStart={0}
                        loadMore={()=> { usersSearch.data && usersSearch.data.length !== 0 && this.props.getUsers(usersSearch.nextPage,this.props.auth.apiToken,this.props.history,this.props.userSearchData.data,true);}}
                        hasMore={usersSearch.hasMore}
                        loader={<div className={classes.coverSpinner} ><CircularProgress style={{color:"#00EBFF"}} /></div>}
                    >
                        {  usersSearch.data && usersSearch.data.map((user , index) => {
                            return <CardSearchComponent user={user} key={index} history={this.props.history} />
                            })
                        }
                    </InfiniteScroll>

                </div>

            </div>
        );
    }
}

SearchResultContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        usersSearch : state.usersSearch,
        tempUsersSearch : state.tempUsersSearch,
        userSearchData : state.userSearchData,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{getUsers,initUsersSearch,initUsersToTempSearch})(SearchResultContainer));