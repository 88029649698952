import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import BookmarkBorderRoundedIcon from '@material-ui/icons/BookmarkBorderRounded';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import Slide from "@material-ui/core/es/Slide/Slide";
import CloseIcon from '@material-ui/icons/Close';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
    bookmarkUser, changeBottomNavStatus, changeLoadingStatus, initTempToUsers, showUserDetail,
    showUserDetailReset
} from "../actions";
import {baseUrl} from "../constants/constant";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import Tooltip from "@material-ui/core/es/Tooltip/Tooltip";
import ContactPhoneRoundedIcon from '@material-ui/icons/ContactPhoneRounded';


const styles = theme => ( {

    mainDivMobile:{
        "min-height": "92vh",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "width": "100%",
        "paddingBottom": "60px",
        [theme.breakpoints.up('xs')]: { //Iphone
            "display": "flex",
            "fontSize": "11px"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "display": "flex",
            "fontSize": "13px"
        },[theme.breakpoints.up('md')]: {
            "display": "none",
            "width": "80%",
            "fontSize": "13px"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "display": "none",
        }
    },

    mainDivWeb:{
        "width": "100%",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "paddingBottom": "60px",
        "justifyContent": "center",
        "flexDirection": "column",
        [theme.breakpoints.up('xs')]: { //Iphone
            "display": "none",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "display": "none",
        },[theme.breakpoints.up('md')]: {
            "display": "flex",
            "fontSize": "14px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "display": "flex",
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "display": "flex",
            "fontSize": "12px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "display": "flex",
            "fontSize": "15px",
        }

    },

    firstDivProfile:{
        "overflow": "hidden",
        "position": "relative",
        "alignItems": "center",
        "flexDirection": "column",
        "backgroundSize": "cover",
        "justifyContent": "center",
        "backgroundPosition": "center",
        "borderRadius": "5px",
        /*"boxShadow": "inset 0px -37px 20px 0px #222222f2",*/
        "marginBottom": "1%",
        /* "width": "98%",
         "height": "50vh",*/
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "98vw",
            "height": "130vw",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "98vw",
            "height": "130vw",
        },[theme.breakpoints.up('md')]: {
            "width": "98vw",
            "height": "130vw",
        }
        ,
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "19vw",
            "height": "25vw",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "19vw",
            "height": "25vw",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "19vw",
            "height": "25vw",
        }
    },
    detailDivProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "90%",
        "height": "fit-content",
        [theme.breakpoints.up('md')]: {
            "height": "20%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "20%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "25%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "30%",
        }
    },
    secondDetailDivProfile:{
        "display": "flex",
        "width": "100%",
        "justifyContent": "space-around",
    },
    colorOrange:{
        "color": "#ff8630",
    },
    coverDetailDivProfile:{
        "display": "flex",
        "justifyContent": "flex-start",
        "flexDirection": "column",
        "alignItems": "center",
        "width": "100%",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        }
    },
    titleDetailDivProfile:{
        "textAlign": "center",
        "width": "80%",


    },
    aboutMeProfile:{
        "textAlign": "center",
        "width": "80%",
        "marginBottom": "11px",
    },
    detailIconsProfile:{
        "color": "#5accd2"
    },
    socialIconsColorProfile:{
        "color": "#fffbf0",
        [theme.breakpoints.down('lg')]: {//Tab
            "height": "5vh",
            "width": "5vh"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "5vh",
            "width": "3vh"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "5vh",
            "width": "3vh"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "4vh",
            "width": "5vh"
        }
    },
    socialIconsProfile:{
        "display": "flex",
        "marginTop": "1%",
        "flexWrap": "wrap",
    },
    photosTitleProfile:{
        "width": "98%",
        "fontSize": "20px",
        "textAlign": "center",
        "borderRadius": "5px",
        "marginBottom": "5px",
        "backgroundColor": "#333",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    othersPhotosDivProfile:{
        "width": "98%",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "row",
        "justifyContent": "space-evenly",
        "flexWrap": "wrap",
        [theme.breakpoints.up('md')]: {
            "width": "49%",
        },
    },
    othersPhotosProfile:{
        "width": "16vw",
        "height": "22vw",
        "backgroundSize": "cover",
        "backgroundPosition": "center",
        "borderRadius": "1px",
        "margin": "1px 0",
        [theme.breakpoints.down('md')]: {
            "width": "32vw",
            "height": "42vw",
        },
    },
    firstSectionDivProfile:{
        "display": "flex",
        "justifyContent": "space-around",
        "alignItems": "center",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "50%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "50%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "50%",
        }
    },
    detailCardProfileWeb:{
        "height": "100%",
        "display": "flex",
        "justifyContent": "space-evenly",
        "alignItems": "end",
        "flexDirection": "column",
        "& p":{
            "margin": "1px 0"
        },
    },
    socialDivCover:{
        "display": "flex",
        "justifyContent": "space-between",
        "width": "90%"
    },
    appBarDialog: {
        position: 'relative',
        "backgroundColor": "#222222",
        "boxShadow": "0 0 0 0 #222222",
        "paddingTop": "11vh",
        "height": "40px",
        "flexDirection": "row",
        "justifyContent": "flex-end",
    },
    titleDialog: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    containerProfileA: {
        "marginTop": "7px",
        "paddingBottom": "20%",
    },
    coverPhotoDialog: {
        "width": "100%",
        "display": "flex",
        "minHeight": "100%",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "backgroundColor": "#222222",
        "paddingTop": "8vh",
        "paddingBottom": "20%"

    },
    dialogPhotoProfile: {
        "width": "40vw",
        "height": "53vw",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        "boxShadow": "0px 4px 6px 1px black",
        "borderRadius": "5px",
        [theme.breakpoints.up('xs')]: {
            "width": "80vw",
            "height": "106vw",
        },
        [theme.breakpoints.up('sm')]: {//Tab
            "width": "80vw",
            "height": "106vw",
        },
        [theme.breakpoints.up('md')]: {
            "width": "53vw",
            "height": "70vw",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "52vh",
            "height": "70vh",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "52vh",
            "height": "70vh",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "52vh",
            "height": "70vh",
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    categoryDivCover:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "85%",
        "marginBottom": "1vw"
    },
    categoryDivPhoto:{
        "width": "15%",
        "paddingRight": "2%",
        "& img":{
            "width": "3vw",
            [theme.breakpoints.down('md')]: {//Tab
                "width": "12vw",
            },
        }
    },
    categoryDivTitle:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "start",
        "flexDirection": "column",
        "width": "70%",
        "& h3":{
            "margin": "0"
        },
        "& div":{
            "color": "#969090",
            [theme.breakpoints.up('lg')]: {//Tab
                "fontSize": "10px"
            },
            [theme.breakpoints.up('xl')]: {//lap
                "fontSize": "10px"
            },
            [theme.breakpoints.up('xxl')]: {//Llap
                "fontSize": "11px"
            }
        }
    },
    categoryDivView:{
        "display": "flex",
        "flexDirection": "column",
        "width": "15%",
        "justifyContent": "center",
        "alignItems": "center",
        "& h4":{
            "margin": "0"
        },
    },
    resumeDivCover:{
        "display": "flex",
        "justifyContent": "space-around",
        "alignItems": "center",
        "width": "47%",
        "margin": "1% 0 1% 0",
        [theme.breakpoints.down('md')]: {
            "flexDirection": "column",
            "width": "100%",
        }
    },
    resumeDiv:{
        "display": "flex",
        visibility:"hidden",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "51%",
        "backgroundColor": "#333",
        "borderRadius": "5px",
        "border": "1px solid lightgray",
        [theme.breakpoints.down('md')]: {
            "margin": "1% 0",
            "width": "90%",
        }
    },
    resumeDivActive:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "51%",
        "backgroundColor": "#333",
        "borderRadius": "5px",
        "border": "1px solid lightgray",
        [theme.breakpoints.down('md')]: {
            "margin": "1% 0",
            "width": "90%",
        }
    },
    aboutMeDiv:{
        "width": "42%",
        "display": "flex",
        "alignItems": "flex-start",
        "paddingLeft": "1%",
        "borderRadius": "5px",
        "justifyContent": "flex-start",
        "flexDirection": "column",
        "& h5":{
            "margin": "4px 1% 0 0",
            "whiteSpace": "nowrap",
        },
        "& h6":{
            "margin": "0",
            "textAlign": "justify",
        },
        [theme.breakpoints.down('md')]: {
            "margin": "1% 0",
            "width": "90%",
        }
    },
    activeDivCover:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "47%",
        "backgroundColor": "red",
        "borderRadius": "10px"
    },
    skypeIcon:{
        "width": "5vw",
        [theme.breakpoints.up('md')]: {
            "width": "3vh",
        },
        [theme.breakpoints.up('xl')]: {
            "width": "3vh",
        }
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    contactInformationClass:{
        "display": "flex",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "fontSize": "small"
    },
    LL:{},
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class ShowProfileSearchContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            open:false,
            contactInformationFlag:false,
            dialogPhotoSrc:"",
        };
    }

    componentWillMount(){
        this.props.changeLoadingStatus(true)
        this.props.showUserDetail(this.props.match.params.id,this.props.auth.apiToken)
        this.props.changeBottomNavStatus(0,6)
    }

    componentWillUnmount(){
        this.props.showUserDetailReset()
    }

    componentDidMount(){
        // setTimeout(() =>{ this.props.initTempToUsers(this.props.tempUsers.data,this.props.tempUsers.nextPage,this.props.tempUsers.hasMore) }, 5000);

    }

    handleClickOpen = (url) => {
        this.setState({
            open:true,
            dialogPhotoSrc:url,
        })
    };

    handleOpenAboutMe = (text) => {
        this.setState({
            openA:true,
            about_meD:text,
        })
    };

    handleCloseAboutMe = () => {
        this.setState({
            openA:false,
            about_meD:"",
        })
    };

    handleClickGoToSocial = (username,type) => {
        if(username && type==="instagram"){
            window.open(`https://www.instagram.com/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="telegram"){
            window.open(`https://t.me/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="facebook"){
            window.open(`https://m.facebook.com/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="linkedin"){
            window.open(username,"_blank").focus();
        }
        if(username && type==="phone"){
            window.open(`tel:${username}`,"_blank").focus();
        }
        if(username && type==="email"){
            window.open(`mailto: ${username}`,"_blank").focus();
        }
        if(username && type==="youtube"){
            window.open(`https://www.youtube.com/user/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="skype"){
            window.open(`skype:${username}?userinfo`,"_blank").focus();
        }
    };

    handleClose = () => {
        this.setState({
            open:false,
            dialogPhotoSrc:"",
        })
    };

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleClickAddToUserBookmarks = (id) => {
        this.props.changeLoadingStatus(true)
        let bookmarks= this.props.auth.user.userFavoritesId;
        bookmarks.push(id.toString())
        this.props.bookmarkUser(this.removeDuplicates(bookmarks),this.props.auth.apiToken)
    };

    handleClickMinesToUserBookmarks = (id) => {

        this.props.changeLoadingStatus(true)
        let bookmarks=[]
        this.props.auth.user.userFavoritesId.map( (favoriteId, index)=>{
            if(favoriteId!==id.toString()){
                bookmarks.push(favoriteId)
            }
        } )

        this.props.bookmarkUser(this.removeDuplicates(bookmarks),this.props.auth.apiToken)
    };

    removeDuplicates(array){
        let uniques = [];
        array.map( (el)=> {
            if(uniques.includes(el) === false) uniques.push(el);
        } )
        return uniques;
    }

    handleViewResume() {
        const {user} = this.props;
        if(user.cv){
            window.open(baseUrl+`download/${user.id}/resume`, '_blank').focus();
        }
    };

    handleClickViewContactInformation = () => {
        let contactInformationFlag = this.state.contactInformationFlag;
        let contactInformation= document.getElementById(`contactInformation`);


        if (contactInformationFlag===false){

            this.setState({
                contactInformationFlag:true
            })
            contactInformation.style.display= "block"

        }
        if (contactInformationFlag===true){

            this.setState({
                contactInformationFlag:false
            })

            contactInformation.style.display= "none"

        }


    }

    render() {
        const { classes,user,loading } = this.props;
        const { open,dialogPhotoSrc,openA,about_meD } = this.state;

        return (

            <div className={classes.containerProfileA}>

                {
                    user &&
                    <div  className={classes.mainDivMobile}>

                        <div  className={classes.firstDivProfile} style={{backgroundImage:`url('${baseUrl+user.main_photo}')`}}>

                        </div>

                        <div className={classes.coverDetailDivProfile}>

                            <div className={classes.detailDivProfile}>

                                <div className={classes.titleDetailDivProfile}>
                                    <h2> {user.name + " " + user.last_name}</h2>
                                </div>

                            </div>

                            <div className={classes.aboutMeProfile}>
                                <p>
                                    {user.about_me && user.about_me.substring(0, 50)}
                                    {
                                        user.about_me &&  user.about_me.length>50?"...":""
                                    }
                                    {
                                        user.about_me &&  user.about_me.length>50 && <span onClick={()=> {this.handleOpenAboutMe(user.about_me)}} style={{color:"#4887cc","cursor": "pointer"}}>see more</span>
                                    }
                                </p>
                            </div>

                            <div className={classes.categoryDivCover}>

                                <div className={classes.categoryDivPhoto}>
                                    <img src={user.group?baseUrl+user.group.photo:""} alt=""/>
                                </div>
                                <div className={classes.categoryDivTitle}>
                                    <h3>{user.group?user.group.title:"N/a"}</h3>
                                    <div>
                                        {user.subgroups && user.subgroups.length>0 &&  user.subgroups.map( (subgroup,index)=> {return (user.subgroups.length===index+1?subgroup.title:subgroup.title + " - " )} )}
                                        {user.subgroups && user.subgroups.length===0 && "Not selected" }
                                    </div>
                                </div>
                                <div className={classes.categoryDivView}>
                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <VisibilitySharpIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                    <h4>{user.count_view}</h4>
                                </div>

                            </div>

                            <div className={classes.detailDivProfile}>
                                <div className={classes.secondDetailDivProfile}>
                                    <div className={classes.detailLeftCard}>
                                        <div className={classes.detailCardP} >
                                            <p>Country : </p>
                                            <p>City : </p>
                                            <p>Gender: </p>
                                            <p>Age: </p>
                                        </div>
                                    </div>
                                    <div className={classes.detailCenterCard}>
                                        <div className={classes.detailCardP} >
                                            <p  className={classes.colorOrange} >{user.country?user.country.title.substring(0, 13):""}</p>
                                            <p  className={classes.colorOrange} >{user.city?user.city.title:""}</p>
                                            <p  className={classes.colorOrange} >{user.gender?user.gender:"N/A"}</p>
                                            <p  className={classes.colorOrange} >{user.age?user.age+" years":"N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.secondDetailDivProfile}>
                                    <div className={classes.detailLeftCard}>
                                        <div className={classes.detailCardP} >
                                            <p>Hourly Rate : </p>
                                            <p>Daily Rate : </p>
                                            <p>Monthly Rate : </p>
                                            <p>Yearly Rate : </p>
                                        </div>
                                    </div>
                                    <div className={classes.detailCenterCard}>
                                        <div className={classes.detailCardP} >
                                            <p  className={classes.colorOrange} >{user.hourly_rate && user.hourly_rate!=0?user.hourly_rate+" "+ user.currency:"Negotiation"}</p>
                                            <p  className={classes.colorOrange} >{user.daily_rate && user.daily_rate!=0?user.daily_rate+" "+ user.currency:"Negotiation"}</p>
                                            <p  className={classes.colorOrange} >{user.monthly_rate && user.monthly_rate!=0?user.monthly_rate+" "+ user.currency:"Negotiation"}</p>
                                            <p  className={classes.colorOrange} >{user.yearly_rate && user.yearly_rate!=0?user.yearly_rate+" "+ user.currency:"Negotiation"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.socialDivCover}>

                                <div className={classes.socialIconsProfile}>
                                    {
                                        user.facebook &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <FacebookIcon onClick={()=> {this.handleClickGoToSocial(user.facebook,"facebook")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                            </IconButton>
                                        </div>
                                    }

                                    {
                                        user.instagram &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <InstagramIcon onClick={()=> {this.handleClickGoToSocial(user.instagram,"instagram")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                            </IconButton>
                                        </div>
                                    }

                                    {
                                        user.linkedin &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <LinkedInIcon onClick={()=> {this.handleClickGoToSocial(user.linkedin,"linkedin")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                            </IconButton>
                                        </div>
                                    }

                                    {
                                        user.telegram &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <TelegramIcon onClick={()=> {this.handleClickGoToSocial(user.telegram,"telegram")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                            </IconButton>
                                        </div>
                                    }

                                    {
                                        user.youtube &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <YouTubeIcon onClick={()=> {this.handleClickGoToSocial(user.youtube,"youtube")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                            </IconButton>
                                        </div>
                                    }

                                    {
                                        user.skype &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                {
                                                    user.skype &&
                                                    <img onClick={()=> {this.handleClickGoToSocial(user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype-active.png" />
                                                }
                                                {
                                                    !user.skype &&
                                                    <img onClick={()=> {this.handleClickGoToSocial(user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype.png" />
                                                }
                                                </IconButton>
                                        </div>
                                    }

                                    {
                                        user.email &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <EmailRoundedIcon onClick={()=> {this.handleClickGoToSocial(user.email,"email")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                            </IconButton>
                                        </div>
                                    }

                                    {
                                        user.phone_number &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <PhoneRoundedIcon onClick={()=> {this.handleClickGoToSocial(user.phone_number,"phone")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                            </IconButton>
                                        </div>
                                    }

                                </div>

                                <div className={classes.socialIconsProfile}>
                                    {/*<div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <ShareRoundedIcon fontSize={window.innerWidth>1400?"medium":"small"}/>
                                        </IconButton>
                                    </div>*/}
                                    {
                                        this.props.appInitData.userBookmarks.includes(user.id.toString()) &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <BookmarkRoundedIcon onClick={()=> {this.handleClickMinesToUserBookmarks(user.id)}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                            </IconButton>
                                        </div>
                                    }

                                    {
                                        !this.props.appInitData.userBookmarks.includes(user.id.toString()) &&
                                        <div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <BookmarkBorderRoundedIcon onClick={()=> {this.handleClickAddToUserBookmarks(user.id)}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                            </IconButton>
                                        </div>
                                    }

                                </div>
                            </div>

                        </div>

                        <div className={classes.resumeDivCover}>

                            {
                                user.cv_flag==="on" && user.cv!==null &&
                                <div onClick={ ()=> {this.handleViewResume()}} className={classes.resumeDivActive}>
                                    <IconButton  className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <EventNoteIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                    <h4>View resume</h4>
                                </div>
                            }

                        </div>

                        <div className={classes.photosTitleProfile}>
                            <p>
                                Photos
                            </p>
                        </div>

                        <div className={classes.othersPhotosDivProfile}>
                            <div style={{"justifyContent": "flex-start"}}  className={classes.othersPhotosDivProfile}>

                                {
                                    user.photos && user.photos.split(";").map((photo , index) => {
                                        return (
                                            <div key={index} onClick={()=> {this.handleClickOpen(baseUrl+photo)}} className={classes.othersPhotosProfile} style={{backgroundImage:`url('${baseUrl+photo}')`}}></div>
                                        )
                                    })
                                }

                            </div>
                            {
                                !user.photos &&
                                <div><h3>No Photos</h3></div>
                            }
                        </div>

                    </div>
                }


                {
                    user &&
                    <div  className={classes.mainDivWeb}>

                        <div className={classes.firstSectionDivProfile}>

                            <div  className={classes.firstDivProfile} style={{backgroundImage:`url('${baseUrl+user.main_photo}')`}}>

                            </div>

                            <div className={classes.coverDetailDivProfile}>

                                <div className={classes.detailDivProfile}>

                                    <div className={classes.titleDetailDivProfile}>
                                        <h2>{user.name + " " + user.last_name}</h2>
                                    </div>

                                </div>

                                <div className={classes.categoryDivCover}>

                                    <div className={classes.categoryDivPhoto}>
                                        <img src={user.group?baseUrl+user.group.photo:""} alt=""/>
                                    </div>
                                    <div className={classes.categoryDivTitle}>
                                        <h3>{user.group?user.group.title:"N/A"}</h3>
                                        <div>
                                            {user.subgroups && user.subgroups.length>0 &&  user.subgroups.map( (subgroup,index)=> {return (user.subgroups.length===index+1?subgroup.title:subgroup.title + " - " )} )}
                                            {user.subgroups && user.subgroups.length===0 && "Not selected" }
                                        </div>
                                    </div>
                                    <div className={classes.categoryDivView}>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <VisibilitySharpIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                        </IconButton>
                                        <h4>{user.count_view}</h4>
                                    </div>

                                </div>

                                <div className={classes.detailDivProfile}>
                                    <div className={classes.secondDetailDivProfile}>
                                        <div className={classes.detailLeftCard}>
                                            <div className={classes.detailCardProfileWeb} >
                                                <p>Country : </p>
                                                <p>City : </p>
                                                <p>Gender: </p>
                                                <p>Age: </p>
                                                <p>Hourly Rate : </p>
                                                <p>Daily Rate : </p>
                                                <p>Monthly Rate : </p>
                                                <p>Yearly Rate : </p>
                                            </div>
                                        </div>
                                        <div className={classes.detailCenterCard}>
                                            <div className={classes.detailCardProfileWeb} >
                                                <p  className={classes.colorOrange} >{user.country?user.country.title.substring(0, 13):""}</p>
                                                <p  className={classes.colorOrange} >{user.city?user.city.title:""}</p>
                                                <p  className={classes.colorOrange} >{user.gender?user.gender:"N/A"}</p>
                                                <p  className={classes.colorOrange} >{user.age?user.age+" years":"N/A"}</p>
                                                <p  className={classes.colorOrange} >{user.hourly_rate && user.hourly_rate!=0?user.hourly_rate+" "+ user.currency:"Negotiation"}</p>
                                                <p  className={classes.colorOrange} >{user.daily_rate && user.daily_rate!=0?user.daily_rate+" "+ user.currency:"Negotiation"}</p>
                                                <p  className={classes.colorOrange} >{user.monthly_rate && user.monthly_rate!=0?user.monthly_rate+" "+ user.currency:"Negotiation"}</p>
                                                <p  className={classes.colorOrange} >{user.yearly_rate && user.yearly_rate!=0?user.yearly_rate+" "+ user.currency:"Negotiation"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.socialDivCover}>

                                    <div className={classes.socialIconsProfile}>
                                        {
                                            user.facebook &&
                                            <div>
                                                <Tooltip title="Facebook">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        <FacebookIcon onClick={()=> {this.handleClickGoToSocial(user.facebook,"facebook")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            user.instagram &&
                                            <div>
                                                <Tooltip title="Instagram">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        <InstagramIcon onClick={()=> {this.handleClickGoToSocial(user.instagram,"instagram")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            user.linkedin &&
                                            <div>
                                                <Tooltip title="Linkedin">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        <LinkedInIcon onClick={()=> {this.handleClickGoToSocial(user.linkedin,"linkedin")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            user.telegram &&
                                            <div>
                                                <Tooltip title="Telegram">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        <TelegramIcon onClick={()=> {this.handleClickGoToSocial(user.telegram,"telegram")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            user.youtube &&
                                            <div>
                                                <Tooltip title="Youtube">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        <YouTubeIcon onClick={()=> {this.handleClickGoToSocial(user.youtube,"youtube")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            user.skype &&
                                            <div>
                                                <Tooltip title="Skype">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        {
                                                            user.skype &&
                                                            <img onClick={()=> {this.handleClickGoToSocial(user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype-active.png" />
                                                        }
                                                        {
                                                            !user.skype &&
                                                            <img onClick={()=> {this.handleClickGoToSocial(user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype.png" />
                                                        }
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            user.email &&
                                            <div>
                                                <Tooltip title="Send email">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        <EmailRoundedIcon onClick={()=> {this.handleClickGoToSocial(user.email,"email")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            user.phone_number &&
                                            <div>
                                                <Tooltip title="Phone number">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        <PhoneRoundedIcon onClick={()=> {this.handleClickGoToSocial(user.phone_number,"phone")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }

                                        {
                                            (user.phone_number || user.email ) &&
                                            <div>
                                                <Tooltip title="View contact information">
                                                    <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                        <ContactPhoneRoundedIcon onClick={()=> {this.handleClickViewContactInformation()}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                    <div className={classes.socialIconsProfile}>
                                        {/*<div>
                                            <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                <ShareRoundedIcon fontSize={window.innerWidth>1400?"medium":"small"}/>
                                            </IconButton>
                                        </div>*/}

                                        {
                                            this.props.appInitData.userBookmarks.includes(user.id.toString()) &&
                                            <div>
                                                <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                    <BookmarkRoundedIcon onClick={()=> {this.handleClickMinesToUserBookmarks(user.id)}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                                </IconButton>
                                            </div>
                                        }

                                        {
                                            !this.props.appInitData.userBookmarks.includes(user.id.toString()) &&
                                            <div>
                                                <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                                    <BookmarkBorderRoundedIcon onClick={()=> {this.handleClickAddToUserBookmarks(user.id)}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                                </IconButton>
                                            </div>
                                        }
                                    </div>
                                </div>


                            </div>

                        </div>


                        <div className={classes.resumeDivCover}>

                            {
                                user.about_me !==null &&
                                <div  className={classes.aboutMeDiv}>
                                    <h5>About Me :</h5>
                                    <h6>
                                        {user.about_me && user.about_me.substring(0, 50)}
                                        {
                                            user.about_me &&  user.about_me.length>50?"...":""
                                        }
                                        {
                                            user.about_me &&  user.about_me.length>50 && <span onClick={()=> {this.handleOpenAboutMe(user.about_me)}} style={{color:"#4887cc"}}>see more</span>
                                        }
                                    </h6>
                                </div>
                            }

                            <div onClick={ ()=> {this.handleViewResume()}} className={user.cv_flag==="on"&& user.cv!==null?classes.resumeDivActive:classes.resumeDiv}>
                                <IconButton  className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                    <EventNoteIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                </IconButton>
                                <h4>View resume</h4>
                            </div>


                        </div>

                        <div id="contactInformation" style={{display:"none"}} className={classes.photosTitleProfile} >
                            <div className={classes.contactInformationClass}>
                                { user.email &&
                                <p>{user.email}</p>
                                }

                                {
                                    user.contact_phone &&
                                    <p>{user.contact_phone}</p>
                                }

                            </div>
                        </div>

                        <div className={classes.photosTitleProfile} >
                            <p>
                                Photos
                            </p>
                        </div>

                        <div className={classes.othersPhotosDivProfile}>
                            {
                                user.photos &&
                                <div style={{"justifyContent": "flex-start", "width": "fit-content","margin":"auto"}}  className={classes.othersPhotosDivProfile}>

                                    {
                                        user.photos && user.photos.split(";").map((photo , index) => {
                                            return (
                                                <div key={index} onClick={()=> {this.handleClickOpen(baseUrl+photo)}} className={classes.othersPhotosProfile} style={{backgroundImage:`url('${baseUrl+photo}')`}}></div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                !user.photos &&
                                <div><h3>No Photos</h3></div>
                            }
                        </div>
                    </div>

                }


                {
                    user &&
                    <div>
                        <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBarDialog}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>

                            <div className={classes.coverPhotoDialog}>


                                <TransformWrapper
                                    defaultScale={1}
                                    positionX={60}
                                    positionY={80}
                                >
                                    <TransformComponent>
                                        <div  className={classes.dialogPhotoProfile} style={{backgroundImage:`url('${dialogPhotoSrc}')`}}></div>
                                    </TransformComponent>
                                </TransformWrapper>

                            </div>
                        </Dialog>
                    </div>
                }

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

                <Dialog
                    open={openA}
                    onClose={this.handleCloseAboutMe}
                    aria-labelledby="alert-dialog-titleD"
                    aria-describedby="alert-dialog-description"
                    className={classes.fontEn}
                >
                    <DialogTitle id="alert-dialog-titleD">{"About me"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.fontEn} id="alert-dialog-description">
                            {about_meD}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </div>


        );
    }
}

ShowProfileSearchContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        auth : state.auth,
        user : state.selectedUser.data,
        loading : state.loadingProps.loading,
        tempUsers : state.tempUsers,
        appInitData : state.appInitData,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{changeBottomNavStatus,bookmarkUser,initTempToUsers,showUserDetailReset,showUserDetail,changeLoadingStatus})(ShowProfileSearchContainer));