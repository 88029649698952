import * as types from "../constants/ActionTypes";


const initialState = {
    isAuthenticated : false,
    phone_number : null,
    email : null,
    id : null,
    codeId : null,
    apiToken:null,
    step:1,
    user:null,
}


const auth = (state = initialState , action) => {
  switch (action.type) {
      case types.REST_LOGIN:
          return {
              ...state,
              phone_number : null,
              email : null,
              step : action.step,
          }
      case types.SEND_PHONE_NUMBER_AUTH:
          return {
              ...state,
              phone_number : action.data.phone_number,
              email : action.data.email,
              codeId : action.data.verification_code.id!=="duplicate"?action.data.verification_code.id:state.codeId,
              step : action.step,
          }
      case types.SEND_PHONE_NUMBER_UPDATE:
          return {
              ...state,
              phone_number : action.data.phone_number,
              email : action.data.email,
              codeId : action.data.verification_code.id!=="duplicate"?action.data.verification_code.id:state.codeId,
              step : action.step,
          }
      case types.SEND_CODE_AUTH:
          return {
              ...state,
              isAuthenticated : true,
              step : action.step,
              apiToken : action.data.accessToken,
              user : action.data.user,
          }
      case types.COMPLETE_ACCOUNT_AUTH:
          return {
              ...state,
              user : action.user,
              step : action.step,
          }
      case types.UPDATE_PROFILE:
          return {
              ...state,
              user : action.user,
          }
      case types.GET_USER_PROFILE:
          return {
              ...state,
              user : action.data,
          }
      case types.ADD_PHOTOS_USER:
          return {
              ...state,
              user : action.userDataPhoto,
          }
      case types.DELETE_CAST:
          return {
              ...state,
              user:{
                  ...state.user,
                  casts:action.castsData,
              }
          }
      case types.APPLY_FOR_CAST:
          return {
              ...state,
              user:{
                  ...state.user,
                  applies:action.appliesData,
              }
          }
      case types.ADD_BOOKMARKS_CAST:
          return {
              ...state,
              user:{
                  ...state.user,
                  castFavoritesId:action.bookmarks,
              }
          }
      case types.ADD_BOOKMARKS_USER:
          return {
              ...state,
              user:{
                  ...state.user,
                  userFavoritesId:action.userBookmarks,
              }
          }
      case types.LOGOUT:
          return {
              ...state,
              user : null,
              step : 1,
              isAuthenticated : false,
              apiToken : null,
              phone_number : null,
              email : null,
          }

      default:
          return state;
  }
}


export default auth;
