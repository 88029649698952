import * as types from "../constants/ActionTypes";


const initialState = {
    data : [],
    nextPage : 1,
    hasMore : true
}


const tempCasts = (state = initialState , action) => {
  switch (action.type) {
      case types.INIT_CASTS_TO_TEMP:
          const { castData,nextPage,hasMore} = action;
          return {
              ...state,
              data : castData ,
              nextPage:nextPage,
              hasMore: hasMore ,
          }
      case types.INIT_CASTS_TEMP:
          return {
              ...state,
              data : [] ,
              nextPage:1,
              hasMore: true ,
          }
      default:
          return state;
  }
}


export default tempCasts;
