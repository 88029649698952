import * as types from "../constants/ActionTypes";


const initialState = {
    data : [],
}


const selectedUser = (state = initialState , action) => {
  switch (action.type) {
      case types.GET_USER_DETAIL:
          return {
              ...state,
              data :action.data,

          }
      case types.REST_SELECTED_USER:
          return {
              ...state,
              data :null,

          }
      default:
          return state;
  }
}


export default selectedUser;
