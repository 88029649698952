import React, { Component  } from 'react';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles/index";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Button from "@material-ui/core/es/Button/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import {changeAlertStatus} from "../actions";


const styles = (theme) => ({
    mainDivCover:{
        "width": "46%",
        "display": "flex",
        "marginTop": "10px",
        "height": "270px",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        "backgroundColor": "#2f2f2f",
        "borderRadius": "5px",
        "boxShadow": "0 1px 3px 0px rgba(0,0,0,.5)",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "95%",
            "height": "215px",
            "fontSize": "12px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "90%",
            "height": "260px",
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "width": "48%",
            "height": "230px",
            "fontSize": "12px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "48%",
            "height": "230px",
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "48%",
            "height": "240px",
            "fontSize": "12px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "48%",
            "height": "300px",
            "fontSize": "14px",
        },
    },
    firstDiv:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "height": "25%",
    },
    secondDiv:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "height": "25%",
    },
    thirdDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "height": "30%",
        "& div":{
            "color": "#A7A7A7",
            "width": "85%",
            "textAlign": "justify"
        }
    },
    appliedNumDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "height": "30%",
        "& div":{
            "width": "85%",
            "textAlign": "justify"
        }
    },
    fourthDiv:{
        "width": "84%",
        "height": "20%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between",
        "paddingLeft": "10%"
    },
    secondCastDetailDiv:{
    "display": "flex",
    "width": "50%",
    "justifyContent": "space-around",
    },
    colorOrange:{
        "color": "#ff8630",
    },
    activeDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    applyBtnDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "& h4":{
            "marginBottom": "0"
        },
    },
    dividerCast:{
        "borderBottom": "1px solid #777",
        "width": "90%"
    },
    detailRightCard:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "color": "#00ffe7"
    },
    button: {
        margin: theme.spacing(1),
        "backgroundColor": "#dc1a1a",
        "fontFamily": "En",
    },

});


class MyCastComponent extends Component {

    constructor(props){
        super(props);
        this.state ={

        }
    }

    componentDidMount(){

    }

    handleGoToMyCastDetailBtn(id){
        this.props.history.push(`/my/${id}/cast`)
    }

    handleDeleteCastBtn(){
        this.props.changeAlertStatus(true,"Are you sure?","If press YES your cast will be deleted.",this.props.cast.id,"cast")
    }

    render() {

        const {classes,cast}=this.props;

        return (

            <div  className={classes.mainDivCover}>

                <div className={classes.firstDiv}>
                    <div>
                        <div className={classes.activeDiv}>

                            {
                                cast.admin_verification==="on" &&
                                <IconButton style={{  "color": "#1be61b"}} aria-haspopup="true"  color="inherit">
                                    <Brightness1Icon  />
                                </IconButton>
                            }

                            {
                                cast.admin_verification!=="on" &&
                                <IconButton style={{  "color": "yellow"}} aria-haspopup="true"  color="inherit">
                                    <Brightness1Icon  />
                                </IconButton>
                            }

                            <h5>
                                {cast.admin_verification==="on"?"active":"waiting for admin approval"}
                            </h5>
                        </div>
                    </div>
                    <div className={classes.activeDiv}>
                        <Button
                            onClick={()=> {this.handleDeleteCastBtn()}}
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
                <div className={classes.firstDiv}>
                    <h3 style={{  "paddingLeft": "5%"}}>

                        {cast.title &&cast.title.substr(0,25)}
                        {cast.title && cast.title.length>25 && " ..."}

                    </h3>
                </div>

                {/*<div className={classes.secondDiv}>
                   <div className={classes.secondCastDetailDiv}>
                       <div className={classes.detailLeftCard}>
                           <div className={classes.detailCardP} >
                               <p>Type : </p>
                               <p>Gender : </p>
                           </div>
                       </div>
                       <div className={classes.detailCenterCard}>
                           <div className={classes.detailCardP} >
                               <p  className={classes.colorOrange} >{cast.group?cast.group.title:""}</p>
                               <p  className={classes.colorOrange} >{cast.gender}</p>
                           </div>
                       </div>
                   </div>
                    <div className={classes.secondCastDetailDiv}>
                        <div className={classes.detailLeftCard}>
                            <div className={classes.detailCardP} >
                                <p>Location : </p>
                                <p>Hourly Rate : </p>
                            </div>
                        </div>
                        <div className={classes.detailCenterCard}>
                            <div className={classes.detailCardP} >
                                <p  className={classes.colorOrange} >{cast.city?cast.city.title:""}</p>
                                <p  className={classes.colorOrange} >{cast.hourly_rate}</p>
                            </div>
                        </div>
                    </div>
                </div>*/}

                {/*<div className={classes.thirdDiv}>
                    <div>
                        <p>
                            {cast.description && cast.description.substr(0,80)}
                            {cast.description && cast.description.length>80 && " ..."}
                        </p>
                    </div>
                </div>*/}

                <div className={classes.appliedNumDiv}>
                    <div>
                        {cast.applies &&  cast.applies.length===1 &&
                            <p>1 people has applied for this Cast</p>
                        }

                        {cast.applies &&  cast.applies.length>1 &&
                            <p>{cast.applies.length} peoples have applied for this Cast</p>
                        }

                        {cast.applies && cast.applies.length===0 &&
                            <p>No one has applied for this Cast</p>
                        }

                    </div>
                </div>
                <div className={classes.applyBtnDiv}>
                    <div className={classes.dividerCast}></div>
                </div>
                <div className={classes.fourthDiv}>
                    <div>
                        <p>opened at {cast.created_at}</p>
                    </div>
                    <div onClick={()=> {this.handleGoToMyCastDetailBtn(cast.id)}} className={classes.detailRightCard}>
                        see detail
                        <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                            <ArrowForwardIosRoundedIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

export default withStyles(styles)(connect(mapStateToProps,{changeAlertStatus})(MyCastComponent));
