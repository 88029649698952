import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import {changeBottomNavStatus} from "../../actions";
import {baseUrl} from "../../constants/constant";
import { Home ,Briefcase,Search ,Activity,User ,Send} from 'react-feather';


const styles = theme => ({
    root: {
        width: '100%',
        "height": "8vh",
        "position": "fixed",
        "zIndex": "10000",
        "top": "-1px",
        backgroundColor:"#222",
    },
    appBar: {
        "boxShadow": "0 1px 8px 1px rgba(0, 0, 0, 0.87)",
        [theme.breakpoints.only('xs')]: {
            "height": "9vh"
        },
    },
    toolBarA: {
        "display": "flex",
        "justifyContent": "space-around",
        "margin": "auto 0",
        backgroundColor:"#222",
        color:"white",
        [theme.breakpoints.up('sm')]: {
            "minHeight": "8vh",
        },
    },
    mainDivWeb:{
        "display": "flex",
        "justifyContent": "space-between",
        "width": "100%",
        "alignItems": "center",
        [theme.breakpoints.down('sm')]: {
            "display": "none",
        },
    },
    mainDivMobile:{
        "display": "none",
        "justifyContent": "center",
        "width": "100%",
        "alignItems": "center",
        [theme.breakpoints.down('sm')]: {
            "display": "flex",
        },
    },
    leftAppBarDiv:{
        "display": "flex",
        "justifyContent": "space-evenly",
        "width": "30%",
        "alignItems": "center",
        "& h5":{
            color:"#00EBFF",
            "width": "100%",
            textAlign: "center",
        },
        [theme.breakpoints.up('md')]: {
            "width": "20%",
            "& h5":{
                "width": "40%",
                textAlign: "left",
            },
        },

    },
    centerAppBarDiv:{
        "display": "flex",
        "justifyContent": "space-evenly",
        "width": "30%",
        "alignItems": "center",
        "& h5":{
            color:"white",
            "width": "100%",
            textAlign: "center",
        },
        [theme.breakpoints.up('md')]: {
            "width": "20%",
            "& h5":{
                "width": "40%",
                textAlign: "left",
            },
        },

    },
    rightAppBarDiv:{
        "display": "flex",
        "justifyContent": "space-evenly",
        "width": "30%",
        "alignItems": "center"
    },
    divider:{
        "border": "1px solid white",
        "height": "26px"
    },
    socialIconsColorProfile:{
        "color": "#fffbf0",
        [theme.breakpoints.down('lg')]: {//Tab
            "height": "5vh",
            "width": "5vh",
            "position": "absolute !important",
            "right": "15px",
            "top": "16px"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "5vh",
            "width": "3vh"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "5vh",
            "width": "3vh"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "4vh",
            "width": "5vh"
        }
    },
    sendIcon:{
        "width": "5vw",
        [theme.breakpoints.up('md')]: {
            "width": "17px",
        }
    },

});


class AppBarComponent extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }


    handleGoToCasts = () => {
        this.props.changeBottomNavStatus(1,1)
        this.props.history.push('/casts')
    };

    handleGoToHomeBtn(){
        this.props.changeBottomNavStatus(0,0)
        this.props.history.push("/")
    }

    handleGoToProfile(){
        this.props.changeBottomNavStatus(4,4)
        this.props.history.push("/profile")
    }

    handleGoToMyActivity(){
        this.props.changeBottomNavStatus(3,3)
        this.props.history.push("/all/my/activity")
    }

    handleGoToSearch(){
        this.props.changeBottomNavStatus(2,2)
        this.props.history.push("/page/user/search")
    }

    handleGoToMessages(){
        this.props.changeBottomNavStatus(0,5)
        this.props.history.push("/messages")
    }

    render() {
        const { bottomNav,classes} = this.props;

        return (
            <div id="appBarCover" className={classes.root} >
                <AppBar position="static" className={classes.appBar} >
                    <Toolbar className={classes.toolBarA}>
                        <div className={classes.mainDivWeb}>
                            <div className={classes.leftAppBarDiv}>
                                <h4>Bloomsho</h4>
                                <div className={classes.divider}></div>
                                {
                                    (bottomNav.value===0 && bottomNav.valueTop!==5 && bottomNav.valueTop!==6) &&
                                    <h5>Home</h5>
                                }
                                {
                                    bottomNav.value===1 &&
                                    <h5>Casting</h5>
                                }
                                {
                                    bottomNav.value===2 &&
                                    <h5>Search</h5>
                                }
                                {
                                    bottomNav.value===3 &&
                                    <h5>My Activity</h5>
                                }
                                {
                                    ( bottomNav.value===4 || bottomNav.valueTop===6)&& bottomNav.valueTop!==8 &&
                                    <h5>Profile</h5>
                                }
                                {
                                    (bottomNav.valueTop===5) &&
                                    <h5>Notifications</h5>
                                }
                            </div>
                            <div className={classes.rightAppBarDiv}>
                                <IconButton onClick={()=> {this.handleGoToMessages()}}  aria-haspopup="true"  style={{"color":`${bottomNav.valueTop===5?"#00EBFF":"white"}`}}>
                                    <Send size={20} />
                                </IconButton>
                                <IconButton onClick={ ()=> {this.handleGoToHomeBtn()}} aria-haspopup="true"  style={{"color":`${(bottomNav.value===0 && bottomNav.valueTop!==5)?"#00EBFF":"white"}`}}>
                                    <Home size={20} />
                                </IconButton>
                                <IconButton onClick={this.handleGoToCasts.bind(this)} aria-haspopup="true"  style={{"color":`${bottomNav.value===1?"#00EBFF":"white"}`}}>
                                    <Briefcase size={20} />
                                </IconButton>
                                <IconButton onClick={this.handleGoToSearch.bind(this)} aria-haspopup="true"  style={{"color":`${bottomNav.value===2?"#00EBFF":"white"}`}}>
                                    <Search size={20}  />
                                </IconButton>
                                <IconButton onClick={this.handleGoToMyActivity.bind(this)} aria-haspopup="true"  style={{"color":`${bottomNav.value===3?"#00EBFF":"white"}`}}v>
                                    <Activity size={20}  />
                                </IconButton>
                                <IconButton onClick={this.handleGoToProfile.bind(this)} aria-haspopup="true"  style={{"color":`${bottomNav.value===4?"#00EBFF":"white"}`}}>
                                    <Avatar alt="Profile photo" src={`${this.props.user && this.props.user.main_photo?baseUrl+this.props.user.main_photo:"/images/no-user.png"}`} />
                                </IconButton>
                            </div>
                        </div>

                        <div className={classes.mainDivMobile}>
                            <div className={classes.centerAppBarDiv}>
                                {
                                    (bottomNav.value===0 && bottomNav.valueTop!==5 && bottomNav.valueTop!==6) &&
                                    <h5>Home</h5>
                                }
                                {
                                    bottomNav.value===1 && bottomNav.valueTop!==7 && bottomNav.valueTop!==6 &&
                                    <h5>Casting</h5>
                                }
                                {
                                    bottomNav.value===2 &&
                                    <h5>Search</h5>
                                }
                                {
                                    bottomNav.value===3 && bottomNav.valueTop!==7 && bottomNav.valueTop!==6 &&
                                    <h5>My Activity</h5>
                                }
                                {
                                    ( bottomNav.value===4 || bottomNav.valueTop===6)&& bottomNav.valueTop!==8 &&
                                    <h5>Profile</h5>
                                }
                                {
                                    (bottomNav.valueTop===5) &&
                                    <h5>Notifications</h5>
                                }
                                {
                                    (bottomNav.valueTop===7) &&
                                    <h5>Cast Detail</h5>
                                }
                                {
                                    (bottomNav.valueTop===8) &&
                                    <h5>Setting</h5>
                                }
                            </div>

                            {
                                (bottomNav.value===0 && bottomNav.valueTop!==5 && bottomNav.valueTop!==6) &&
                                <div>
                                    <IconButton onClick={()=> {this.handleGoToMessages()}} className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <Send size={22} />
                                    </IconButton>
                                </div>

                            }

                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

AppBarComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        bottomNav : state.bottomNav,
        user : state.auth.user,
    }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps,{changeBottomNavStatus})(AppBarComponent)));
