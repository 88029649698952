import * as types from "../constants/ActionTypes";


const initialState = {
    data : [],
    nextPage : 1,
    hasMore : true,
    total : 0
}


const users = (state = initialState , action) => {
  switch (action.type) {
      case types.RECEIVE_USERS:
          const { data,users} = action;
          return {
              ...state,
              data : state.data.concat(users) ,
              nextPage:data.current_page + 1,
              hasMore: data.current_page !== data.last_page ,
              total : data.total
          }
      case types.INIT_TEMP_TO_USERS:
          const { tempData,nextPage,hasMore} = action;
          return {
              ...state,
              data : tempData ,
              nextPage:nextPage,
              hasMore: hasMore,
              total : data.total
          }
      case types.INIT_USERS:
          return {
              ...state,
              data : [] ,
              nextPage:1,
              hasMore: true,
              total : 0
          }
      default:
          return state;
  }
}


export default users;
