import * as types from "../constants/ActionTypes";


const initialState = {
    data:[],
}


const userSearchData = (state = initialState , action) => {
  switch (action.type) {
      case types.CHANGE_USER_SEARCH_DATA:
          const {data} = action;
          return {
              ...state,
              data:data,
          }
      default:
          return state;
  }
}


export default userSearchData;
