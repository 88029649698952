import React , {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import green from "@material-ui/core/colors/green";
import {Snackbar} from "@material-ui/core/es/index";
import SnackbarContent from "@material-ui/core/es/SnackbarContent/SnackbarContent";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import amber from '@material-ui/core/colors/amber';
import Grid from "@material-ui/core/es/Grid/Grid";
import {connect} from "react-redux";
import {changeSnackStatus} from "../../actions";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    commentItem:{
        textAlign:'left',
    },
});

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    iconRight: {
        "fontSize": "20px",
        "marginLeft": "0%",
        "marginRight": "-5% !important",
    },
    iconLeft: {
        fontSize: 20,
        marginRight:5,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        "width": "80%",
        "display": "flex",
        "marginTop": "-1%",
        "alignItems": "center",
        "paddingLeft": "10%",
        "justifyContent": "center",
        "textAlign": "center"
    },
    coverMessage:{
        "width": "100%",
    },
    close:{
        left:0,
        position:'absolute',
        "margin": "auto",
        "marginRight": "5%",

        [theme.breakpoints.down('md')]: {
            left: 0,
        },
        [theme.breakpoints.down('sm')]: {
            left: 0,
        },
        [theme.breakpoints.down('xs')]: {
            left: 0,
        },
    }
});

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <Grid container direction={'row'} >
            <SnackbarContent
                className={classNames(classes[variant], className)}
                aria-describedby="client-snackbar"
                action={[]}
                classes={{message:classes.coverMessage}}
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                        <Icon className={classNames(classes.iconRight, classes.iconVariant)} />
                    </span>
                }
                {...other}
            />
            <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={onClose}
            >
                <CloseIcon className={classes.iconLeft} />
            </IconButton>
        </Grid>

    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};
const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

class SnackBarComponent extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }


    handleClose(){
        this.props.changeSnackStatus(false,'success','')
    }
    render() {
        return (
            <Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: `${this.props.snackProps.vertical?this.props.snackProps.vertical:"top"}`,
                        horizontal: `${this.props.snackProps.horizontal?this.props.snackProps.horizontal:"center"}`,
                    }}
                    open={this.props.snackProps.open}
                    autoHideDuration={1}

                >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose.bind(this)}
                        variant={this.props.snackProps.variant}
                        message={this.props.snackProps.message}
                    />
                </Snackbar>
            </Grid>
        );
    }
}

Comment.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        snackProps : state.snackProps
    }
}

export default withStyles(styles)(connect(mapStateToProps ,{changeSnackStatus} )(SnackBarComponent));