import * as types from "../constants/ActionTypes";


const initialState = {
    data : [],
    nextPage : 1,
    hasMore : true
}


const tempUsersSearch = (state = initialState , action) => {
  switch (action.type) {
      case types.INIT_USERS_TO_TEMP_SEARCH:
          const { userData,nextPage,hasMore} = action;
          return {
              ...state,
              data : userData ,
              nextPage:nextPage,
              hasMore: hasMore ,
          }
      case types.INIT_USERS_TEMP_SEARCH:
          return {
              ...state,
              data : [],
              nextPage:1,
              hasMore: true,
          }
      default:
          return state;
  }
}


export default tempUsersSearch;
