import { combineReducers } from 'redux';
import auth from './auth';
import loadingProps from "./loadingProps";
import snackProps from "./snackProps";
import appInitData from "./appInitData";
import users from "./users";
import selectedUser from "./selectedUser";
import casts from "./casts";
import selectedCast from "./selectedCast";
import tempUsers from "./tempUsers";
import tempCasts from "./tempCasts";
import newCast from "./newCast";
import bottomNav from "./bottomNav";
import alertStatus from "./alertStatus";
import userSearchData from "./userSearchData";
import usersSearch from "./usersSearch";
import tempUsersSearch from "./tempUsersSearch";
import castSearchData from "./castSearchData";

export default combineReducers({
    auth,
    loadingProps,
    snackProps,
    appInitData,
    users,
    usersSearch,
    tempUsers,
    tempUsersSearch,
    castSearchData,
    selectedUser,
    casts,
    tempCasts,
    selectedCast,
    newCast,
    bottomNav,
    userSearchData,
    alertStatus
});
