import * as types from "../constants/ActionTypes";


const initialState = {
    data : [],
}


const newCast = (state = initialState , action) => {
  switch (action.type) {
      case types.ADD_CAST:
          const { castData} = action;
          return {
              ...state,
              data: castData,
          }
      default:
          return state;
  }
}


export default newCast;
