import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import FilterListIcon from '@material-ui/icons/FilterList';
import CastComponent from "../components/CastComponent";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import Input from "@material-ui/core/es/Input/Input";
import Radio from "@material-ui/core/es/Radio/Radio";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {changeCastSearchState, getCasts, initCasts, initCastsTemp, initCastsToTemp} from "../actions";
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Filter} from 'react-feather';



const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
    },
    mainDiv:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "space-evenly",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "width": "100%",
        [theme.breakpoints.up('md')]: {
            "width": "80%",
        },
    },
    filterBtnMainDivCastCon:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        [theme.breakpoints.down('md')]: {
            "width": "92%",
        },
    },
    filterBtnCoverDivCastCon:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
    },
    filterBtnDivCastCon:{
        "color": "#00EBFF",
        "width": "100%",
        "display": "flex",
        "padding": "3%",
        "alignItems": "center",
        "justifyContent": "center",
        "backgroundColor": "#222222",
        "& h6":{
            "margin": "0",
            "marginRight": "3%",
        },
    },
    filterBtnDivCastCon2:{
        "color": "#00EBFF",
        "width": "100%",
        "display": "flex",
        "padding": "3%",
        "alignItems": "center",
        "justifyContent": "center",
        "backgroundColor": "#222222",
        "& h6":{
            "margin": "0",
        },
    },
    secondDivCoverSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
        "position": "relative",
    },
    secondDivInputSearch:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "24%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "20%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "18%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "18%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "15%",
        }
    },
    secondDivInputPlaceSearch:{
        "position": "absolute",
        "top": "0",
        "left": "4%",
        "margin": "2% 1%",
        "color": "#fea257",
        "pointerEvents": "none",
        [theme.breakpoints.up('xs')]: { //Iphone
            "margin": "3% 1%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "margin": "2% 1%",
        },[theme.breakpoints.up('md')]: {
            "margin": "3% 1%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "margin": "3% 1%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "margin": "2% 1%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "margin": "2% 1%",
        }
    },
    secondDivInput2Search:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "34%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "28%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "23%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "24%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "19%",
        }
    },
    secondDivInput3Search:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "22%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "18%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "18%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "18%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "16%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "14%",
        }
    },
    secondDivInputArrowSearch:{
        "position": "absolute",
        "top": "0",
        "right": "0",
        "color": "#fea257"
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },
    paperRoot:{
        "width": "40%",
        "maxWidth": "50%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "80%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "80%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('md')]: {
            "width": "80%",
            "maxWidth": "100%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "40%",
            "maxWidth": "50%",
        }
    },
    fontEn:{
        "fontFamily": "En",
        "& h2":{
            "fontFamily": "En",
        },
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        "width": "100%"
    },
    genderTitleSearch: {
        "width": "82%",
        "marginTop": "3%"
    },
    genderCoverDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "82%",
    },
    radioBtnSearch: {
        color: "#ff8630 !important",
    },
    genderLabelSearch: {
        "marginLeft": "-7px",
        "marginRight": "5px",
    },
    searchItemDivCast: {
        "display": "none",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "100%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "100%",
        },[theme.breakpoints.up('md')]: {
            "width": "100%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "100%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "60%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "60%",
        }
    },
    searchBtnCoverDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "82%",
    },
    searchBtnDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "backgroundColor": "#00EBFF",
        "padding": "3%",
        "borderRadius": "5px",
        "color": "#222222",
        "boxShadow": "0 3px 4px 1px black",
        "& h6":{
            "margin": "0",
        },
    },
    coverSpinner:{
        "width": "100%",
        "display": "flex",
        "justifyContent": "center"
    },
    searchButton: {
        "backgroundColor": "#00ebff",
        "color": "#222",
        "fontFamily": "En",
        "width": "100%",
    },
    DescDiv:{
        "width": "80%",
        "textAlign": "center",
        "color": "#A7A7A7"
    }
});


class CastContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            showMore:false,
            open:false,
            open2:false,
            open3:false,
            type:"All",
            groupId:null,
            country:"All",
            countryId:null,
            cities:null,
            city:"All",
            cityId:null,
            selectedValueGender:null,
        };
        this.componentCleanup = this.componentCleanup.bind(this);
    }

    handleShowMoreBtnCast = () => {
        let showMore = this.state.showMore;
        let showMoreIcon= document.getElementById(`moreIcon`);
        let showLessIcon= document.getElementById(`lessIcon`);
        let showMoreCast= document.getElementById(`showMoreCast`);

        if (showMore===false){

            this.setState({
                showMore:true
            })

            showMoreIcon.style.display= "none"
            showLessIcon.style.display= "block"
            showMoreCast.style.display= "flex"
        }
        if (showMore===true){

            this.setState({
                showMore:false
            })

            showMoreIcon.style.display= "block"
            showLessIcon.style.display= "none"
            showMoreCast.style.display= "none"

        }


    }

    handleChangeType = (event) => {
        let selectedType= this.props.appInitData.groups.filter( (group)=> {
            if(group.title===event.target.value){
                return group
            }
        } )

        this.setState({
            type:selectedType[0].title,
            groupId:selectedType[0].id,
        })
    };

    handleChangeCountry = (event) => {
        let selectedCountry = this.props.appInitData.countries.filter( (country)=> {
            if(country.title===event.target.value){
                return country
            }
        } )

        this.setState({
            country:selectedCountry[0].title,
            countryId:selectedCountry[0].id,
            cities:selectedCountry[0].cities
        })
    };

    handleChangeCity = (event) => {
        let selectedCity = this.state.cities.filter( (city)=> {
            if(city.title===event.target.value){
                return city
            }
        } )
        this.setState({
            city:selectedCity[0].title,
            cityId:selectedCity[0].id
        })
    };

    handleChangeGender(value){
        this.setState({
            selectedValueGender:value
        })
    }

    handleClickOpen = () => {
        this.setState({
            open:true
        })
    };

    handleClickOpen2 = () => {
        this.setState({
            open2:true
        })
    };

    handleClickOpen3 = () => {
        this.setState({
            open3:true
        })
    };

    handleClose = () => {
        this.setState({
            open:false
        })
    };

    handleClose2 = () => {
        this.setState({
            open2:false,
        })
    };

    handleClose3 = () => {
        this.setState({
            open3:false,
        })
    };


    componentWillMount(){
        if (this.props.casts.nextPage===1){
            this.props.getCasts(1,this.props.auth.apiToken,this.props.castSearchData)
        }
        window.addEventListener('beforeunload', this.componentCleanup);
    }

    componentWillUnmount(){
        // this.props.initCastsToTemp(this.props.casts.data,this.props.casts.nextPage,this.props.casts.hasMore)
        // this.props.initCasts()
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup() {
        this.props.initCasts()
        this.props.changeCastSearchState(this.state)
    }

    handleSearchBtn(){
        this.props.initCasts()
        this.props.initCastsTemp()
        this.props.changeCastSearchState(this.state)
        this.props.getCasts(1,this.props.auth.apiToken,this.state)
    }

    render() {
        const { classes ,casts,appInitData} = this.props;
        const { open,open2,open3,type ,country,city,cities,selectedValueGender} = this.state;
        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                    <div className={classes.filterBtnMainDivCastCon}>
                        <div  className={classes.filterBtnCoverDivCastCon}>


                            <div onClick={()=> {this.handleShowMoreBtnCast()}} style={{display:"flex"}}>
                                <h5 style={{margin:"auto",color:"#00EBFF"}} >Filter</h5>
                                <IconButton  aria-haspopup="true"  color="inherit">
                                    <Filter size={20} color="#00EBFF"  />
                                </IconButton>
                            </div>

                            <IconButton  style={{width:"14%"}} aria-haspopup="true"  color="inherit">
                                <div onClick={()=> {this.handleShowMoreBtnCast()}} className={classes.filterBtnDivCastCon2} >
                                    <ExpandMoreIcon id="moreIcon" />
                                    <ExpandLessIcon id="lessIcon" style={{display:"none"}} />
                                </div>
                            </IconButton>
                        </div>
                    </div>

                   <div id="showMoreCast"  className={classes.searchItemDivCast}>

                       <div onClick={this.handleClickOpen} className={classes.secondDivCoverSearch}>
                           <input className={classes.secondDivInputSearch} value={type} type="text" disabled />
                           <h4 className={classes.secondDivInputPlaceSearch}>Type :</h4>
                           <div className={classes.secondDivInputArrowSearch}>
                               <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                   <KeyboardArrowDownRoundedIcon fontSize="small" />
                               </IconButton>
                           </div>
                       </div>
                       <div>
                           <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose}>
                               <DialogTitle className={classes.fontEn}>Select type</DialogTitle>
                               <DialogContent>

                                   <form className={classes.container}>
                                       <FormControl className={classes.formControl}>
                                           <InputLabel id="demo-dialog-select-label">Type</InputLabel>
                                           <Select
                                               labelId="demo-dialog-select-label"
                                               id="demo-dialog-select"
                                               value={type}
                                               onChange={this.handleChangeType}
                                               input={<Input />}
                                           >
                                               {
                                                   appInitData.groups && appInitData.groups.map((group , index) => {
                                                       return <MenuItem key={index} value={group.title}>{group.title}</MenuItem>
                                                   })
                                               }
                                           </Select>
                                       </FormControl>
                                   </form>

                               </DialogContent>
                               <DialogActions>
                                   <Button className={classes.fontEn} onClick={this.handleClose} color="primary">
                                       Cancel
                                   </Button>
                                   <Button className={classes.fontEn} onClick={this.handleClose} color="primary">
                                       Ok
                                   </Button>
                               </DialogActions>
                           </Dialog>
                       </div>


                       <div onClick={this.handleClickOpen2} className={classes.secondDivCoverSearch}>
                           <input className={classes.secondDivInput2Search} value={country} type="text" disabled />
                           <h4 className={classes.secondDivInputPlaceSearch}>Country :</h4>
                           <div className={classes.secondDivInputArrowSearch}>
                               <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                   <KeyboardArrowDownRoundedIcon fontSize="small" />
                               </IconButton>
                           </div>
                       </div>
                       <div>
                           <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open2} onClose={this.handleClose2}>
                               <DialogTitle className={classes.fontEn}>Select country</DialogTitle>
                               <DialogContent>

                                   <form className={classes.container}>
                                       <FormControl className={classes.formControl}>
                                           <InputLabel id="demo-dialog-select-label2">Country</InputLabel>
                                           <Select
                                               labelId="demo-dialog-select-label2"
                                               id="demo-dialog-select2"
                                               value={country}
                                               onChange={this.handleChangeCountry}
                                               input={<Input />}
                                           >
                                               {
                                                   appInitData.countries && appInitData.countries.map((country , index) => {
                                                       return <MenuItem key={index} value={country.title}>{country.title}</MenuItem>
                                                   })
                                               }
                                           </Select>
                                       </FormControl>
                                   </form>

                               </DialogContent>
                               <DialogActions>
                                   <Button className={classes.fontEn} onClick={this.handleClose2} color="primary">
                                       Cancel
                                   </Button>
                                   <Button className={classes.fontEn} onClick={this.handleClose2} color="primary">
                                       Ok
                                   </Button>
                               </DialogActions>
                           </Dialog>
                       </div>

                       <div onClick={this.handleClickOpen3} className={classes.secondDivCoverSearch}>
                           <input className={classes.secondDivInput3Search} value={city} type="text" disabled />
                           <h4 className={classes.secondDivInputPlaceSearch}>City :</h4>
                           <div className={classes.secondDivInputArrowSearch}>
                               <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                   <KeyboardArrowDownRoundedIcon fontSize="small" />
                               </IconButton>
                           </div>
                       </div>
                       <div>
                           <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open3} onClose={this.handleClose2}>
                               <DialogTitle className={classes.fontEn}>Select city</DialogTitle>
                               <DialogContent>

                                   <form className={classes.container}>
                                       <FormControl className={classes.formControl}>
                                           <InputLabel id="demo-dialog-select-label3">City</InputLabel>
                                           <Select
                                               labelId="demo-dialog-select-label3"
                                               id="demo-dialog-select2"
                                               value={city}
                                               onChange={this.handleChangeCity}
                                               input={<Input />}
                                           >
                                               {
                                                   cities && cities.map((city , index) => {
                                                       return <MenuItem key={index} value={city.title}>{city.title}</MenuItem>
                                                   })
                                               }
                                           </Select>
                                       </FormControl>
                                   </form>

                               </DialogContent>
                               <DialogActions>
                                   <Button className={classes.fontEn} onClick={this.handleClose3} color="primary">
                                       Cancel
                                   </Button>
                                   <Button className={classes.fontEn} onClick={this.handleClose3} color="primary">
                                       Ok
                                   </Button>
                               </DialogActions>
                           </Dialog>
                       </div>


                       <div  className={classes.genderTitleSearch}>
                           Gender
                       </div>
                       <div  className={classes.genderCoverDivSearch}>
                           <div className={classes.genderCoverDivSearch}>
                               <Radio
                                   classes={{root: classes.radioBtnSearch}}
                                   checked={selectedValueGender === null}
                                   onChange={()=>{this.handleChangeGender(null)}}
                                   value="Male"
                                   name="radio-button-demo"
                                   size="small"
                               />
                               <p className={classes.genderLabelSearch}>All</p>
                           </div>
                           <div className={classes.genderCoverDivSearch}>
                               <Radio
                                   classes={{root: classes.radioBtnSearch}}
                                   checked={selectedValueGender === 'Male'}
                                   onChange={()=>{this.handleChangeGender("Male")}}
                                   value="Male"
                                   name="radio-button-demo"
                                   size="small"
                               />
                               <p className={classes.genderLabelSearch}>Male</p>
                           </div>
                           <div className={classes.genderCoverDivSearch}>
                               <Radio
                                   classes={{root: classes.radioBtnSearch}}
                                   checked={selectedValueGender === 'Female'}
                                   onChange={()=>{this.handleChangeGender("Female")}}
                                   value="Female"
                                   name="radio-button-demo"
                                   size="small"
                               />
                               <p className={classes.genderLabelSearch}>Female</p>
                           </div>
                           <div className={classes.genderCoverDivSearch}>
                               <Radio
                                   classes={{root: classes.radioBtnSearch}}
                                   checked={selectedValueGender === 'Others'}
                                   onChange={()=>{this.handleChangeGender("Others")}}
                                   value="Others"
                                   name="radio-button-demo"
                                   size="small"
                               />
                               <p className={classes.genderLabelSearch}>Others</p>
                           </div>
                       </div>

                       <div  className={classes.searchBtnCoverDivSearch}>
                           <Button  onClick={()=> {this.handleSearchBtn()}} variant="contained"  className={classes.searchButton} color="default" component="span">
                               Filter
                           </Button>
                       </div>

                   </div>

                    <div className={classes.DescDiv}>
                        <h5>In this section, you will see all job postings. See details and submit your request for cooperation.</h5>
                    </div>

                    <InfiniteScroll
                        className={classes.mainDiv}
                        pageStart={0}
                        loadMore={()=> { casts.hasMore && casts.data && casts.data.length !== 0 && this.props.getCasts(casts.nextPage,this.props.auth.apiToken,this.props.castSearchData);}}
                        hasMore={casts.hasMore}
                        loader={<div className={classes.coverSpinner} ><CircularProgress style={{color:"#00EBFF"}} /></div>}
                    >

                        {  casts.data && casts.data.map((cast , index) => {
                            return <CastComponent cast={cast} key={index} history={this.props.history} />
                            })
                        }

                    </InfiniteScroll>

                </div>

            </div>

        );
    }
}

CastContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        casts : state.casts,
        tempCasts: state.tempCasts,
        castSearchData: state.castSearchData,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{changeCastSearchState,getCasts,initCasts,initCastsToTemp,initCastsTemp})(CastContainer));