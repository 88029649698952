import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Tabs from "@material-ui/core/es/Tabs/Tabs";
import Tab from "@material-ui/core/es/Tab/Tab";
import Box from "@material-ui/core/es/Box/Box";
import Typography from "@material-ui/core/es/Typography/Typography";
import './../styles/css/main.css';
import CastComponent from "../components/CastComponent";
import MyCastComponent from "../components/MyCastComponent";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import AddIcon from '@material-ui/icons/Add';
import {changeBottomNavStatus, changeLoadingStatus, showUserProfile} from "../actions";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardComponent from "../components/CardComponent";


const styles = theme => ( {

    mainCover:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "backgroundColor": "#222",
        "flexDirection": "row",
        "min-height": "92vh",
    },

    mainDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "flex-start",
        "width": "100%",
        "backgroundColor": "#222",
        "flexDirection": "row",
        "min-height": "92vh",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "paddingBottom": "60px",
        "paddingTop": "1%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "12px"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "14px"
        },[theme.breakpoints.up('md')]: {
            "width": "80%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "80%",
        },
        [theme.breakpoints.up('xl')]: {//Tab
            "width": "80%",
        },
        [theme.breakpoints.up('xxl')]: {//Tab
            "width": "80%",
        }
    },
    rootTab: {
            backgroundColor: "#222",
        "width": "100%",
    },
    tabColor: {
        "color": "#fff",
        "backgroundColor": "rgb(34, 34, 34)"
    },
    orangeTab: {
        "backgroundColor": "#fea257"
    },
    addNewCastCoverDivMyActivity:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "flex-start",
        "width": "85%",
        "flexDirection": "column",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "12px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "12px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "12px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "14px",
        },

    },
    addNewCastIconBtnMyActivity:{
        "display": "flex",
        "justifyContent": "center",
        "width": "50%",
        "alignItems": "center"
    },
    addNewCastIconBtnDivMyActivity:{
        "display": "flex",
        "justifyContent": "flex-start",
        "alignItems": "center",
        "width": "100%",
        "color": "#1bd4d4",
        "& h6":{
            "margin": "0",
            "marginRight": "2%"
        },
    },
    addNewCastDescDivMyActivity:{
        "& p":{
            "margin": "0",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    titleBookmarksDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
    },
    userBookmarksDiv:{
        "display": "flex",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "width": "80%",
        "flexWrap": "wrap",
        [theme.breakpoints.down('md')]: {//Tab
            "width": "100%",
        },
    },
    dividerDivCover:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "margin": "2vw 0",
        "& h4":{
            "marginBottom": "0"
        },
    },
    dividerCast:{
        "borderBottom": "1px solid #777",
        "width": "92%"
    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    llk:{},

});



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box style={{"display": "flex", "justifyContent": "space-evenly","alignItems": "center","fontFamily": "En","flexWrap": "wrap","padding": "0"}} p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class MyActivityContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            value:0,
        };
    }

    componentWillMount(){
        this.props.changeBottomNavStatus(3,3)
        this.props.changeLoadingStatus(true)
        this.props.showUserProfile(this.props.auth.user.id,this.props.auth.apiToken)
    }

    handleChange = (event, newValue) => {
         this.setState({
             value:newValue,
         })
    };

    handleGoToAddNewCastBtn(){
        this.props.history.push(`/add/new/cast`)
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    render() {

        const { classes ,myApplies,myCast,castFavorites,userFavorites,loading} = this.props;
        const { value } = this.state;

        return (
            <div className={classes.mainCover}>

                <div className={classes.mainDiv}>

                    <div className={classes.rootTab}>
                        <AppBar className={classes.tabColor} position="static">
                            <Tabs  classes={{indicator:classes.orangeTab}} value={value} onChange={this.handleChange} aria-label="simple tabs"  centered>
                                <Tab style={{width:"33%","maxWidth": "100%"}} label="MY CASTS" {...a11yProps(0)} />
                                <Tab style={{width:"33%","maxWidth": "100%"}} label="APPLIED" {...a11yProps(1)} />
                                <Tab style={{width:"33%","maxWidth": "100%"}} label="BOOKMARKS " {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>

                            <div className={classes.addNewCastCoverDivMyActivity}>
                                <IconButton onClick={()=> {this.handleGoToAddNewCastBtn()}} className={classes.addNewCastIconBtnMyActivity} aria-haspopup="true"  color="inherit">
                                    <div className={classes.addNewCastIconBtnDivMyActivity} >
                                        <h6>Add New Cast</h6>
                                        <AddIcon/>
                                    </div>
                                </IconButton>
                                <div className={classes.addNewCastDescDivMyActivity}>
                                    <p>
                                        lorm Add your cast. You can change it later Add your cast. You can change it later Add your cast. You can change it later
                                    </p>
                                </div>
                            </div>

                            {
                                myCast && myCast.map( (cast,index)=> {
                                    return  <MyCastComponent cast={cast} key={index} history={this.props.history} />
                                })
                            }


                        </TabPanel>
                        <TabPanel value={value} index={1}>

                            {
                                myApplies && myApplies.map( (apply,index)=> {
                                    if(apply.post){
                                        return  <CastComponent  key={index} cast={apply.post} history={this.props.history} />
                                    }

                                })
                            }


                        </TabPanel>
                        <TabPanel value={value} index={2}>

                            {
                                castFavorites && castFavorites.length>0 &&
                                <div className={classes.titleBookmarksDiv}>
                                    <h3>Casts</h3>
                                </div>
                            }
                            {
                                castFavorites && castFavorites.length>0 &&
                                <div className={classes.dividerDivCover}>
                                    <div className={classes.dividerCast}></div>
                                </div>
                            }


                            {
                                castFavorites && castFavorites.map( (favorite,index)=> {
                                    if(favorite.post){
                                        return  <CastComponent  key={index} cast={favorite.post} history={this.props.history} />
                                    }
                                })
                            }


                            {
                                userFavorites && userFavorites.length>0 &&
                                <div className={classes.titleBookmarksDiv}>
                                    <h3>Users</h3>
                                </div>
                            }

                            {
                                userFavorites && userFavorites.length>0 &&
                                <div className={classes.dividerDivCover}>
                                    <div className={classes.dividerCast}></div>
                                </div>
                            }
                            <div className={classes.userBookmarksDiv}>
                                {
                                    userFavorites && userFavorites.map( (favorite,index)=> {
                                        if(favorite.user){
                                            return  <CardComponent  key={index} user={favorite.user} history={this.props.history} />
                                        }
                                    })
                                }
                            </div>


                        </TabPanel>
                    </div>
                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>
            </div>



        );
    }
}

MyActivityContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        myCast : state.auth.user.casts,
        myApplies : state.auth.user.applies,
        castFavorites : state.auth.user.castFavorites,
        userFavorites : state.auth.user.userFavorites,
        loading : state.loadingProps.loading,

    }
}

export default withStyles(styles)(connect(mapStateToProps,{changeBottomNavStatus,changeLoadingStatus,showUserProfile})(MyActivityContainer));