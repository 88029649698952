import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import './../../styles/css/animation_app_bar.css';
import BottomNavigation from "@material-ui/core/es/BottomNavigation/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/es/BottomNavigationAction/BottomNavigationAction";
import { Home ,Briefcase,Search ,Activity,User} from 'react-feather';
import {changeBottomNavStatus} from "../../actions";

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor:"#222",
        "position": "fixed",
        "bottom": "0",
        "fontFamily": "En",
        "borderTop": "1px solid lightgray",
        "display": "none",
        "zIndex": "1000",
        "& span":{
            "& span":{
                "fontFamily": "En",
            }
        },
        [theme.breakpoints.down('sm')]: {
            "display": "flex",
        }
    },
    minWidthBtn:{
        "minWidth": "60px !important",
        "color": "white"
    }
    ,
    selected:{
        "color": "#00EBFF !important"
    }


});


class BottomNavigationComponent extends Component {
    constructor(props){
        super(props);
        this.state={
            value:0,
            valueTop:0,
        }
        this.handleGoToProfile = this.handleGoToProfile.bind(this);

    }


    handleGoToProfile = () => {
        this.props.history.push('/profile')
    };

    handelOnChangeBtn(event,value){

        this.setState({
            value:value,
            valueTop:value,
        })
        if (value===0){
            this.props.changeBottomNavStatus(0,0)
            this.props.history.push("/")
        }
        if (value===1){
            this.props.changeBottomNavStatus(1,1)
            this.props.history.push("/casts")
        }
        if (value===2){
            this.props.changeBottomNavStatus(2,2)
            this.props.history.push("/page/user/search")
        }
        if (value===3){
            this.props.changeBottomNavStatus(3,3)
            this.props.history.push("/all/my/activity")
        }
        if (value===4){
            this.props.changeBottomNavStatus(4,4)
            this.props.history.push("/profile")
        }
    }


    render() {
        const { bottomNav ,classes} = this.props;


        return (
            <div id="BottomNavigation">
                <BottomNavigation
                    value={bottomNav.value}
                    className={classes.root}
                >
                    <BottomNavigationAction classes={{root: classes.minWidthBtn,selected: classes.selected}} onClick={ ()=>{this.handelOnChangeBtn(this,0)} } label="Home" icon={<Home size={20}  />} />
                    <BottomNavigationAction classes={{root: classes.minWidthBtn,selected: classes.selected}} onClick={ ()=>{this.handelOnChangeBtn(this,1)} } label="Casting" icon={<Briefcase size={20}  />} />
                    <BottomNavigationAction classes={{root: classes.minWidthBtn,selected: classes.selected}}  onClick={ ()=>{this.handelOnChangeBtn(this,2)} } label="Search" icon={<Search size={20}   />} />
                    <BottomNavigationAction classes={{root: classes.minWidthBtn,selected: classes.selected}} onClick={ ()=>{this.handelOnChangeBtn(this,3)} } label="Activity" icon={<Activity size={20}  />} />
                    <BottomNavigationAction classes={{root: classes.minWidthBtn,selected: classes.selected}} onClick={ ()=>{this.handelOnChangeBtn(this,4)} } label="Profile" icon={<User size={20}  />} />
                </BottomNavigation>
            </div>
        );
    }
}

BottomNavigationComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        bottomNav : state.bottomNav,
    }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps,{changeBottomNavStatus})(BottomNavigationComponent)));
