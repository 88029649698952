import * as types from "../constants/ActionTypes";


const initialState = {
    group_id:null,
    country_id:null,
    city_id:null,
    gender:null,
}


const castSearchData = (state = initialState , action) => {
  switch (action.type) {
      case types.CHANGE_CAST_SEARCH_DATA:
          const {data} = action;
          return {
              ...state,
              group_id:data.groupId,
              country_id:data.countryId,
              city_id:data.cityId,
              gender:data.selectedValueGender,
          }
      default:
          return state;
  }
}


export default castSearchData;
