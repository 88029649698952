import React, { Component  } from 'react';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles/index";
import {baseUrl} from "../constants/constant";

const styles = (theme) => ({
    mainDivCover:{
        "display": "flex",
        "marginTop": "10px",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        "backgroundColor": "#2f2f2f",
        "borderRadius": "5px",
        "boxShadow": "0 1px 3px 0px rgba(0,0,0,.5)",
        [theme.breakpoints.up('xs')]: {
            "width": "46vw",
            "fontSize": "8px",
            "margin": "1% 2%",
        },[theme.breakpoints.up('sm')]: {
            "width": "46vw",
            "fontSize": "9px",
            "margin": "1% 2%",
        },[theme.breakpoints.up('md')]: {
            "width": "15vw",
            "fontSize": "9px",
            "margin": "1% 2%",
        },
        [theme.breakpoints.up('lg')]: {
            "width": "15vw",
            "fontSize": "6px",
            "margin": "1% 0 1% 1%"
        },
        [theme.breakpoints.up('xl')]: {
            "width": "15vw",
            "fontSize": "8px",
            "margin": "1% 0 1% 1%"
        },
        [theme.breakpoints.up('xxl')]: {
            "width": "15vw",
            "fontSize": "12px",
            "margin": "1% 0 1% 1%"
        },

    },
    titleCard:{
        "display": "flex",
        "width": "100%",
        "height": "15%",
        "paddingLeft": "4%",
        "justifyContent": "flex-start",
        "alignItems": "center",
    },
    photoCard:{
        "width": "46vw",
        "height": "61vw",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        [theme.breakpoints.up('xs')]: {
            "width": "46vw",
            "height": "61vw",
        },[theme.breakpoints.up('sm')]: {
            "width": "46vw",
            "height": "61vw",
        },[theme.breakpoints.up('md')]: {
            "width": "15vw",
            "height": "20vw",
        },
        [theme.breakpoints.up('lg')]: {
            "width": "15vw",
            "height": "20vw",
        },
        [theme.breakpoints.up('xl')]: {
            "width": "15vw",
            "height": "20vw",
        },
        [theme.breakpoints.up('xxl')]: {
            "width": "15vw",
            "height": "20vw",
        },
    },
    detailCard:{
        "width": "100%",
        "height": "15%",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "3% 0",

    },
    detailLeftCard:{
        "width": "38%",
        "display": "flex",
        "alignItems": "flex-start",
        "paddingLeft": "4%",
        "flexDirection": "column",
        "justifyContent": "center",
        "height": "100%",
    },
    detailCenterCard:{
        "width": "38%",
        "display": "flex",
        "alignItems": "flex-start",
        "paddingLeft": "4%",
        "flexDirection": "column",
        "justifyContent": "center",
        "height": "100%",
        "& div ":
            {
                color:"#ff8630"
            }
    },
    detailRightCard:{
        "display": "flex",
        "width": "24%",
        "fontSize": "10px",
        "justifyContent": "center",
        "alignItems": "center",
    },
    detailCardP:{
        "height": "100%",
        "display": "flex",
        "justifyContent": "space-evenly",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "& p":{
            "margin": "1px 0"
        },
    },
});


class CardComponent extends Component {

    constructor(props){
        super(props);
        this.state ={

        }
    }

    handleGoToUserProfileBtn(id){
        this.props.history.push(`/user/${id}/profile`)
    }

    render() {

        const {classes,user}=this.props;

        return (

            <div className={classes.mainDivCover}>

                <div className={classes.titleCard} >
                    <h3>{user.name.substr(0, 10) + " " + user.last_name.substr(0, 10)}</h3>
                </div>

                <div onClick={()=> {this.handleGoToUserProfileBtn(user.id)}} className={classes.photoCard} style={{backgroundImage:`url('${baseUrl+user.main_photo}')`}} ></div>

                <div className={classes.detailCard} >
                    <div className={classes.detailLeftCard}>
                        <div className={classes.detailCardP} >
                            <p>Type : </p>
                            <p>Location : </p>
                        </div>
                    </div>
                    <div className={classes.detailCenterCard}>
                        <div className={classes.detailCardP} >
                            <p>{user.group.title.substr(0, 15)}{user.group.title.length>15?"...":""}</p>
                            <p>{user.city.title.substr(0, 15)}{user.group.title.length>15?"...":""}</p>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth.isAuthenticated,
    }
}

export default withStyles(styles)(connect(mapStateToProps,)(CardComponent));
