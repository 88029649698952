import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import {addCVUser, changeLoadingStatus, changeSnackStatus, deleteCVUser} from "../actions";
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import Button from "@material-ui/core/es/Button/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {baseUrl} from "../constants/constant";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "11px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "10px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "13px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "15px",
        }
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    firstDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },
    firstRightDivEditProfile:{
        "marginRight": "5%",
        "color": "#00ebff"
    },
    secondDivEditConnection:{
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "space-evenly",
        "height": "72vh"
    },
    fourthDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "width": "85%"
    },
    fourthDivTitleEditConnection:{
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "marginBottom": "10%",
        "marginTop": "-10%",
        "& h3":{
            "marginBottom": "0",
            "color": "#fffbf0"
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    photoDivAddResume:{
        "display": "flex",
        "justifyContent": "center",
        "marginBottom": "3vw",
        "width": "100%",
    },
    button: {
        margin: theme.spacing(1),
        "color": "white",
        "border": "1px solid",
        "fontFamily": "En",
        "width": "45%",
    },
    deleteButton: {
        margin: theme.spacing(1),
        "color": "white",
        "border": "1px solid",
        "fontFamily": "En",
        "width": "30%",
    },
    uploadButton: {
        "backgroundColor": "#00ebff",
        "color": "#222",
        "fontFamily": "En",
        "width": "100%",
    },
    input: {
        display: 'none',
    },
    coverButtons:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "90%"
    },
    mainCoverPhotoDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    resumeLogo:{
        "width": "150px",
        "height": "150px",
    },
    labelUpload:{
        width: "80%",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "78%",
        }
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    jkjl:{},
});


class AddResumeComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            file:null,
        };
        this.onChangeInput = this.onChangeInput.bind(this);
    }

    componentDidMount(){
        const {linkedin,facebook,instagram,youtube,skype,telegram,email} =this.props.user;
        this.setState({
            linkedin: linkedin,
        })
    }

    handleGoBackSetting(){
        this.props.history.push(`/setting`)
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleViewResume() {
        const {user} = this.props;
        if(user.cv){
            window.open(baseUrl+`download/${user.id}/resume`, '_blank').focus();
        }else{
            this.props.changeSnackStatus(true,"warning",`please upload your resume!`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }
    };

    handleDeleteResume = () => {
        const {user,auth} = this.props;
        if(user.cv){
            this.props.changeLoadingStatus(true);
            this.props.deleteCVUser(auth.apiToken);
        }else{
            this.props.changeSnackStatus(true,"warning",`You have no resume!`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }
    };

    onChangeInput(e) {
        const {auth} = this.props;
        let data = e.target.files
        if(e.target.files[0]!=null){
            this.props.changeLoadingStatus(true);
            this.props.addCVUser(data[0],auth.apiToken);
        }
    }

    render() {
        const {  classes,user,loading  } = this.props;
        const {} = this.state;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                   <div className={classes.firstDivEditProfile}>
                       <di onClick={ ()=> {this.handleGoBackSetting()}} className={classes.firstLeftDivEditProfile}>
                           <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                               <ArrowBackIosIcon fontSize="small" />
                           </IconButton>
                       </di>

                   </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.fourthDivEditProfile}>

                            <div className={classes.photoDivAddResume}>
                                <img className={classes.resumeLogo} src="/images/resume.png" alt=""/>
                            </div>

                            <div className={classes.fourthDivTitleEditConnection}>
                                <h3>You can add your resume to profile</h3>
                            </div>

                        </div>

                        <div className={classes.mainCoverPhotoDiv}>

                            <div className={classes.coverButtons}>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    className={classes.button}
                                    startIcon={<EventNoteIcon />}
                                    onClick={ ()=> {this.handleViewResume()}}
                                >
                                    View
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="default"
                                    className={classes.deleteButton}
                                    startIcon={<DeleteIcon />}
                                    onClick={ ()=> {this.handleDeleteResume()}}
                                >
                                    Delete
                                </Button>
                            </div>
                            <div className={classes.coverButtons}>
                                <input
                                    accept="application/pdf, application/vnd.ms-excel"
                                    className={classes.input}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={ this.onChangeInput}
                                />
                                <label className={classes.labelUpload}  htmlFor="contained-button-file">
                                    <Button variant="contained"  className={classes.uploadButton} color="default" component="span">
                                        Upload
                                    </Button>
                                </label>
                            </div>

                        </div>

                    </div>



                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>

        );
    }
}

AddResumeComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{addCVUser,deleteCVUser,changeLoadingStatus,changeSnackStatus})(AddResumeComponent));