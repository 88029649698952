import * as types from "../constants/ActionTypes";


const initialState = {
    data : [],
}


const selectedCast = (state = initialState , action) => {
  switch (action.type) {
      case types.GET_CAST_DETAIL:
          return {
              ...state,
              data :action.data,

          }
      case types.INIT_SELECTED_CAST:
          return {
              ...state,
              data :null,

          }
      default:
          return state;
  }
}


export default selectedCast;
