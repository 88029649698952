import * as types from "../constants/ActionTypes";


const initialState = {
    variant:'success',
    message:'',
    open:false,
    horizontal:'center',
    vertical:'top',
}


const snackProps = (state = initialState , action) => {
  switch (action.type) {
      case types.CHANGE_SNACK_PROPS:
          const {variant,message,open,horizontal,vertical} = action;
          return {
              ...state,
              variant,
              message,
              open,
              horizontal,
              vertical,
          }
      default:
          return state;
  }
}


export default snackProps;
