import * as types from "../constants/ActionTypes";


const initialState = {
    open:false,
    title:"",
    message:"",
    itemId:null,
    actionType:"",
}


const alertStatus = (state = initialState , action) => {
  switch (action.type) {
      case types.CHANGE_ALERT_PROPS:
          const {open,message,title,itemId,actionType} = action;
          return {
              ...state,
              open:open,
              title:title,
              message:message,
              itemId:itemId,
              actionType:actionType,
          }
      default:
          return state;
  }
}


export default alertStatus;
