import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import './../styles/css/animation_profile.css';

const styles = theme => ( {
    mainDiv:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "92vh",
        overflow: "hidden",
        "position": "relative",

    },

});


class NoMatchContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {

        };
    }

    componentDidMount(){

    }

    render() {
        const { classes } = this.props;

        return (
            <div  className={classes.mainDiv}>
                <div>
                    <h4>Not Found</h4>
                </div>
            </div>

        );
    }
}

NoMatchContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {

    }
}

export default withStyles(styles)(connect(mapStateToProps,null)(NoMatchContainer));