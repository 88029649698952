import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import './../styles/css/main.css';
import Button from "@material-ui/core/es/Button/Button";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import Select from "@material-ui/core/es/Select/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Input from "@material-ui/core/es/Input/Input";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import Slider from "@material-ui/core/es/Slider/Slider";
import Radio from "@material-ui/core/es/Radio/Radio";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {countryLogin} from "../constants/ActionTypes";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {changeLoadingStatus, changeSnackStatus, completeAccountDataLogin, resetLogin, sendCode,sendPhoneNumber} from "../actions";
import {baseUrl} from "../constants/constant";
import 'react-image-crop/dist/ReactCrop.css';
import './../styles/css/App.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {addPhotoUser, deletePhotoUser} from "../actions";
import Slide from "@material-ui/core/es/Slide/Slide";
import CloseIcon from '@material-ui/icons/Close';
import ReactCrop from 'react-image-crop';
import DeleteIcon from '@material-ui/icons/Delete';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import LockIcon from '@material-ui/icons/Lock';



const styles = theme => ( {
    mainDiv:{
        "display": "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        color:"white",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "11px"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px"
        },[theme.breakpoints.up('md')]: {
            "fontSize": "13px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "13px",
        }
    },
    mainCover:{
        "display": "flex",
        "width": "100%",
        "height": "100%",
        "left": "0",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    mainCoverWeb:{
        "display": "flex",
        "width": "100%",
        "height": "100%",
        "left": "0",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    secondMainCover:{
        "display": "flex",
        "left": "100%",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    secondCoverWeb:{
        "display": "flex",
        "left": "100%",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    thirdMainCover:{
        "display": "flex",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "backgroundColor": "#222",
        "overflow": "scroll",
    },
    fourthMainCover:{
        "left": "100%",
        "top": "0px",
        "width": "100%",
        "height": "100%",
        "display": "flex",
        "position": "absolute",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "space-evenly",
        "backgroundColor": "#222"
    },fourthDivMainCoverA:{
        "height": "250%",
        "overflow": "scroll",
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start"
    },
    titleCompleteAccount: {
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "height": "20vh"
    },

    phoneNumberDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "marginTop": "15px",
        "width": "100%"
    },
    phoneNumberDiv1:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "height": "fit-content",
        [theme.breakpoints.up('md')]: {
            "height": "fit-content"
        },

    },
    phoneNumberDivX:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        height: "65vh",
        [theme.breakpoints.up('md')]: {
            height: "75vh",
        },

    },
    phoneNumberDiv2:{
        "display": "flex",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexWrap": "wrap"
    },
    phoneNumberDiv3:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
    },
    genderLabel:
        {
            "marginLeft": "-7px",
            "marginRight": "5px",
        }
    ,
    pIcon:
        {
            "backgroundColor": "#bdbdbd",
            "borderRadius": "7px 0 0 7px",
            "height": "29px",
            "border": "1px solid #707070",
            "borderRight": "none"
        }
    ,
    input:
        {
            "color": "white",
            "border": "1px solid #707070",
            "height": "35px",
            "borderRadius": "0 7px 7px 0",
            "backgroundColor": "#222",
            "width": "70%",
            [theme.breakpoints.up('md')]: {
                "height": "25px",
            },
        },
    sendBtn:
        {
            "backgroundColor": "#00EBFF",
            "borderRadius": "5px",
            "width": "140px",
            "height": "29px",
            "color": "#222",
            "fontSize": "12px",
        },
    sendBtnDiv:
        {
            "display": "flex",
            "justifyContent": "center",
            "alignItems": "center",
            "margin": "6vh"
        },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        "width": "100%"
    },
    selectBtn: {
        "height": "40px !important",
        "borderRadius": "7px 0 0 7px",
        "backgroundColor": "#716e6e",
        "minWidth": "50px !important",
        color:"white",
        [theme.breakpoints.up('md')]: {
            "height": "30px !important",
        },
    },
    phoneIcon: {
        "backgroundColor": "#716e6e",
        "borderRadius": "7px 0 0 7px",
        "height": "29px",
        "width": "46px",
        [theme.breakpoints.down('md')]: {
            "height": "41px",
        },
    },
    typeIconDiv: {
        "width": "35%",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "& :nth-child(2)":
            {
                "margin": "0 0px",
                "opacity": ".5"
            }
    },
    typeIcon: {
        "borderRadius": "7px 0 0 7px",
        "width": "35%"
    },
    avatarType: {
        "width": "60px",
        "height": "60px",
        "borderRadius": "0",
        "opacity": ".5",
        [theme.breakpoints.up('md')]: {
            "width": "35px",
            "height": "35px",
        },

    },
    message1: {
        "width": "74%",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "textAlign": "center",
        "& p":{
            "color": "#bbb3b3"
        },"& a":{
            "color": "#00ebff",
            "textDecoration": "none"
        },
        [theme.breakpoints.up('md')]: {
            "width": "100%",
        },
    },
    message2: {
        "width": "100%",
        "textAlign": "center",
        "& p":{
            "color": "#bbb3b3"
        },
        "& :nth-child(2)":{
            "color": "#00ebff",
            "marginTop": "-5px"
        },
        [theme.breakpoints.up('md')]: {
            "width": "100%",
        },
    },
    message3: {
        "width": "70%",
        "& p":{
            "fontSize": "12px",
            "color": "#bbb3b3",
            "textAlign": "center"
        }
    },
    message4: {
        "width": "90%",
        "textAlign": "center",
        "& p":{
            "color": "#e0e0e0",
            "margin": "-8px 0px 0px 0",
            "fontSize": "15px"
        }
    },
    divTitle: {
        "width": "70%",
        "marginTop": "6vh",
        "& h3":{
            "textAlign": "center"
        }
    },
    ageRange: {
        color: "#ff8630",
        width: "100%",
        margin: "-12px auto",
    },
    settingItemDiv:{
        width: "95%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        margin: "15px auto"
    },
    radioBtn: {
        color: "#ff8630 !important",
    },
    genderTitle: {
        "width": "100%"
    },
    countryDiv:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "border": "1px solid dimgray",
        "borderRadius": "4px",
        "height": "28px",
        "color": "#ff8630",
        "marginTop": "10px",
        "backgroundColor": "#101010",
        "& div":
            {
                "margin": "0 10px",
                "width": "80%",
            }
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },

    secondDivWeb:{
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-evenly",
        "flexDirection": "column",
        "height": "50vh",
        "width": "30%",
        "border": "1px solid lightgray",
        "borderRadius": "5px",
        "padding": "0px 50px",
        "backgroundColor": "#333",
    },
    downloadDivWeb:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "36%"
    },
    socialDivWeb:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "marginTop": "4%"

    },
    backBtnTopDiv:{
        "display": "flex",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
    },
    backBtnTopSelectTypeWebDiv:{
        "display": "flex",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
        [theme.breakpoints.down('sm')]: {
            "display": "none",
        },
    },
    backBtnTopSelectTypeDiv:{
        "display": "none",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
        [theme.breakpoints.down('sm')]: {
            "display": "flex",
        },
    },
    fontEn:{
        "fontFamily": "En",
        "& h2":{
            "fontFamily": "En",
        },
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imgCountry:{
        width:"20px",
        height:"20px",
        borderRadius:"5px",
        marginRight:"5px",
    },
    paperRoot:{
        "width": "40%",
        "maxWidth": "50%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "95%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "95%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('md')]: {
            "width": "95%",
            "maxWidth": "100%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "40%",
            "maxWidth": "50%",
        }
    },
    socialItemGoogleDivWeb:{
        "width": "48%",
        "border": "1px solid lightgray",
        "backgroundColor": "azure",
        "borderRadius": "10px",
    },
    socialItemAppleDivWeb:{
        "width": "48%",
        "border": "1px solid lightgray",
        "backgroundColor": "azure",
        "borderRadius": "10px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    secondDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%",
        "height": "fit-content",
    },
    secondUpDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondUpPhotoDivEditProfile:{
        "width": "150px",
        "height": "200px",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        "borderRadius": "5px",
        "boxShadow": "0 2px 6px 0 #000000c9",
        "position": "relative",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('md')]: {
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "13vw",
            "height": "18vw"
        }
    },
    secondUpPhotoIconBtnDivEditProfile:{
        "position": "absolute",
        "bottom": "0",
    },
    secondUpPhotoIconDivEditProfile:{
        "height": "35px",
        "width": "35px",
    },
    secondUpPhotoDeleteIconBtnDivEditProfile:{
        "position": "absolute",
        "bottom": "0",
        "right": "1px",
        "color": "#dc1c1c",
    },
    secondDownDivEditProfile:{
        "& h5":{
            "color": "#A7A7A7",
            "margin": "7px",
        },
    },
    appBarDialog: {
        position: 'relative',
        "backgroundColor": "#222222",
        "boxShadow": "0 0 0 0 #222222",
        "paddingTop": "11vh",
        "height": "40px",
        "flexDirection": "row",
        "justifyContent": "flex-end",
    },
    titleDialog: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    coverUploadPhotoDialog: {
        "width": "100%",
        "display": "flex",
        "minHeight": "100%",
        "alignItems": "center",
        "paddingTop": "8vh",
        "paddingBottom": "20%",
        "justifyContent": "start",
        "backgroundColor": "#222222",
        "flexDirection": "column"

    },
    buttonUpload: {
        "fontFamily": "En",
        "margin": "25% 0",
    },
    coverGetPhone:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column"
    },
    loginLogo:{
        maxWidth: "70vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            maxWidth: "30vw",
        }
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    ll:{},
});


class LoginStep1Container extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            groupId:null,

        };
    }


    componentDidMount(){
        let appBar=document.getElementById("appBarCover")
        let BottomNavigation=document.getElementById("BottomNavigation")
        let container=document.getElementById("container")
        appBar.style.display="none";
        BottomNavigation.style.display="none";
        container.style.paddingTop="0";
    }

    componentWillUnmount(){
        let appBar=document.getElementById("appBarCover")
        let BottomNavigation=document.getElementById("BottomNavigation")
        let container=document.getElementById("container")
        appBar.style.display="flex";
        BottomNavigation.style.display="flex";
        container.style.paddingTop="8vh";
    }


    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };




    handleClickOnType = (id,groupId,count) => {

        this.setState({
            groupId:groupId
        })
        let selectedCat=this.props.appInitData.groups.filter( (group)=>{
            if(group.id===groupId){
                return group;
            }
        } )
        if(selectedCat[0]){
            let catDesc= document.getElementById(`categoryDesc`);
            catDesc.innerText=selectedCat[0].desc
        }


        let eleA= document.getElementById(`avatarType${id}`);
        let elePA= document.getElementById(`avatarP${id}`);
        eleA.style.opacity= "1"
        elePA.style.color= "#FEA257"
        elePA.style.opacity= "1"
        for(let i=0;i<count;i++){
            if(i!==id){
                let ele= document.getElementById(`avatarType${i}`);
                let eleP= document.getElementById(`avatarP${i}`);
                ele.style.opacity= ".5"
                eleP.style.opacity= ".5"
                eleP.style.color= "white"
            }

        }
    }




    handleGoToStep2Btn(){
        let groupId=this.state.groupId
        this.props.history.push(`/user/add/${groupId}/detail`)
    }


    render() {
        const {appInitData,loading,classes} = this.props;

        return (
            <div>
                <div id="mainDiv" className={classes.mainDiv} style={{backgroundImage:"url('/images/login.png')" }}>

                    <div id="selectType" className={classes.thirdMainCover}>

                        <div className={classes.fourthDivMainCoverA}>
                                <div className={classes.titleCompleteAccount}>
                                    <h2>Choose your type</h2>
                                </div>
                                <div className={classes.phoneNumberDiv1}>
                                    <div  className={classes.phoneNumberDiv2}>

                                        {
                                            appInitData.groups && appInitData.groups.map((group , index) => {
                                                return (
                                                    <div className={classes.typeIconDiv}>
                                                        <IconButton key={index} className={classes.typeIcon} aria-haspopup="true"  color="inherit">
                                                            <Avatar onClick={ ()=> {this.handleClickOnType(index,group.id,appInitData.groups.length)}} id={"avatarType"+index} className={classes.avatarType} alt="Profile photo" src={baseUrl+group.photo} />
                                                        </IconButton>
                                                        <p style={{"minHeight": "50px"}} id={"avatarP"+index} >{group.title}</p>
                                                    </div>
                                                )

                                            })

                                        }

                                    </div>
                                    <div className={classes.message3}>
                                        <p id="categoryDesc"></p>
                                    </div>
                                </div>
                                {
                                    this.state.groupId && (
                                        <div className={classes.sendBtnDiv}>
                                            <Button onClick={ ()=> {this.handleGoToStep2Btn()}} className={classes.sendBtn} variant="contained">Continue</Button>
                                        </div>
                                    )
                                }

                                {
                                    this.state.groupId===null && (
                                        <div className={classes.sendBtnDiv}>
                                            <Button disabled onClick={ ()=> {this.handleGoToStep2Btn()}} className={classes.sendBtn} style={{color:"white"}} variant="contained">Continue</Button>
                                        </div>
                                    )
                                }
                        </div>
                    </div>

                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>

        );
    }
}

LoginStep1Container.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        loading : state.loadingProps.loading,
        apiToken : state.auth.apiToken,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{addPhotoUser,deletePhotoUser,completeAccountDataLogin,sendCode,sendPhoneNumber,changeLoadingStatus,resetLogin,changeSnackStatus})(LoginStep1Container));