import React ,{Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import Input from "@material-ui/core/es/Input/Input";
import Radio from "@material-ui/core/es/Radio/Radio";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {changeBottomNavStatus, changeSearchState, initUsersSearch, initUsersTempSearch} from "../actions";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
    },
    mainDiv:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "space-evenly",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "width": "100%",
        "paddingBottom": "30vh",
            [theme.breakpoints.up('md')]: {
            "width": "80%",
        },
    },
    secondDivMainSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%",
        "marginTop": "3%",

    },
    secondDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%",
        [theme.breakpoints.up('md')]: {
            "width": "70%",
        },
    },
    secondDivCoverSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
        "position": "relative",
    },
    secondDivInputSearch:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "24%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "20%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "18%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "18%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "15%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "15%",
        }
    },
    secondDivInput1Search:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "38%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "30%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "30%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "30%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "24%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "23%",
        }
    },
    secondDivInput2Search:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "34%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "28%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "27%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "27%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "21%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "15%",
        }
    },
    secondDivInput3Search:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "22%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "18%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "18%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "18%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "14%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "12%",
        }
    },
    secondDivInputPlaceSearch:{
        "position": "absolute",
        "top": "0",
        "left": "4%",
        "margin": "2% 1%",
        "color": "#fea257",
        "pointerEvents": "none",
        [theme.breakpoints.up('xs')]: { //Iphone
            "margin": "3% 1%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "margin": "2% 1%",
        },[theme.breakpoints.up('md')]: {
            "margin": "3% 1%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "margin": "3% 1%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "margin": "2% 1%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "margin": "2% 1%",
        }
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },
    secondDivInputArrowSearch:{
        "position": "absolute",
        "top": "0",
        "right": "0",
        "color": "#fea257"
    },
    paperRoot:{
        "width": "40%",
        "maxWidth": "50%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "80%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "80%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('md')]: {
            "width": "80%",
            "maxWidth": "100%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "40%",
            "maxWidth": "50%",
        }
    },
    fontEn:{
        "fontFamily": "En",
        "& h2":{
            "fontFamily": "En",
        },
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imgCountry:{
        width:"20px",
        height:"20px",
        borderRadius:"5px",
        marginRight:"5px",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        "width": "100%"
    },
    genderTitleSearch: {
        "width": "82%",
        "marginTop": "3%"
    },
    genderCoverDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "82%",
    },
    otherTitleCoverDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "82%",
    },
    searchBtnCoverDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "82%",
    },moreBtnCoverDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "82%",
    },
    radioBtnSearch: {
        color: "#ff8630 !important",
    },
    genderLabelSearch: {
        "marginLeft": "-7px",
        "marginRight": "5px",
    },expandLabelSearch: {
        "width": "82%",
        "display": "none",
        "alignItems": "center",
        "justifyContent": "center",
        "flexDirection": "column",
        "backgroundColor": "#222222"

    },
    expandSummarySearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "position": "absolute",
        "top": "0px",
        "left": "35%",
        "color": "#16dede",
        "& div":{
            "color": "#16dede"
        }
    },
    expandContentSearch:{
        "marginTop": "50%",
        "width": "92%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "flexDirection": "column",
    },
    fourthDivCharSearch:{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "10px auto 20px",
        "& div":{
            "& p":{
                "color": "white",
                "paddingLeft": "4%"
            },
        },
    },
    fourthDivCharItemCoverSearch:{
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "start",
        "flexWrap": "wrap",
        [theme.breakpoints.up('xs')]: { //Iphone
            fontSize: 11,
        },[theme.breakpoints.up('sm')]: {//Iphone +
            fontSize: 12,
        },
        [theme.breakpoints.up('lg')]: {//Tab
            fontSize: 12,
        },
        [theme.breakpoints.up('xl')]: {//lap
            fontSize: 15,
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            fontSize: 16,
        }
    },
    fourthDivCharItemSearch:{
        "border": "1px solid #00EBFF",
        "height": "25px",
        "minWidth": "12%",
        "padding": "1% 5%",
        "borderRadius": "5px",
        "color": "#00EBFF",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2%",
        width: "fit-content",
        "flexWrap": "nowrap"
},fourthDivAgeItemSearch:{
        "color": "black",
        "minWidth": "12%",
        "border": "1px solid black",
        "height": "25px",
        "margin": "2%",
        "display": "flex",
        "padding": "1% 5%",
        "alignItems": "center",
        "borderRadius": "5px",
        "justifyContent": "center",
        "backgroundColor": "white",
        "boxShadow": "0px 2px 4px 0px black"
    },
    DividerCoverDivSearch: {
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "& h4": {
            "marginBottom": "0"
        },
    },
    dividerSearch:{
        "borderBottom": "1px solid #777",
        "width": "100%",
        "margin": "5% 0",
    },
    searchBtnDivSearch:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "backgroundColor": "#04d2e4",
        "padding": "3%",
        "borderRadius": "5px",
        "color": "#222222",
        "boxShadow": "0 3px 4px 1px black",
        "& h6":{
            "margin": "0",
        },
    },
    moreBtnDivSearch:{
        "color": "#03d8eb",
        "width": "100%",
        "display": "flex",
        "padding": "3%",
        "alignItems": "center",
        "justifyContent": "center",
        "backgroundColor": "#222222",
        "& h6":{
            "margin": "0",
        },
    },
    searchButton: {
        "backgroundColor": "#00ebff",
        "color": "#222",
        "fontFamily": "En",
        "width": "100%",
    },
    lklk:{},
});


class SearchContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            open:false,
            open1:false,
            open2:false,
            open3:false,
            showMore:false,
            type:"All",
            groupId:null,
            subgroups:null,
            age:null,
            category:"All",
            subgroupId:null,
            optionals:"",
            optionalsId:[],
            country:"All",
            countryId:null,
            cities:null,
            city:"All",
            cityId:null,
            selectedValueGender:null,
            optionalsType:[],
        };
    }

    componentWillMount(){
        this.props.initUsersSearch()
        this.props.changeBottomNavStatus(2,2)
        this.setState({
            type: this.props.userSearchData.data.type?this.props.userSearchData.data.type:"All",
            groupId: this.props.userSearchData.data.groupId?this.props.userSearchData.data.groupId:null,
            subgroups: this.props.userSearchData.data.subgroups?this.props.userSearchData.data.subgroups:null,
            selectedValueGender: this.props.userSearchData.data.selectedValueGender?this.props.userSearchData.data.selectedValueGender:null,
            optionalsType:this.props.userSearchData.data.optionalsType?this.props.userSearchData.data.optionalsType:[],
            cityId:this.props.userSearchData.data.cityId?this.props.userSearchData.data.cityId:null,
            city:this.props.userSearchData.data.city?this.props.userSearchData.data.city:"All",
            cities:this.props.userSearchData.data.cities?this.props.userSearchData.data.cities:null,
            countryId:this.props.userSearchData.data.countryId?this.props.userSearchData.data.countryId:null,
            country:this.props.userSearchData.data.country?this.props.userSearchData.data.country:"All",
            optionalsId:this.props.userSearchData.data.optionalsId?this.props.userSearchData.data.optionalsId:[],
            optionals:this.props.userSearchData.data.optionals?this.props.userSearchData.data.optionals:'',
            subgroupId:this.props.userSearchData.data.subgroupId?this.props.userSearchData.data.subgroupId:null,
            category:this.props.userSearchData.data.category?this.props.userSearchData.data.category:"All",
            age:this.props.userSearchData.data.age?this.props.userSearchData.data.age:null,
        })
    }

    handleClickOpen = () => {
        this.setState({
            open:true
        })
    };

    handleClickOpen1 = () => {
        this.setState({
            open1:true
        })
    };

    handleClickOpen2 = () => {
        this.setState({
            open2:true
        })
    };

    handleClickOpen3 = () => {
        this.setState({
            open3:true
        })
    };

    handleClose = () => {
        this.setState({
            open:false
        })
    };

    handleClose1 = () => {
        this.setState({
            open1:false
        })
    };

    handleClose2 = () => {
        this.setState({
            open2:false,
        })
    };

    handleClose3 = () => {
        this.setState({
            open3:false,
        })
    };

    handleChangeType = (event) => {
        let selectedType= this.props.appInitData.groups.filter( (group)=> {
            if(group.title===event.target.value){
                return group
            }
        } )

        let optionalsType=[];
        selectedType[0].others && selectedType[0].others.map( (optional)=> {
            if(!optionalsType.includes(optional.type)){
                optionalsType.push(optional.type)
            }
        } )


        this.setState({
            type:selectedType[0].title,
            groupId:selectedType[0].id,
            subgroups:selectedType[0].subgroups,
            optionals:selectedType[0].others,
            category:"",
            optionalsType:optionalsType,
        })
    };

    handleChangeCategory = (event) => {
        let selectedSubgroup = this.state.subgroups.filter( (subgroup)=> {
            if(subgroup.title===event.target.value){
                return subgroup
            }
        } )
        this.setState({
            category:selectedSubgroup[0].title,
            subgroupId:selectedSubgroup[0].id
        })

        this.setState({
            category:event.target.value
        })
    };

    handleChangeCountry = (event) => {
        let selectedCountry = this.props.appInitData.countries.filter( (country)=> {
            if(country.title===event.target.value){
                return country
            }
        } )

        this.setState({
            country:selectedCountry[0].title,
            countryId:selectedCountry[0].id,
            cities:selectedCountry[0].cities
        })
    };

    handleChangeCity = (event) => {
        let selectedCity = this.state.cities.filter( (city)=> {
            if(city.title===event.target.value){
                return city
            }
        } )
        this.setState({
            city:selectedCity[0].title,
            cityId:selectedCity[0].id
        })
    };

    handleChangeGender(value){
        this.setState({
            selectedValueGender:value
        })
    }

    handleClickChar = (IDs,count,optionalId) => {
        let optionalsIdsArray=this.state.optionalsId
        let finalOptionalsIdsArray=[];
        optionalsIdsArray.map( (item)=>{
            if(!IDs.includes(item)){
                finalOptionalsIdsArray.push(item)
            }
        } )
        if(!finalOptionalsIdsArray.includes(optionalId)){
            finalOptionalsIdsArray.push(optionalId)
        }
        this.setState({
            optionalsId:finalOptionalsIdsArray,
        })

        let eleA= document.getElementById(`${optionalId}charType`);
        eleA.style.backgroundColor= "#00EBFF"
        eleA.style.color= "#05080b"
       IDs.map( (id) => {
          if(optionalId!==id){
              let ele= document.getElementById(`${id}charType`);
              ele.style.backgroundColor= "#222"
              ele.style.color= "#00EBFF"
          }
       })
    }

    handleClickAge = (id,count,value) => {
        let eleA= document.getElementById(`ageType${id}`);
        eleA.style.backgroundColor= "#00EBFF"
        eleA.style.color= "#05080b"
        for(let i=0;i<=count-1;i++){
            if(i!==id){
                let ele= document.getElementById(`ageType${i}`);
                ele.style.backgroundColor= "#fff"
                ele.style.color= "#222"
            }
        }
        this.setState({
            age:value==="All"?null:value,
        })
    }

    handleShowMoreBtn = () => {
        let showMore = this.state.showMore;
        let showMoreIcon= document.getElementById(`moreIcon`);
        let showLessIcon= document.getElementById(`lessIcon`);
        let showMoreTXT= document.getElementById(`showMoreTXT`);
        let moreItemDiv= document.getElementById(`moreItemDiv`);

        if (showMore===false){

            this.setState({
                showMore:true
            })

            showMoreIcon.style.display= "none"
            showLessIcon.style.display= "block"
            moreItemDiv.style.display= "flex"
            showMoreTXT.innerText= "Show less"
        }
        if (showMore===true){

            this.setState({
                showMore:false
            })

            showMoreIcon.style.display= "block"
            showLessIcon.style.display= "none"
            moreItemDiv.style.display= "none"
            showMoreTXT.innerText= "Show more"

        }


    }

    handleSearchBtn(){
        this.props.changeSearchState(this.state)
        /*this.props.initUsersTempSearch()*/
        this.props.history.push('/search/result/user')
    }

    handleResetBtn(){
        this.props.changeSearchState({})
        this.props.initUsersSearch()
        this.setState({
            type:"All",
            groupId:null,
            subgroups:null,
            selectedValueGender: null,
            optionalsType:[],
            cityId:null,
            city:"All",
            cities:null,
            countryId:null,
            country:"All",
            optionalsId:[],
            optionals:'',
            subgroupId:null,
            category:"All",
            age:null,
        })
    }

    render() {
        const {classes,appInitData ,userSearchData} = this.props;
        const {optionals,optionalsType,cities,subgroups, open,open1,open2,open3,type ,country,category,city,selectedValueGender} = this.state;

        return (
            <div className={classes.cover}>


                {
                    userSearchData && appInitData &&
                    <div  className={classes.mainDiv}>

                        <div className={classes.secondDivMainSearch}>

                            <div className={classes.secondDivSearch}>


                                <div  className={classes.searchBtnCoverDivSearch}>
                                    <Button onClick={()=> {this.handleResetBtn()}} style={{width:"100%"}}  color="secondary">
                                        Reset All filters
                                    </Button>
                                </div>

                                <div onClick={this.handleClickOpen} className={classes.secondDivCoverSearch}>
                                    <input className={classes.secondDivInputSearch} value={type} type="text" disabled />
                                    <h4 className={classes.secondDivInputPlaceSearch}>Type :</h4>
                                    <div className={classes.secondDivInputArrowSearch}>
                                        <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                            <KeyboardArrowDownRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                    <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose}>
                                        <DialogTitle className={classes.fontEn}>Select type</DialogTitle>
                                        <DialogContent>

                                            <form className={classes.container}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id="demo-dialog-select-label">Type</InputLabel>
                                                    <Select
                                                        labelId="demo-dialog-select-label"
                                                        id="demo-dialog-select"
                                                        value={type}
                                                        onChange={this.handleChangeType}
                                                        input={<Input />}
                                                    >
                                                        {
                                                            appInitData.groups && appInitData.groups.map((group , index) => {
                                                                return <MenuItem key={index} value={group.title}>{group.title}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </form>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button className={classes.fontEn} onClick={this.handleClose} color="primary">
                                                Cancel
                                            </Button>
                                            <Button className={classes.fontEn} onClick={this.handleClose} color="primary">
                                                Ok
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>


                                <div onClick={this.handleClickOpen1} className={classes.secondDivCoverSearch}>
                                    <input className={classes.secondDivInput1Search} value={category} type="text" disabled />
                                    <h4 className={classes.secondDivInputPlaceSearch}>Category :</h4>
                                    <div className={classes.secondDivInputArrowSearch}>
                                        <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                            <KeyboardArrowDownRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                    <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open1} onClose={this.handleClose1}>
                                        <DialogTitle className={classes.fontEn}>Select category</DialogTitle>
                                        <DialogContent>

                                            <form className={classes.container}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id="demo-dialog-select-label1">Category</InputLabel>
                                                    <Select
                                                        labelId="demo-dialog-select-label1"
                                                        id="demo-dialog-select2"
                                                        value={category}
                                                        onChange={this.handleChangeCategory}
                                                        input={<Input />}
                                                    >
                                                        {
                                                            subgroups && subgroups.map((subgroup , index) => {
                                                                return <MenuItem key={index} value={subgroup.title}>{subgroup.title}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </form>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button className={classes.fontEn} onClick={this.handleClose1} color="primary">
                                                Cancel
                                            </Button>
                                            <Button className={classes.fontEn} onClick={this.handleClose1} color="primary">
                                                Ok
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div onClick={this.handleClickOpen2} className={classes.secondDivCoverSearch}>
                                    <input className={classes.secondDivInput2Search} value={country} type="text" disabled />
                                    <h4 className={classes.secondDivInputPlaceSearch}>Country :</h4>
                                    <div className={classes.secondDivInputArrowSearch}>
                                        <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                            <KeyboardArrowDownRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                    <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open2} onClose={this.handleClose2}>
                                        <DialogTitle className={classes.fontEn}>Select country</DialogTitle>
                                        <DialogContent>

                                            <form className={classes.container}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id="demo-dialog-select-label2">Country</InputLabel>
                                                    <Select
                                                        labelId="demo-dialog-select-label2"
                                                        id="demo-dialog-select2"
                                                        value={country}
                                                        onChange={this.handleChangeCountry}
                                                        input={<Input />}
                                                    >
                                                        {
                                                            appInitData.countries && appInitData.countries.map((country , index) => {
                                                                return <MenuItem key={index} value={country.title}>{country.title}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </form>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button className={classes.fontEn} onClick={this.handleClose2} color="primary">
                                                Cancel
                                            </Button>
                                            <Button className={classes.fontEn} onClick={this.handleClose2} color="primary">
                                                Ok
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>

                                <div onClick={this.handleClickOpen3} className={classes.secondDivCoverSearch}>
                                    <input className={classes.secondDivInput3Search} value={city} type="text" disabled />
                                    <h4 className={classes.secondDivInputPlaceSearch}>City :</h4>
                                    <div className={classes.secondDivInputArrowSearch}>
                                        <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                            <KeyboardArrowDownRoundedIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                    <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open3} onClose={this.handleClose2}>
                                        <DialogTitle className={classes.fontEn}>Select city</DialogTitle>
                                        <DialogContent>

                                            <form className={classes.container}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id="demo-dialog-select-label3">City</InputLabel>
                                                    <Select
                                                        labelId="demo-dialog-select-label3"
                                                        id="demo-dialog-select2"
                                                        value={city}
                                                        onChange={this.handleChangeCity}
                                                        input={<Input />}
                                                    >
                                                        {
                                                            cities && cities.map((city , index) => {
                                                                return <MenuItem key={index} value={city.title}>{city.title}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </form>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button className={classes.fontEn} onClick={this.handleClose3} color="primary">
                                                Cancel
                                            </Button>
                                            <Button className={classes.fontEn} onClick={this.handleClose3} color="primary">
                                                Ok
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>


                                <div  className={classes.genderTitleSearch}>
                                    Gender
                                </div>
                                <div  className={classes.genderCoverDivSearch}>
                                    <div className={classes.genderCoverDivSearch}>
                                        <Radio
                                            classes={{root: classes.radioBtnSearch}}
                                            checked={selectedValueGender === null}
                                            onChange={()=>{this.handleChangeGender(null)}}
                                            value="Male"
                                            name="radio-button-demo"
                                            size="small"
                                        />
                                        <p className={classes.genderLabelSearch}>All</p>
                                    </div>
                                    <div className={classes.genderCoverDivSearch}>
                                        <Radio
                                            classes={{root: classes.radioBtnSearch}}
                                            checked={selectedValueGender === 'Male'}
                                            onChange={()=>{this.handleChangeGender("Male")}}
                                            value="Male"
                                            name="radio-button-demo"
                                            size="small"
                                        />
                                        <p className={classes.genderLabelSearch}>Male</p>
                                    </div>
                                    <div className={classes.genderCoverDivSearch}>
                                        <Radio
                                            classes={{root: classes.radioBtnSearch}}
                                            checked={selectedValueGender === 'Female'}
                                            onChange={()=>{this.handleChangeGender("Female")}}
                                            value="Female"
                                            name="radio-button-demo"
                                            size="small"
                                        />
                                        <p className={classes.genderLabelSearch}>Female</p>
                                    </div>
                                    <div className={classes.genderCoverDivSearch}>
                                        <Radio
                                            classes={{root: classes.radioBtnSearch}}
                                            checked={selectedValueGender === 'Others'}
                                            onChange={()=>{this.handleChangeGender("Others")}}
                                            value="Others"
                                            name="radio-button-demo"
                                            size="small"
                                        />
                                        <p className={classes.genderLabelSearch}>Others</p>
                                    </div>
                                </div>

                                <div  className={classes.moreBtnCoverDivSearch}>
                                    <IconButton onClick={()=> {this.handleShowMoreBtn()}} style={{width:"50%"}} aria-haspopup="true"  color="inherit">
                                        <div className={classes.moreBtnDivSearch} >
                                            <h6 id="showMoreTXT">Show More</h6>
                                            <ExpandMoreIcon id="moreIcon" />
                                            <ExpandLessIcon id="lessIcon" style={{display:"none"}} />
                                        </div>
                                    </IconButton>
                                </div>

                                {/*Show more Item*/}
                                <div id="moreItemDiv" className={classes.expandLabelSearch}>

                                    <div  className={classes.otherTitleCoverDivSearch}>
                                        <h3> Age</h3>
                                    </div>
                                    <div className={classes.fourthDivCharSearch}>

                                        <div className={classes.fourthDivCharItemCoverSearch}>
                                            <div id="ageType0" onClick={ ()=> {this.handleClickAge(0,8,"All")}} className={classes.fourthDivAgeItemSearch}>All</div>
                                            <div id="ageType1" onClick={ ()=> {this.handleClickAge(1,8,"4,10")}} className={classes.fourthDivAgeItemSearch}>4-10</div>
                                            <div id="ageType2" onClick={ ()=> {this.handleClickAge(2,8,"10,18")}} className={classes.fourthDivAgeItemSearch}>10-18</div>
                                            <div id="ageType3" onClick={ ()=> {this.handleClickAge(3,8,"18,24")}} className={classes.fourthDivAgeItemSearch}>18-24</div>
                                            <div id="ageType4" onClick={ ()=> {this.handleClickAge(4,8,"24,30")}} className={classes.fourthDivAgeItemSearch}>24-30</div>
                                            <div id="ageType5" onClick={ ()=> {this.handleClickAge(5,8,"30,40")}} className={classes.fourthDivAgeItemSearch}>30-40</div>
                                            <div id="ageType6" onClick={ ()=> {this.handleClickAge(6,8,"40,50")}} className={classes.fourthDivAgeItemSearch}>40-50</div>
                                            <div id="ageType7" onClick={ ()=> {this.handleClickAge(7,8,"50,200")}} className={classes.fourthDivAgeItemSearch}>+50</div>
                                        </div>
                                    </div>


                                    <div className={classes.DividerCoverDivSearch}>
                                        <div className={classes.dividerSearch}></div>
                                    </div>

                                    <div  className={classes.otherTitleCoverDivSearch}>
                                        <h3> Characteristic</h3>
                                    </div>


                                    {
                                        optionals && optionalsType.length>0 && optionalsType.map( (type)=>{
                                            let count=0;
                                            let IDs=[];
                                            optionals.map( (optional)=>{  if(optional.type===type){count=count+1}   } )
                                            return (
                                                <div className={classes.fourthDivCharSearch}>
                                                    <div className={classes.fourthDivCharTitleEditProfile}>
                                                        <p >{type}</p>
                                                    </div>
                                                    <div className={classes.fourthDivCharItemCoverSearch}>

                                                        {
                                                            optionals && optionals.map( (optional,index)=>{
                                                                if(optional.type===type){
                                                                    IDs.push(optional.id);
                                                                    return (
                                                                        <div key={index} id={optional.id+"charType"} onClick={ ()=> {this.handleClickChar(IDs,count,optional.id)}} className={classes.fourthDivCharItemSearch}>{optional.title}</div>
                                                                    )
                                                                }
                                                            } )
                                                        }

                                                    </div>
                                                </div>
                                            )


                                        } )

                                    }

                                    {
                                        optionalsType.length===0 &&
                                        <h6>There is no optionals</h6>
                                    }


                                </div>


                                <div  className={classes.searchBtnCoverDivSearch}>
                                    <Button onClick={()=> {this.handleSearchBtn()}} variant="contained"  className={classes.searchButton} color="default" component="span">
                                        Search
                                    </Button>
                                </div>

                            </div>

                        </div>

                    </div>
                }

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        loading : state.loadingProps.loading,
        userSearchData : state.userSearchData,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{changeBottomNavStatus,initUsersSearch,changeSearchState,initUsersTempSearch})(SearchContainer));