import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import './../styles/css/main.css';
import Button from "@material-ui/core/es/Button/Button";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import Select from "@material-ui/core/es/Select/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Input from "@material-ui/core/es/Input/Input";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import Slider from "@material-ui/core/es/Slider/Slider";
import Radio from "@material-ui/core/es/Radio/Radio";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {countryLogin} from "../constants/ActionTypes";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {changeLoadingStatus, changeSnackStatus, completeAccountDataLogin, resetLogin, sendCode,sendPhoneNumber} from "../actions";
import {baseUrl} from "../constants/constant";
import 'react-image-crop/dist/ReactCrop.css';
import './../styles/css/App.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {addPhotoUser, deletePhotoUser} from "../actions";
import Slide from "@material-ui/core/es/Slide/Slide";
import CloseIcon from '@material-ui/icons/Close';
import ReactCrop from 'react-image-crop';
import DeleteIcon from '@material-ui/icons/Delete';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import LockIcon from '@material-ui/icons/Lock';



const styles = theme => ( {
    mainDiv:{
        "display": "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        color:"white",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "11px"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px"
        },[theme.breakpoints.up('md')]: {
            "fontSize": "13px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "13px",
        }

    },
    mainCover:{
        "display": "flex",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    mainCoverWeb:{
        "display": "flex",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    secondMainCover:{
        "display": "flex",
        "left": "100%",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    secondCoverWeb:{
        "display": "flex",
        "left": "100%",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    thirdMainCover:{
        "display": "flex",
        "left": "100%",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute",
        "backgroundColor": "#222",
        "overflow": "scroll",
    },
    fourthMainCover:{
        "width": "100%",
        "height": "100%",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "space-evenly",
        "backgroundColor": "#222"
    },fourthDivMainCoverA:{
        "height": "250%",
        "overflow": "scroll",
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start"
    },
    titleCompleteAccount: {
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "height": "20vh"
    },

    phoneNumberDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "marginTop": "15px",
        "width": "100%"
    },
    phoneNumberDiv1:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "height": "fit-content",
        [theme.breakpoints.up('md')]: {
            "height": "fit-content"
        },

    },
    phoneNumberDivX:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",

    },
    phoneNumberDiv2:{
        "display": "flex",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexWrap": "wrap"
    },
    phoneNumberDiv3:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
    },
    genderLabel:
        {
            "marginLeft": "-7px",
            "marginRight": "5px",
        }
    ,
    pIcon:
        {
            "backgroundColor": "#bdbdbd",
            "borderRadius": "7px 0 0 7px",
            "height": "29px",
            "border": "1px solid #707070",
            "borderRight": "none"
        }
    ,
    input:
        {
            "color": "white",
            "border": "1px solid #707070",
            "height": "35px",
            "borderRadius": "0 7px 7px 0",
            "backgroundColor": "#222",
            "width": "70%",
            [theme.breakpoints.up('md')]: {
                "height": "25px",
            },
        },
    sendBtn:
        {
            "backgroundColor": "#00EBFF",
            "borderRadius": "5px",
            "width": "140px",
            "height": "29px",
            "color": "#222",
            "fontSize": "12px",
        },
    sendBtnDiv:
        {
            "display": "flex",
            "justifyContent": "center",
            "alignItems": "center",
            "margin": "6vh"
        },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        "width": "100%"
    },
    selectBtn: {
        "height": "40px !important",
        "borderRadius": "7px 0 0 7px",
        "backgroundColor": "#716e6e",
        "minWidth": "50px !important",
        color:"white",
        [theme.breakpoints.up('md')]: {
            "height": "30px !important",
        },
    },
    phoneIcon: {
        "backgroundColor": "#716e6e",
        "borderRadius": "7px 0 0 7px",
        "height": "29px",
        "width": "46px",
        [theme.breakpoints.down('md')]: {
            "height": "41px",
        },
    },
    typeIconDiv: {
        "width": "35%",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "& :nth-child(2)":
            {
                "margin": "0 0px",
                "opacity": ".5"
            }
    },
    typeIcon: {
        "borderRadius": "7px 0 0 7px",
        "width": "35%"
    },
    avatarType: {
        "width": "60px",
        "height": "60px",
        "borderRadius": "0",
        "opacity": ".5",
        [theme.breakpoints.up('md')]: {
            "width": "40px",
            "height": "40px",
        },

    },
    message1: {
        "width": "74%",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "textAlign": "center",
        "& p":{
            "color": "#bbb3b3"
        },"& a":{
            "color": "#00ebff",
            "textDecoration": "none"
        },
        [theme.breakpoints.up('md')]: {
            "width": "100%",
        },
    },
    message2: {
        "width": "100%",
        "textAlign": "center",
        "& p":{
            "color": "#bbb3b3"
        },
        "& :nth-child(2)":{
            "color": "#00ebff",
            "marginTop": "-5px"
        },
        [theme.breakpoints.up('md')]: {
            "width": "100%",
        },
    },
    message3: {
        "width": "70%",
        "& p":{
            "fontSize": "12px",
            "color": "#bbb3b3",
            "textAlign": "center"
        }
    },
    message4: {
        "width": "90%",
        "textAlign": "center",
        "& p":{
            "color": "#e0e0e0",
            "margin": "-8px 0px 0px 0",
            "fontSize": "15px"
        }
    },
    divTitle: {
        "width": "70%",
        "marginTop": "6vh",
        "& h3":{
            "textAlign": "center"
        }
    },
    ageRange: {
        color: "#ff8630",
        width: "100%",
        margin: "-12px auto",
    },
    settingItemDiv:{
        width: "95%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        margin: "15px auto"
    },
    radioBtn: {
        color: "#ff8630 !important",
    },
    genderTitle: {
        "width": "100%"
    },
    countryDiv:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "border": "1px solid dimgray",
        "borderRadius": "4px",
        "height": "28px",
        "color": "#ff8630",
        "marginTop": "10px",
        "backgroundColor": "#101010",
        "& div":
            {
                "margin": "0 10px",
                "width": "80%",
            }
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },

    secondDivWeb:{
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-evenly",
        "flexDirection": "column",
        "height": "50vh",
        "width": "30%",
        "border": "1px solid lightgray",
        "borderRadius": "5px",
        "padding": "0px 50px",
        "backgroundColor": "#333",
    },
    downloadDivWeb:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "36%"
    },
    socialDivWeb:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "marginTop": "4%"

    },
    backBtnTopDiv:{
        "display": "flex",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
    },
    backBtnTopSelectTypeWebDiv:{
        "display": "flex",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
        [theme.breakpoints.down('sm')]: {
            "display": "none",
        },
    },
    backBtnTopSelectTypeDiv:{
        "display": "none",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
        [theme.breakpoints.down('sm')]: {
            "display": "flex",
        },
    },
    fontEn:{
        "fontFamily": "En",
        "& h2":{
            "fontFamily": "En",
        },
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imgCountry:{
        width:"20px",
        height:"20px",
        borderRadius:"5px",
        marginRight:"5px",
    },
    paperRoot:{
        "width": "40%",
        "maxWidth": "50%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "95%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "95%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('md')]: {
            "width": "95%",
            "maxWidth": "100%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "40%",
            "maxWidth": "50%",
        }
    },
    socialItemGoogleDivWeb:{
        "width": "48%",
        "border": "1px solid lightgray",
        "backgroundColor": "azure",
        "borderRadius": "10px",
    },
    socialItemAppleDivWeb:{
        "width": "48%",
        "border": "1px solid lightgray",
        "backgroundColor": "azure",
        "borderRadius": "10px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    secondDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%",
        "height": "fit-content",
    },
    secondUpDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondUpPhotoDivEditProfile:{
        "width": "150px",
        "height": "200px",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        "borderRadius": "5px",
        "boxShadow": "0 2px 6px 0 #000000c9",
        "position": "relative",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('md')]: {
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "13vw",
            "height": "18vw"
        }
    },
    secondUpPhotoIconBtnDivEditProfile:{
        "position": "absolute",
        "bottom": "0",
    },
    secondUpPhotoIconDivEditProfile:{
        "height": "35px",
        "width": "35px",
    },
    secondUpPhotoDeleteIconBtnDivEditProfile:{
        "position": "absolute",
        "bottom": "0",
        "right": "1px",
        "color": "#dc1c1c",
    },
    secondDownDivEditProfile:{
        "& h5":{
            "color": "#A7A7A7",
            "margin": "7px",
        },
    },
    appBarDialog: {
        position: 'relative',
        "backgroundColor": "#222222",
        "boxShadow": "0 0 0 0 #222222",
        "paddingTop": "11vh",
        "height": "40px",
        "flexDirection": "row",
        "justifyContent": "flex-end",
    },
    titleDialog: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    coverUploadPhotoDialog: {
        "width": "100%",
        "display": "flex",
        "minHeight": "100%",
        "alignItems": "center",
        "paddingTop": "8vh",
        "paddingBottom": "20%",
        "justifyContent": "start",
        "backgroundColor": "#222222",
        "flexDirection": "column"

    },
    buttonUpload: {
        "fontFamily": "En",
        "margin": "25% 0",
    },
    coverGetPhone:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column"
    },
    loginLogo:{
        maxWidth: "70vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            maxWidth: "30vw",
        }
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    ll:{},
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class LoginStep2Container extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            age:25,
            selectedValueGender:"Male",
            open2:false,
            open3:false,
            countries:null,
            cities:null,
            city:"Your City",
            country:"Your Country",
            countryId:null,
            cityId:null,
            groupId:null,
            firstName:null,
            lastName:null,
            base64Photo: null,
            src: null,
            openPhoto:false,
            crop: {
                unit: 'vw',
                width: 30,
                aspect: 3 / 4,
            },
        };
        this.handelRangeAge = this.handelRangeAge.bind(this);
    }

    componentDidMount(){
        let appBar=document.getElementById("appBarCover")
        let BottomNavigation=document.getElementById("BottomNavigation")
        let container=document.getElementById("container")
        appBar.style.display="none";
        BottomNavigation.style.display="none";
        container.style.paddingTop="0";
    }

    componentWillUnmount(){
        let appBar=document.getElementById("appBarCover")
        let BottomNavigation=document.getElementById("BottomNavigation")
        let container=document.getElementById("container")
        appBar.style.display="flex";
        BottomNavigation.style.display="flex";
        container.style.paddingTop="8vh";
    }

    handleChangeCountry = (event) => {
        let selectedCountry = this.props.appInitData.countries.filter( (country)=> {
            if(country.title===event.target.value){
                return country
            }
        } )

         this.setState({
             country:selectedCountry[0].title,
             countryId:selectedCountry[0].id,
             cities:selectedCountry[0].cities
         })
    };

    handleChangeCity = (event) => {
        let selectedCity = this.state.cities.filter( (city)=> {
            if(city.title===event.target.value){
                return city
            }
        } )
         this.setState({
             city:selectedCity[0].title,
             cityId:selectedCity[0].id
         })
    };

    handleChangeInputs = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        })
    };

    handleClickOpen = () => {
        this.setState({
            open:true
        })
    };

    handleClickOpen2 = () => {
        this.setState({
            open2:true
        })
    };

    handleClickOpen3 = () => {
        this.setState({
            open3:true
        })
    };

    handleClose2 = () => {
        this.setState({
            open2:false
        })
        if (this.state.country!=="Your Country"){
            let ele= document.getElementById(`countrySelect`);
            ele.innerText=this.state.country
        }
    };

    handleClose3 = () => {
        this.setState({
            open3:false
        })
        if (this.state.country!=="Your City"){
            let ele= document.getElementById(`citySelect`);
            ele.innerText=this.state.city
        }
    };

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handelRangeAge(e,value){
        this.setState({
            age:value
        })
        let ele= document.getElementById(`ageP`);
        ele.innerText="Age : " +this.state.age
    }

    handleChangeGender(value){
        this.setState({
            selectedValueGender:value
        })
    }

    handleGoToHomeBtn(){
        let groupId=this.props.match.params.id;
        const {apiToken,user}=this.props.auth
        const {firstName,lastName,selectedValueGender,age,countryId,cityId}=this.state
        if(firstName!==null && lastName!==null && groupId!==null && countryId!==null && cityId!==null ){
            this.props.changeLoadingStatus(true)
            this.props.completeAccountDataLogin(firstName,lastName,selectedValueGender,age,groupId,countryId,cityId,this.props.history,user.id,apiToken)
        }else if(firstName!==null || lastName!==null || groupId!==null || countryId!==null || cityId!==null){
            this.props.changeSnackStatus(true,"warning",`please complete your account.`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width*scaleX;
        canvas.height = crop.height*scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );

        const base64Image = canvas.toDataURL('image/jpeg');
        this.setState({ base64Photo: base64Image});

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    handleClickOpenPhoto = () => {
        this.setState({
            openPhoto:true,
        })
    };

    handleClosePhoto = () => {
        this.setState({
            openPhoto:false,
            src:null,
            croppedImageUrl:null,
        })
    };

    handleBackClick(){
        this.props.history.replace("/user/select/category")
    }

    handleClickAddPhotos = () => {
        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.addPhotoUser(user.id,this.state.base64Photo.replace("data:image/jpeg;base64,",""),"main_photo",auth.apiToken);
        this.setState({
            openPhoto:false,
            src:null,
            croppedImageUrl:null,
        })

    };

    handleDeleteCastBtn(){

        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.deletePhotoUser(user.id,null,null,"main_photo",auth.apiToken);
        this.setState({
            open:false,
            dialogPhotoSrc:'',
            dialogPhotoKey:'',
            dialogPhotoPureUrl:'',
        })
    }

    render() {
        const {appInitData,user,loading,classes} = this.props;
        const {selectedValueGender,age,open2,open3,city,country,cities,crop, croppedImageUrl, src ,openPhoto} = this.state;

        return (
            <div>
                <div id="mainDiv" className={classes.mainDiv} style={{backgroundImage:"url('/images/login.png')" }}>

                    <div id="addDetail" className={classes.fourthMainCover}>

                       <div className={classes.fourthDivMainCoverA}>

                           <div id="backBtn" onClick={ ()=> {this.handleBackClick()}} className={classes.backBtnTopDiv}>
                               <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                   <ArrowBackIosIcon fontSize="small" />
                               </IconButton>
                               <div>Back</div>
                           </div>

                           <div className={classes.divTitle}>
                               <h3>Complete your personal information</h3>
                           </div>

                           <div className={classes.secondDivEditProfile} style={{height: "45vh"}}>

                               <div className={classes.secondUpDivEditProfile}>
                                   <div  className={classes.secondUpPhotoDivEditProfile} style={{backgroundImage:`url('${ user && user.main_photo?baseUrl+user.main_photo:"/images/no-user.png"}')`}}>

                                       <IconButton onClick={()=> {this.handleClickOpenPhoto()}}  className={classes.secondUpPhotoIconBtnDivEditProfile} aria-haspopup="true"  style={{"color": "#00ebff"}}>
                                           <AddAPhotoRoundedIcon  className={classes.secondUpPhotoIconDivEditProfile} fontSize="small" />
                                       </IconButton>

                                       <IconButton onClick={()=> {this.handleDeleteCastBtn()}}  className={classes.secondUpPhotoDeleteIconBtnDivEditProfile} aria-haspopup="true"  color="inherit">
                                           <DeleteIcon  className={classes.secondUpPhotoIconDivEditProfile} fontSize="small" />
                                       </IconButton>

                                   </div>

                               </div>

                               <div className={classes.secondDownDivEditProfile}>
                                   <h5>Add your profile photo. You can change it later</h5>
                               </div>
                           </div>


                           <div className={classes.phoneNumberDivX}>

                               <div  className={classes.phoneNumberDiv}>
                                   <IconButton classes={{root: classes.phoneIcon}} aria-haspopup="true"  color="inherit">
                                       <AccountCircleRoundedIcon fontSize="small" />
                                   </IconButton>
                                   <input onChange={this.handleChangeInputs} className={classes.input} name="firstName"  type="text" placeholder="First Name" />
                               </div>

                               <div  className={classes.phoneNumberDiv}>
                                   <IconButton classes={{root: classes.phoneIcon}} aria-haspopup="true"  color="inherit">
                                       <AccountCircleRoundedIcon fontSize="small" />
                                   </IconButton>
                                   <input onChange={this.handleChangeInputs} className={classes.input} name="lastName"  type="text" placeholder="Last Name" />
                               </div>

                               <div className={classes.settingItemDiv}>
                                   <div>
                                       <p id="ageP">Age : {age}</p>
                                   </div>
                                   <Slider onChange={ this.handelRangeAge } className={classes.ageRange}   min={1} max={100} defaultValue={age}  />
                               </div>

                               <div  className={classes.genderTitle}>
                                   Gender
                               </div>

                               <div  className={classes.phoneNumberDiv3}>
                                   <div className={classes.phoneNumberDiv3}>
                                       <Radio
                                           classes={{root: classes.radioBtn}}
                                           checked={selectedValueGender === 'Male'}
                                           onChange={()=>{this.handleChangeGender("Male")}}
                                           value="Male"
                                           name="radio-button-demo"
                                           size="small"
                                       />
                                       <p className={classes.genderLabel}>Male</p>
                                   </div>
                                   <div className={classes.phoneNumberDiv3}>
                                       <Radio
                                           classes={{root: classes.radioBtn}}
                                           checked={selectedValueGender === 'Female'}
                                           onChange={()=>{this.handleChangeGender("Female")}}
                                           value="Female"
                                           name="radio-button-demo"
                                           size="small"
                                       />
                                       <p className={classes.genderLabel}>Female</p>
                                   </div>
                                   <div className={classes.phoneNumberDiv3}>
                                       <Radio
                                           classes={{root: classes.radioBtn}}
                                           checked={selectedValueGender === 'Others'}
                                           onChange={()=>{this.handleChangeGender("Others")}}
                                           value="Others"
                                           name="radio-button-demo"
                                           size="small"
                                       />
                                       <p className={classes.genderLabel}>Others</p>
                                   </div>
                               </div>

                               <div  className={classes.countryDiv}>
                                   <div onClick={this.handleClickOpen2} id="countrySelect" >Your Country</div>
                                   <IconButton onClick={this.handleClickOpen2} className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                       <ArrowDropDownIcon fontSize="small" />
                                   </IconButton>
                                   <Dialog disableBackdropClick disableEscapeKeyDown open={open2} onClose={this.handleClose2}>
                                       <DialogTitle>Choose Your country</DialogTitle>
                                       <DialogContent>
                                           <form className={classes.container}>
                                               <FormControl className={classes.formControl}>
                                                   <InputLabel id="demo-dialog-select-label2">Country</InputLabel>
                                                   <Select
                                                       labelId="demo-dialog-select-label2"
                                                       id="demo-dialog-select2"
                                                       value={country}
                                                       onChange={this.handleChangeCountry}
                                                       input={<Input />}
                                                   >
                                                       {
                                                           appInitData.countries && appInitData.countries.map((country , index) => {
                                                               return <MenuItem key={index} value={country.title}>{country.title}</MenuItem>
                                                           })
                                                       }

                                                   </Select>
                                               </FormControl>
                                           </form>
                                       </DialogContent>
                                       <DialogActions>
                                           <Button onClick={this.handleClose2} color="primary">
                                               Cancel
                                           </Button>
                                           <Button onClick={this.handleClose2} color="primary">
                                               Ok
                                           </Button>
                                       </DialogActions>
                                   </Dialog>
                               </div>

                               <div  className={classes.countryDiv}>
                                   <div onClick={this.handleClickOpen3} id="citySelect" >Your City or State</div>
                                   <IconButton onClick={this.handleClickOpen3} className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                       <ArrowDropDownIcon fontSize="small" />
                                   </IconButton>
                                   <Dialog disableBackdropClick disableEscapeKeyDown open={open3} onClose={this.handleClose3}>
                                       <DialogTitle>Choose Your City</DialogTitle>
                                       <DialogContent>
                                           <form className={classes.container}>
                                               <FormControl className={classes.formControl}>
                                                   <InputLabel id="demo-dialog-select-label3">City</InputLabel>
                                                   <Select
                                                       labelId="demo-dialog-select-label3"
                                                       id="demo-dialog-select2"
                                                       value={city}
                                                       onChange={this.handleChangeCity}
                                                       input={<Input />}
                                                   >
                                                       {
                                                           cities && cities.map((city , index) => {
                                                               return <MenuItem key={index} value={city.title}>{city.title}</MenuItem>
                                                           })
                                                       }
                                                   </Select>
                                               </FormControl>
                                           </form>
                                       </DialogContent>
                                       <DialogActions>
                                           <Button onClick={this.handleClose3} color="primary">
                                               Cancel
                                           </Button>
                                           <Button onClick={this.handleClose3} color="primary">
                                               Ok
                                           </Button>
                                       </DialogActions>
                                   </Dialog>
                               </div>
                           </div>

                           <div className={classes.sendBtnDiv}>
                               <Button onClick={ ()=> {this.handleGoToHomeBtn()}} className={classes.sendBtn} variant="contained">Save & Login</Button>
                           </div>
                       </div>

                    </div>

                </div>



                <div>
                    <Dialog fullScreen open={openPhoto} onClose={this.handleClosePhoto} TransitionComponent={Transition} >
                        <AppBar className={classes.appBarDialog}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={this.handleClosePhoto} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>

                        <div className={classes.coverUploadPhotoDialog}>
                            <div className="divFile">
                                <input type="file" accept="image/*" onChange={this.onSelectFile} />
                            </div>
                            <div className="App">

                                {src && (
                                    <ReactCrop
                                        src={src}
                                        crop={crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                )}
                                {croppedImageUrl && (
                                    <img alt="Crop" className="imgCropMee" src={croppedImageUrl} />
                                )}

                            </div>
                            {croppedImageUrl && (
                                <div>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        className={classes.buttonUpload}
                                        startIcon={<CloudUploadIcon fontSize={window.innerWidth>1400?"medium":"small"} />}
                                        onClick={()=> {this.handleClickAddPhotos()}}
                                    >
                                        Upload
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Dialog>
                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>

        );
    }
}

LoginStep2Container.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        loading : state.loadingProps.loading,
        apiToken : state.auth.apiToken,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{addPhotoUser,deletePhotoUser,completeAccountDataLogin,sendCode,sendPhoneNumber,changeLoadingStatus,resetLogin,changeSnackStatus})(LoginStep2Container));