import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import './../styles/css/main.css';
import Button from "@material-ui/core/es/Button/Button";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {countryLogin} from "../constants/ActionTypes";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {changeLoadingStatus, changeSnackStatus, completeAccountDataLogin, resetLogin, sendCode,sendPhoneNumber} from "../actions";
import 'react-image-crop/dist/ReactCrop.css';
import './../styles/css/App.css';
import {addPhotoUser, deletePhotoUser} from "../actions";
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';


const styles = theme => ( {
    mainDiv:{
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
        "position": "relative",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        color:"white",
        [theme.breakpoints.down('sm')]: {
            "display": "flex",
        },
    },
    mainDivWeb:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
        "position": "relative",
        color:"white",
        [theme.breakpoints.down('sm')]: {
            "display": "none",
        },
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "12px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "12px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "11px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "14px",
        },
    },
    mainCover:{
        "display": "flex",
        "width": "100%",
        "height": "100%",
        "left": "0",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    mainCoverWeb:{
        "display": "flex",
        "width": "100%",
        "height": "100%",
        "left": "0",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    secondMainCover:{
        "display": "flex",
        "left": "100%",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    secondCoverWeb:{
        "display": "flex",
        "left": "100%",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute"
    },
    thirdMainCover:{
        "display": "flex",
        "left": "100%",
        "width": "100%",
        "height": "100%",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexDirection": "column",
        "position": "absolute",
        "backgroundColor": "#222",
        "overflow": "scroll",
    },
    fourthMainCover:{
        "left": "100%",
        "top": "0px",
        "width": "100%",
        "height": "100%",
        "display": "flex",
        "position": "absolute",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "space-evenly",
        "backgroundColor": "#222"
    },fourthDivMainCoverA:{
        "height": "250%",
        "overflow": "scroll",
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start"
    },
    titleCompleteAccount: {
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "height": "20vh"
    },

    phoneNumberDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "marginTop": "15px",
        "width": "100%"
    },
    phoneNumberDiv1:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "height": "fit-content",
        [theme.breakpoints.up('md')]: {
            "height": "fit-content"
        },

    },
    phoneNumberDivX:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        height: "65vh",
        [theme.breakpoints.up('md')]: {
            height: "75vh",
        },

    },
    phoneNumberDiv2:{
        "display": "flex",
        "justifyContent": "space-evenly",
        "alignItems": "center",
        "flexWrap": "wrap"
    },
    phoneNumberDiv3:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
    },
    genderLabel:
        {
            "marginLeft": "-7px",
            "marginRight": "5px",
        }
    ,
    pIcon:
        {
            "backgroundColor": "#bdbdbd",
            "borderRadius": "7px 0 0 7px",
            "height": "29px",
            "border": "1px solid #707070",
            "borderRight": "none"
        }
    ,
    input:
        {
            "color": "white",
            "border": "1px solid #707070",
            "height": "35px",
            "borderRadius": "0 7px 7px 0",
            "backgroundColor": "#222",
            "width": "70%",
            "paddingLeft": "10px",
            [theme.breakpoints.up('md')]: {
                "height": "25px",
            },
        },
    sendBtn:
        {
            "backgroundColor": "#00EBFF",
            "borderRadius": "5px",
            "width": "140px",
            "height": "29px",
            "color": "#222",
            "fontSize": "12px",
        },
    sendBtnDiv:
        {
            "display": "flex",
            "justifyContent": "center",
            "alignItems": "center",
            "margin": "1vh"
        },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        "width": "100%"
    },
    selectBtn: {
        "height": "40px !important",
        "borderRadius": "7px 0 0 7px",
        "backgroundColor": "#716e6e",
        "minWidth": "50px !important",
        color:"white",
        [theme.breakpoints.up('md')]: {
            "height": "30px !important",
        },
    },
    phoneIcon: {
        "backgroundColor": "#716e6e",
        "borderRadius": "7px 0 0 7px",
        "height": "29px",
        "width": "46px",
        [theme.breakpoints.down('md')]: {
            "height": "41px",
        },
    },
    typeIconDiv: {
        "width": "35%",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "& :nth-child(2)":
            {
                "margin": "0 0px",
                "opacity": ".5"
            }
    },
    typeIcon: {
        "borderRadius": "7px 0 0 7px",
        "width": "35%"
    },
    avatarType: {
        "width": "60px",
        "height": "60px",
        "borderRadius": "0",
        "opacity": ".5",
        [theme.breakpoints.up('md')]: {
            "width": "40px",
            "height": "40px",
        },

    },
    message1: {
        "width": "74%",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "textAlign": "center",
        "& p":{
            "color": "#bbb3b3"
        },"& a":{
            "color": "#00ebff",
            "textDecoration": "none"
        },
        [theme.breakpoints.up('md')]: {
            "width": "100%",
        },
    },
    message2: {
        "width": "100%",
        "textAlign": "center",
        "& p":{
            "color": "#bbb3b3"
        },
        "& :nth-child(2)":{
            "color": "#00ebff",
            "marginTop": "-5px"
        },
        [theme.breakpoints.up('md')]: {
            "width": "100%",
        },
    },
    message3: {
        "width": "70%",
        "& p":{
            "fontSize": "12px",
            "color": "#bbb3b3",
            "textAlign": "center"
        }
    },
    message4: {
        "width": "90%",
        "textAlign": "center",
        "& p":{
            "color": "#e0e0e0",
            "margin": "-8px 0px 0px 0",
            "fontSize": "15px"
        }
    },
    divTitle: {
        "width": "70%",
        "marginTop": "6vh",
        "& h3":{
            "textAlign": "center"
        }
    },
    ageRange: {
        color: "#ff8630",
        width: "100%",
        margin: "-12px auto",
    },
    settingItemDiv:{
        width: "95%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        margin: "15px auto"
    },
    radioBtn: {
        color: "#ff8630 !important",
    },
    genderTitle: {
        "width": "100%"
    },
    countryDiv:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "border": "1px solid dimgray",
        "borderRadius": "4px",
        "height": "28px",
        "color": "#ff8630",
        "marginTop": "10px",
        "backgroundColor": "#101010",
        "& div":
            {
                "margin": "0 10px",
                "width": "80%",
            }
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },

    secondDivWeb:{
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-evenly",
        "flexDirection": "column",
        "height": "50vh",
        "width": "30%",
        "border": "1px solid lightgray",
        "borderRadius": "5px",
        "padding": "0px 50px",
        "backgroundColor": "#333",
    },
    downloadDivWeb:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "36%"
    },
    socialDivWeb:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "marginTop": "4%"

    },
    backBtnTopDiv:{
        "display": "flex",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
    },
    backBtnTopSelectTypeWebDiv:{
        "display": "flex",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
        [theme.breakpoints.down('sm')]: {
            "display": "none",
        },
    },
    backBtnTopSelectTypeDiv:{
        "display": "none",
        "position": "absolute",
        "top": "10px",
        "left": "10px",
        "alignItems": "center",
        [theme.breakpoints.down('sm')]: {
            "display": "flex",
        },
    },
    fontEn:{
        "fontFamily": "En",
        "& h2":{
            "fontFamily": "En",
        },
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imgCountry:{
        width:"20px",
        height:"20px",
        borderRadius:"5px",
        marginRight:"5px",
    },
    paperRoot:{
        "width": "40%",
        "maxWidth": "50%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "95%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "95%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('md')]: {
            "width": "95%",
            "maxWidth": "100%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "40%",
            "maxWidth": "50%",
        }
    },
    socialItemGoogleDivWeb:{
        "width": "48%",
        "border": "1px solid lightgray",
        "backgroundColor": "azure",
        "borderRadius": "10px",
    },
    socialItemAppleDivWeb:{
        "width": "48%",
        "border": "1px solid lightgray",
        "backgroundColor": "azure",
        "borderRadius": "10px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    secondDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%",
        "height": "fit-content",
    },
    secondUpDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondUpPhotoDivEditProfile:{
        "width": "150px",
        "height": "200px",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        "borderRadius": "5px",
        "boxShadow": "0 2px 6px 0 #000000c9",
        "position": "relative",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('md')]: {
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "13vw",
            "height": "18vw"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "13vw",
            "height": "18vw"
        }
    },
    secondUpPhotoIconBtnDivEditProfile:{
        "position": "absolute",
        "bottom": "0",
    },
    secondUpPhotoIconDivEditProfile:{
        "height": "35px",
        "width": "35px",
    },
    secondUpPhotoDeleteIconBtnDivEditProfile:{
        "position": "absolute",
        "bottom": "0",
        "right": "1px",
        "color": "#dc1c1c",
    },
    secondDownDivEditProfile:{
        "& h5":{
            "color": "#A7A7A7",
            "margin": "7px",
        },
    },
    appBarDialog: {
        position: 'relative',
        "backgroundColor": "#222222",
        "boxShadow": "0 0 0 0 #222222",
        "paddingTop": "11vh",
        "height": "40px",
        "flexDirection": "row",
        "justifyContent": "flex-end",
    },
    titleDialog: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    coverUploadPhotoDialog: {
        "width": "100%",
        "display": "flex",
        "minHeight": "100%",
        "alignItems": "center",
        "paddingTop": "8vh",
        "paddingBottom": "20%",
        "justifyContent": "start",
        "backgroundColor": "#222222",
        "flexDirection": "column"

    },
    buttonUpload: {
        "fontFamily": "En",
        "margin": "25% 0",
    },
    coverGetPhone:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column"
    },
    loginLogo:{
        maxWidth: "70vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            maxWidth: "30vw",
        }
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    currencyItem1DivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "43%",
        "border": "1px solid white",
        "height": "32px",
        "borderRadius": "5px",
        "backgroundColor": "white",
        "color": "#222222",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "32px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "40px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "33px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "38px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
        }
    },
    currencyItem2DivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "43%",
        "border": "1px solid #222222",
        "height": "32px",
        "borderRadius": "5px",
        "backgroundColor": "#222222",
        "color": "white",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "32px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "40px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "33px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "38px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
        }
    },
    currencyCoverDivEditRate:{
        "display": "flex",
        "justifyContent": "space-around",
        "alignItems": "center",
        "width": "70%",
        "border": "1px solid lightgray",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "padding": "2%",
        "boxShadow": "0 1px 3px 1px black"
    },
    currencyDivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    ll:{},
});


class LoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            phoneNumber:null,
            email:null,
            code:"+1",
            verificationCode:null,
            codeId:null,
            open:false,
            loginType:'phone_number',
        };
        this.onCountryCodeChange = this.onCountryCodeChange.bind(this);
    }

    componentDidUpdate(prevProps){

        if(prevProps.auth.step !== this.props.auth.step){

            if (this.props.auth.step===1 && prevProps.auth.step===11){
                if(window.innerWidth<755){
                    this.props.resetLogin(11)
                }else{
                    this.props.resetLogin(1)
                }
            }
            if (this.props.auth.step===2 && prevProps.auth.step===1){
                this.handleNextStepWebClick("sendPhoneNumberWeb","sendCodeWeb")
            }
            if (this.props.auth.step===1 && prevProps.auth.step===2){
                this.handlePrevStepClick("sendCodeWeb","sendPhoneNumberWeb")
            }
            if (this.props.auth.step===22 && prevProps.auth.step===11){
                this.handleNextStepClick("getPhoneNumber","sendCode")
            }
            if (this.props.auth.step===11 && prevProps.auth.step===22){
                this.handlePrevStepClick("sendCode","getPhoneNumber")
            }
        }
    }

    componentDidMount(){
        let appBar=document.getElementById("appBarCover")
        let BottomNavigation=document.getElementById("BottomNavigation")
        let container=document.getElementById("container")
        appBar.style.display="none";
        BottomNavigation.style.display="none";
        container.style.paddingTop="0";
        if(window.innerWidth<755){
            this.props.resetLogin(11)
        }else{
            this.props.resetLogin(1)
        }

    }

    componentWillUnmount(){
        let appBar=document.getElementById("appBarCover")
        let BottomNavigation=document.getElementById("BottomNavigation")
        let container=document.getElementById("container")
        appBar.style.display="flex";
        BottomNavigation.style.display="flex";
        container.style.paddingTop="8vh";
    }

    onCountryCodeChange = (event, value) => {
        this.setState({
            code: "+" + value.callingCodes[0]
        })
    }

    handleChangeInputs = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        })
    };

    handleClickOpen = () => {
        this.setState({
            open:true
        })
    };

    handleClose = () => {
        this.setState({
            open:false
        })
    };

    handleOkDialog = () => {
        this.setState({
            open:false
        })
    };

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleResendCodeMobile()  {
        this.props.resetLogin(11)
        this.setState({
            verificationCode:null
        })
    };

    handleNextStepClick(prevId,nextId)  {

        let prevAnimationClass ="go-away-left-login";
        let nextAnimationClass ="go-from-right-login";
        let prevEle= document.getElementById(`${prevId}`);
        let nextEle= document.getElementById(`${nextId}`);
        prevEle.classList.add(prevAnimationClass)
        nextEle.classList.add(nextAnimationClass)
        prevEle.classList.remove("go-back-left-login")
        nextEle.classList.remove("go-back-right-login")
        if (nextId==="selectType"){
            let mainDiv= document.getElementById(`mainDiv`);
            let mainDivWeb= document.getElementById(`mainDivWeb`);
            let sendCode= document.getElementById(`sendCode`);
            let getPhoneNumber= document.getElementById(`getPhoneNumber`);
            let sendCodeWeb= document.getElementById(`sendCodeWeb`);
            let sendPhoneNumberWeb= document.getElementById(`sendPhoneNumberWeb`);
            mainDiv.style.backgroundImage="none"
            mainDiv.style.backgroundColor="#222"
            mainDivWeb.style.display="none"
            sendCode.style.display="none"
            getPhoneNumber.style.display="none"
            sendPhoneNumberWeb.style.display="none"
            sendCodeWeb.style.display="none"
        }
    };

    handlePrevStepClick(prevId,nextId)  {



        if(nextId==="sendCodeWeb") {
            let prevEleParent= document.getElementById(`mainDivWeb`);
            let nextEleParent= document.getElementById(`mainDiv`);
            nextEleParent.style.backgroundImage=""
            nextEleParent.style.backgroundColor="#222"
            prevEleParent.style.display="flex"
            nextEleParent.style.display="none"
        }

        let prevAnimationClass ="go-back-right-login";
        let nextAnimationClass ="go-back-left-login";
        let prevEle= document.getElementById(`${prevId}`);
        let nextEle= document.getElementById(`${nextId}`);
        prevEle.classList.add(prevAnimationClass)
        nextEle.classList.add(nextAnimationClass)
        prevEle.classList.remove("go-from-right-login")
        nextEle.classList.remove("go-away-left-login")
    };

    handleNextStepWebClick(prevId,nextId)  {



        let prevAnimationClass ="go-away-left-login";
        let nextAnimationClass ="go-from-right-login";
        let prevEle= document.getElementById(`${prevId}`);
        let nextEle= document.getElementById(`${nextId}`);
        prevEle.classList.add(prevAnimationClass)
        nextEle.classList.add(nextAnimationClass)
        prevEle.classList.remove("go-back-left-login")
        nextEle.classList.remove("go-back-right-login")
        if (nextId==="selectType"){
            let prevEleParent= document.getElementById(`mainDivWeb`);
            let nextEleParent= document.getElementById(`mainDiv`);
            nextEleParent.style.backgroundImage=""
            nextEleParent.style.backgroundColor="#222"
            prevEleParent.style.display="none"
            nextEleParent.style.display="flex"
            let mainDiv= document.getElementById(`mainDiv`);
            let mainDivWeb= document.getElementById(`mainDivWeb`);
            let sendCode= document.getElementById(`sendCode`);
            let getPhoneNumber= document.getElementById(`getPhoneNumber`);
            let sendCodeWeb= document.getElementById(`sendCodeWeb`);
            let sendPhoneNumberWeb= document.getElementById(`sendPhoneNumberWeb`);
            mainDiv.style.backgroundImage="none"
            mainDiv.style.backgroundColor="#222"
            mainDivWeb.style.display="none"
            sendCode.style.display="none"
            getPhoneNumber.style.display="none"
            sendPhoneNumberWeb.style.display="none"
            sendCodeWeb.style.display="none"
        }


    };

    handleSendPhoneNumber(type)  {
        let userPhoneNumber=this.state.phoneNumber
        let userEmail=this.state.email
        let loginType=this.state.loginType
        let code=this.state.code
        if(userPhoneNumber!==null && userPhoneNumber.length===10 && loginType==="phone_number"){
            this.props.changeLoadingStatus(true)
            let step=type==="Mobile"?22:2;
            this.setState({
                verificationCode:null
            })
            this.props.sendPhoneNumber(code+userPhoneNumber,userEmail,loginType,step)
        }else if(userEmail!==null && loginType==="email"){
            this.props.changeLoadingStatus(true)
            let step=type==="Mobile"?22:2;
            this.setState({
                verificationCode:null
            })
            this.props.sendPhoneNumber(code+userPhoneNumber,userEmail,loginType,step)
        }else if(userPhoneNumber!==null && loginType!=="phone_number" && (userPhoneNumber.length>10 || userPhoneNumber.length<10)){
            this.props.changeSnackStatus(true,"warning",`please enter your phone number correctly.`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }else if(userPhoneNumber===null && loginType!=="phone_number"){
            this.props.changeSnackStatus(true,"warning",`please enter your phone number.`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }else if(userEmail===null && loginType!=="email"){
            this.props.changeSnackStatus(true,"warning",`please enter your email.`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }
    };

    handleSendCode(type)  {
        let userPhoneNumber=this.props.auth.phone_number
        let userEmail=this.state.email
        let loginType=this.state.loginType
        let codeId=this.props.auth.codeId
        let verificationCode=this.state.verificationCode
        if(verificationCode!==null && verificationCode.length===4){
            this.props.changeLoadingStatus(true)
            this.props.sendCode(userPhoneNumber,codeId,userEmail,loginType,verificationCode,this.props.history)
        }else if(verificationCode!==null && (verificationCode.length>4 || verificationCode.length<4)){
            this.props.changeSnackStatus(true,"warning",`please enter verification code correctly.`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }else if(verificationCode===null){
            this.props.changeSnackStatus(true,"warning",`please enter verification code.`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }
    };

    handleResendCodeWeb()  {
        this.props.resetLogin(1)
        this.setState({
            verificationCode:null
        })
    };

    handleClickLoginType = (idCur,idNext) => {
        let eleA= document.getElementById(`loginType${idCur}`);
        let eleB= document.getElementById(`loginType${idNext}`);
        eleA.style.backgroundColor= "white"
        eleA.style.color= "#222222"
        eleA.style.borderColor= "white"
        eleB.style.backgroundColor= "#222222"

        eleB.style.color= "white"
        eleB.style.borderColor= "#222222"

        let emailLoginType= document.getElementById(`emailLoginType`);
        let mobileLoginType= document.getElementById(`mobileLoginType`);
        if(idNext===2){
            mobileLoginType.style.display= "flex"
            emailLoginType.style.display= "none"
        }
        if(idNext===1){

            emailLoginType.style.display= "flex"
            mobileLoginType.style.display= "none"
        }


        this.setState({
            loginType:idNext===2?"phone_number":"email"
        })
    }

    handleClickLoginTypeWeb = (idCur,idNext) => {
        let eleA= document.getElementById(`loginTypeWeb${idCur}`);
        let eleB= document.getElementById(`loginTypeWeb${idNext}`);
        eleA.style.backgroundColor= "white"
        eleA.style.color= "#222222"
        eleA.style.borderColor= "white"
        eleB.style.backgroundColor= "#222222"

        eleB.style.color= "white"
        eleB.style.borderColor= "#222222"

        let emailLoginType= document.getElementById(`emailLoginTypeWeb`);
        let mobileLoginType= document.getElementById(`mobileLoginTypeWeb`);
        if(idNext===2){
            mobileLoginType.style.display= "flex"
            emailLoginType.style.display= "none"
        }
        if(idNext===1){
            emailLoginType.style.display= "flex"
            mobileLoginType.style.display= "none"
        }


        this.setState({
            loginType:idNext===2?"phone_number":"email"
        })
    }


    handleDownloadApp = (url,type) => {
        if(url && type==="appStore"){
            window.open(url,"_blank").focus();
        }
        if(url && type==="googlePlay"){
            window.open(url,"_blank").focus();
        }
    };


    render() {
        const {loading,classes} = this.props;
        const {code,open,phoneNumber,loginType,email} = this.state;

        return (
            <div>
                <div id="mainDiv" className={classes.mainDiv} style={{backgroundImage:"url('/images/login.png')" }}>
                {/*<div id="mainDiv" className={classes.mainDiv} style={{backgroundColor:"#333" }}>*/}

                    <div id="getPhoneNumber" className={classes.mainCover}>
                        <div>
                            <img className={classes.loginLogo} src="/images/logo.png" alt=""/>
                        </div>
                        <div className={classes.currencyDivEditRate}>
                            <div className={classes.currencyCoverDivEditRate}>
                                <div id="loginType1" onClick={ ()=> {this.handleClickLoginType(1,2)}} className={classes.currencyItem1DivEditRate}><h6>Login By Mobile</h6></div>
                                <div id="loginType2" onClick={ ()=> {this.handleClickLoginType(2,1)}} className={classes.currencyItem2DivEditRate}><h6>Login By Email</h6></div>
                            </div>
                        </div>
                        <div id="mobileLoginType" className={classes.coverGetPhone}>
                            <div className={classes.message1}>
                                <p>Please confirm your country code and enter your phone number.</p>
                            </div>
                            <div className={classes.phoneNumberDiv}>
                                <Button classes={{root: classes.selectBtn}} onClick={this.handleClickOpen}>{code}</Button>
                                <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose}>
                                    <DialogTitle>Your country code</DialogTitle>
                                    <DialogContent>
                                        <Autocomplete
                                            id="country-select-demo"
                                            style={{ width: "100%" }}
                                            options={countryLogin}
                                            classes={{
                                                option: classes.option,
                                            }}
                                            onChange={this.onCountryCodeChange}
                                            autoHighlight
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    <img className={classes.imgCountry} src={option.flag} />
                                                    {option.name} ({option.alpha3Code}) + {option.callingCodes}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label=""
                                                    variant="outlined"
                                                    style={{ width: "100%" }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={this.handleClose} color="primary">
                                            Ok
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <input onChange={this.handleChangeInputs} name="phoneNumber" className={classes.input}  type="text" placeholder="Phone number" />
                            </div>
                            <div className={classes.message1}>
                                <p>By continue you accept terms and conditions</p>
                            </div>

                            <div className={classes.message1}>
                                <a href="http://bloomsho.com/Terms.html" target="_blank">See terms and condition</a>
                            </div>
                        </div>
                        <div id="emailLoginType" className={classes.coverGetPhone} style={{display:"none"}}>
                            <div className={classes.message1}>
                                <p>Please enter your email.</p>
                            </div>
                            <div className={classes.phoneNumberDiv}>
                                <IconButton classes={{root: classes.selectBtn}} aria-haspopup="true"  color="inherit">
                                    <MailIcon fontSize="small" />
                                </IconButton>
                                <input onChange={this.handleChangeInputs} name="email" className={classes.input}  type="text" placeholder="Email" />
                            </div>
                            <div className={classes.message1}>
                                <p>By continue you accept terms and conditions</p>
                            </div>

                            <div className={classes.message1}>
                                <a href="http://bloomsho.com/Terms.html" target="_blank">See terms and condition</a>
                            </div>
                        </div>
                        <div className={classes.sendBtnDiv}>
                            <Button onClick={ ()=> {this.handleSendPhoneNumber("Mobile")}} className={classes.sendBtn} variant="contained">Continue</Button>
                        </div>
                    </div>

                    <div id="sendCode" className={classes.secondMainCover}>

                        <div>
                            <img className={classes.loginLogo} src="/images/logo.png" alt=""/>
                        </div>
                        <div className={classes.phoneNumberDiv1}>
                            <div className={classes.message1}>
                                <p>please type the verification code sent to: {loginType==="phone_number"?code+phoneNumber:email}</p>
                            </div>
                            <div  className={classes.phoneNumberDiv}>
                                <IconButton classes={{root: classes.phoneIcon}} aria-haspopup="true"  color="inherit">
                                    <LockIcon fontSize="small" />
                                </IconButton>
                                <input onChange={this.handleChangeInputs} className={classes.input} name="verificationCode"  type="text" placeholder="Enter code" />
                            </div>
                            <div className={classes.message2}>
                                <p>check your message and enter the code.</p>
                                <p onClick={ ()=> {this.handleResendCodeMobile()}}>resend code?</p>
                            </div>
                        </div>
                        <div className={classes.sendBtnDiv}>
                            <Button onClick={ ()=> {this.handleSendCode("Mobile")}} className={classes.sendBtn} variant="contained">Continue</Button>
                        </div>
                    </div>

                </div>


                <div id="mainDivWeb" className={classes.mainDivWeb}>

                    <div className={classes.mainCoverWeb} id="sendPhoneNumberWeb">
                        <div>
                            <img className={classes.loginLogo} src="/images/logo.png" alt=""/>
                        </div>
                        <div className={classes.secondDivWeb}>
                            <div style={{width:"100%"}}>
                                <div className={classes.currencyDivEditRate}>
                                    <div className={classes.currencyCoverDivEditRate}>
                                        <div id="loginTypeWeb1" onClick={ ()=> {this.handleClickLoginTypeWeb(1,2)}} className={classes.currencyItem1DivEditRate}><h5>Login By Mobile</h5></div>
                                        <div id="loginTypeWeb2" onClick={ ()=> {this.handleClickLoginTypeWeb(2,1)}} className={classes.currencyItem2DivEditRate}><h5>Login By Email</h5></div>
                                    </div>
                                </div>
                                <div className={classes.message1}>
                                    <p>{loginType==="phone_number"?"Please confirm your country code and enter your phone number.":"Please enter your email."}</p>
                                </div>
                                <div id="mobileLoginTypeWeb" className={classes.phoneNumberDiv}>
                                    <Button classes={{root: classes.selectBtn}} onClick={this.handleClickOpen}>{code}</Button>
                                    <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose}>
                                        <DialogTitle>Your country code</DialogTitle>
                                        <DialogContent>

                                            <Autocomplete
                                                id="country-select-demo"
                                                style={{ width: "100%" }}
                                                options={countryLogin}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                onChange={this.onCountryCodeChange}
                                                autoHighlight
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <img className={classes.imgCountry} src={option.flag} />
                                                        {option.name} ({option.alpha3Code}) + {option.callingCodes}
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label=""
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            />

                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={this.handleOkDialog} color="primary">
                                                Ok
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <input onChange={this.handleChangeInputs} name="phoneNumber" className={classes.input}  type="text" placeholder="Phone number" />
                                </div>
                                <div id="emailLoginTypeWeb" className={classes.phoneNumberDiv} style={{display:"none"}} >
                                    <IconButton classes={{root: classes.selectBtn}} aria-haspopup="true"  color="inherit">
                                        <MailIcon fontSize="small" />
                                    </IconButton>
                                    <input onChange={this.handleChangeInputs} name="email" className={classes.input}  type="text" placeholder="Email" />
                                </div>
                                <div className={classes.message1}>
                                    <p>By continue you accept terms and conditions</p>
                                </div>

                                <div className={classes.message1}>
                                    <a href="http://bloomsho.com/Terms.html" target="_blank">See terms and condition</a>
                                </div>
                            </div>
                            <div>
                                <div className={classes.sendBtnDiv}>
                                    <Button onClick={ ()=> {this.handleSendPhoneNumber("Desktop")}} className={classes.sendBtn} variant="contained">Continue</Button>
                                </div>
                            </div>
                        </div>
                        <div className={classes.downloadDivWeb}>
                            {/*<div className={classes.message4}>
                                <p>Get the App</p>
                            </div>*/}
                            <div className={classes.socialDivWeb}>
                                <img onClick={ ()=> {this.handleDownloadApp("https://apps.apple.com/tt/app/bloomsho/id1526863885?ign-mpt=uo%3D2","appStore")}} className={classes.socialItemAppleDivWeb} src="/images/appStore.png" alt="appStore"/>
                                <img onClick={ ()=> {this.handleDownloadApp("https://play.google.com/store/apps/details?id=com.bloomsho&hl=en_US","googlePlay")}} className={classes.socialItemGoogleDivWeb} src="/images/googlePlay.png" alt="googlePlay"/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.secondCoverWeb} id="sendCodeWeb">

                        <div>
                            <img className={classes.loginLogo} src="/images/logo.png" alt=""/>
                        </div>
                        <div className={classes.secondDivWeb}>
                            <div>
                                <div className={classes.message1}>
                                    <p>please type the verification code sent to: {loginType==="phone_number"?code+phoneNumber:email}</p>
                                </div>
                                <div  className={classes.phoneNumberDiv}>
                                    <IconButton classes={{root: classes.phoneIcon}} aria-haspopup="true"  color="inherit">
                                        <LockIcon fontSize="small" />
                                    </IconButton>
                                    <input onChange={this.handleChangeInputs} name="verificationCode" className={classes.input}  type="text" placeholder="Enter code" />
                                </div>
                                <div className={classes.message2}>
                                    <p>check your message and enter the code.</p>
                                    <p onClick={ ()=> {this.handleResendCodeWeb()}}>resend code?</p>
                                </div>
                            </div>
                            <div>
                                <div className={classes.sendBtnDiv}>
                                    <Button onClick={ ()=> {this.handleSendCode("Desktop")}} className={classes.sendBtn} variant="contained">Continue</Button>
                                </div>
                            </div>
                        </div>
                        <div className={classes.downloadDivWeb}>
                            {/*<div className={classes.message4}>
                                <p>Get the App</p>
                            </div>*/}
                            <div className={classes.socialDivWeb}>
                                <img onClick={ ()=> {this.handleDownloadApp("https://apps.apple.com/tt/app/bloomsho/id1526863885?ign-mpt=uo%3D2","appStore")}} className={classes.socialItemAppleDivWeb} src="/images/appStore.png" alt="appStore"/>
                                <img onClick={ ()=> {this.handleDownloadApp("https://play.google.com/store/apps/details?id=com.bloomsho&hl=en_US","googlePlay")}} className={classes.socialItemGoogleDivWeb} src="/images/googlePlay.png" alt="googlePlay"/>
                            </div>
                        </div>
                    </div>

                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>

        );
    }
}

LoginContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        loading : state.loadingProps.loading,
        apiToken : state.auth.apiToken,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{addPhotoUser,deletePhotoUser,completeAccountDataLogin,sendCode,sendPhoneNumber,changeLoadingStatus,resetLogin,changeSnackStatus})(LoginContainer));