import React, { Component  } from 'react';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles/index";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import {baseUrl} from "../constants/constant";
import {
    applyCast, bookmarkCast, changeBottomNavStatus, changeLoadingStatus, getCastDetail, initSelectedCast,
    initTempToCasts
} from "../actions";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import BookmarkBorderRoundedIcon from '@material-ui/icons/BookmarkBorderRounded';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Button from "@material-ui/core/es/Button/Button";

const styles = (theme) => ({
    mainDivCover:{
        "width": "46%",
        "display": "flex",
        "marginTop": "10px",
        "height": "254px",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        "backgroundColor": "#222222",
        "borderRadius": "5px",
        "paddingBottom": "20%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "95%",
            "height": "100%",
            "fontSize": "11px"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "95%",
            "height": "100%",
            "fontSize": "12px"
        },[theme.breakpoints.up('md')]: {
            "width": "95%",
            "height": "100%",
            "fontSize": "12px"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "48%",
            "height": "100%",
            "fontSize": "12px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "48%",
            "height": "100%",
            "fontSize": "12px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "48%",
            "height": "100%",
            "fontSize": "18px",
        },
    },
    containerDetailCast:{
        "flexDirection": "column",
        "width": "100%",
        "display": "flex",
        "minHeight": "92vh",
        "alignItems": "center",
        "paddingBottom": "12%",
        "justifyContent": "flex-start"
    },
    firstDiv:{
        "width": "100%",
        "height": "25%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "flexDirection": "column"
        ,[theme.breakpoints.up('md')]: {
            "width": "89%",
            "paddingLeft": "5%",
        }
    },
    secondDiv:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "height": "25%",
    },
    secondDivPayment:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "82%",
        "height": "25%",
    },
    thirdDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "height": "30%",
        "& div":{
            "width": "85%",
            "textAlign": "justify",
            "color": "#A7A7A7"
        }
    },
    fourthDiv:{
        "width": "80%",
        "height": "20%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between",
        "paddingLeft": "10%",
        "marginTop": "10px",
    },
    secondCastDetailDiv:{
    "display": "flex",
    "width": "50%",
    "justifyContent": "space-around",
    },
    secondCastDetailDivPayment:{
    "display": "flex",
    "width": "50%",
    "justifyContent": "space-between",
    },
    colorOrange:{
        "color": "#ff8630",
    },
    titleCardDivShow:{
        "display": "flex",
        "justifyContent": "center",
        "width": "100%"
    },
    titleCard:{
        "display": "flex",
    },
    titleCard2:{
        "display": "flex",
        "justifyContent": "space-between",
        "width": "100%",
        "marginTop": "-28px"
    },
    socialIconsProfile:{
        "display": "flex",
        "width": "75%",
        "justifyContent": "start",
        "flexWrap": "wrap",
    },
    bookmarkIconsProfile:{
        "display": "flex",
        "width": "15%",
        "justifyContent": "center"
    },
    applyBtnDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "& h4":{
            "marginBottom": "0"
        },
    },
    dividerCast:{
        "borderBottom": "1px solid #777",
        "width": "92%"
    },
    paymentDetailDivCast:{
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    applyBtn:{
        "color": "#222222",
        "width": "100%",
        "height": "30px",
        "textAlign": "center",
        "borderRadius": "28px",
        "backgroundColor": "aqua",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "& p":{
            "margin": "0"
        },
    },
    applyDisableBtn:{
        "color": "#f4f4f4",
        "width": "100%",
        "height": "30px",
        "textAlign": "center",
        "borderRadius": "28px",
        "backgroundColor": "#999",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "& p":{
            "margin": "0"
        },
    },
    detailRightCard:{
        "display": "flex",
        "width": "40%",
        "fontSize": "10px",
        "justifyContent": "center",
        "alignItems": "center",
    },
    applyIcon:{
        "width": "100%"
        ,[theme.breakpoints.down('md')]: {
            "fontSize": "13px"
        },
        [theme.breakpoints.up('md')]: {//Tab
            "fontSize": "16px",
        },
    },
    avatarShowCast:{
        [theme.breakpoints.down('md')]: {
            "width": "13vh",
            "height": "13vh",
        },
        [theme.breakpoints.up('md')]: {//Tab
            "width": "13vh",
            "height": "13vh",
        },[theme.breakpoints.up('lg')]: {//Tab
            "width": "13vh",
            "height": "13vh",
        },[theme.breakpoints.up('xl')]: {//Tab
            "width": "13vh",
            "height": "13vh",
        },[theme.breakpoints.up('xxl')]: {//Tab
            "width": "18vh",
            "height": "18vh",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    socialDivCover:{
        "display": "flex",
        "justifyContent": "center",
        "width": "100%",
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "90%",
        },

    },
    socialIconsColorProfile:{
        "color": "#fffbf0",
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "5vh",
            "width": "5vh",
            "margin": "1vw 0 0 0",
        },
    },
    contactInfoCover:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column"
    },
    contactInfo:{
        "display": "flex",
        "width": "90%",
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    skypeIcon:{
        "width": "5vw",
        [theme.breakpoints.up('md')]: {
            "width": "4vh",
        }
    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    goToUserProfileDiv:{
        "display": "flex",
        "border": "1px solid #777",
        "borderRadius": "5px",
        "paddingLeft": "20px",
        "backgroundColor": "#303030"
    },
    searchButton: {
        "backgroundColor": "#00ebff",
        "color": "#222",
        "fontFamily": "En",
        "width": "100%",
    },
});


class ShowCastContainer extends Component {

    constructor(props){
        super(props);
        this.state ={

        }
    }

    componentWillMount(){
        this.props.changeBottomNavStatus(null,7)
        this.props.changeLoadingStatus(true)
        setTimeout(
            function() {
                this.props.getCastDetail(this.props.match.params.id,this.props.casts)
            }
                .bind(this),
            1000
        );
    }

    componentWillUnmount(){
        this.props.initSelectedCast()
    }

    componentDidMount(){

        /*setTimeout(
            function() {
                this.props.initTempToCasts(this.props.tempCasts.data,this.props.tempCasts.nextPage,this.props.tempCasts.hasMore)
            }
                .bind(this),
            500
        );*/

    }

    handleClickGoToSocial = (username,type) => {
        if(username && type==="instagram"){
            window.open(`https://www.instagram.com/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="telegram"){
            window.open(`https://t.me/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="facebook"){
            window.open(`https://m.facebook.com/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="linkedin"){
            window.open(username,"_blank").focus();
        }
        if(username && type==="phone"){
            window.open(`tel:${username}`,"_blank").focus();
        }
        if(username && type==="email"){
            window.open(`mailto: ${username}`,"_blank").focus();
        }
        if(username && type==="youtube"){
            window.open(`https://www.youtube.com/user/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="skype"){
            window.open(`skype:${username}?userinfo`,"_blank").focus();
        }
    };

    handleClickApply = (id) => {
        this.props.changeLoadingStatus(true)
        this.props.applyCast(id,this.props.auth.apiToken)
    };

    handleClickAddToBookmarks = (id) => {
        this.props.changeLoadingStatus(true)
        let bookmarks= this.props.auth.user.castFavoritesId;
        bookmarks.push(id.toString())
        this.props.bookmarkCast(this.removeDuplicates(bookmarks),this.props.auth.apiToken)


    };

    handleClickMinesToBookmarks = (id) => {

        this.props.changeLoadingStatus(true)
        let bookmarks=[]
        this.props.auth.user.castFavoritesId.map( (favoriteId, index)=>{
            if(favoriteId!==id.toString()){
                bookmarks.push(favoriteId)
            }
        } )
        this.props.bookmarkCast(this.removeDuplicates(bookmarks),this.props.auth.apiToken)
    };

    removeDuplicates(array){
        let uniques = [];
       array.map( (el)=> {
           if(uniques.includes(el) === false) uniques.push(el);
       } )
        return uniques;
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleClickGoToOwner(id){
        this.props.history.push(`/user/${id}/profile`)
    }

    render() {

        const {classes,cast,loading,auth}=this.props;

        return (

            <div className={classes.containerDetailCast}>

                {
                    cast && cast.user &&
                    <div className={classes.mainDivCover}>

                        <div className={classes.firstDiv}>
                            <div>
                                <IconButton aria-haspopup="true"  color="inherit">
                                    <Avatar classes={{root:classes.avatarShowCast}} alt="Profile photo" src={cast.user?baseUrl+cast.user.main_photo:"/images/no-user.png"} />
                                </IconButton>
                            </div>
                            <div className={classes.titleCardDivShow} >
                                <div className={classes.titleCard} >
                                    <h4> {cast.title}</h4>
                                </div>
                            </div>
                            <div  onClick={()=> {this.handleClickGoToOwner(cast.user.id)}} className={classes.goToUserProfileDiv} >
                                <h4>See owner profile</h4>
                                <IconButton aria-haspopup="true"  color="inherit">
                                    <ArrowForwardRoundedIcon style={{"color": "white"}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                </IconButton>
                            </div>
                        </div>
                        <div className={classes.secondDiv}>
                            <div className={classes.secondCastDetailDiv}>
                                <div className={classes.detailLeftCard}>
                                    <div className={classes.detailCardP} >
                                        <p>Type : </p>
                                        <p>Age range : </p>
                                        <p>Start : </p>
                                    </div>
                                </div>
                                <div className={classes.detailCenterCard}>
                                    <div className={classes.detailCardP} >
                                        <p  className={classes.colorOrange} >{cast.group?cast.group.title:""}</p>
                                        <p  className={classes.colorOrange} >{cast.age?cast.age.replace(",","-"):""}</p>
                                        <p  className={classes.colorOrange} >{cast.created_at}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.secondCastDetailDiv}>
                                <div className={classes.detailLeftCard}>
                                    <div className={classes.detailCardP} >
                                        <p> Gender: </p>
                                        <p>Location: </p>
                                        <p>Expire : </p>
                                    </div>
                                </div>
                                <div className={classes.detailCenterCard}>
                                    <div className={classes.detailCardP} >
                                        <p  className={classes.colorOrange} >{cast.gender}</p>
                                        <p  className={classes.colorOrange} >{cast.city?cast.city.title:""}</p>
                                        <p  className={classes.colorOrange} >{cast.expire_date>0?cast.expire_date+" Days":"Expired"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.applyBtnDiv}>
                            <div className={classes.dividerCast}></div>
                        </div>
                        <div className={classes.applyBtnDiv}>
                            <h4>Description</h4>
                        </div>
                        <div className={classes.thirdDiv}>
                            <div>
                                <p>
                                    {cast.description}
                                </p>
                            </div>
                        </div>
                        <div className={classes.applyBtnDiv}>
                            <div className={classes.dividerCast}></div>
                        </div>
                        <div className={classes.applyBtnDiv}>
                            <h4>Payment Detail</h4>
                        </div>

                        <div className={classes.paymentDetailDivCast}>

                            {
                                cast.hourly_rate && cast.hourly_rate.to!=0 &&
                                <div className={classes.secondDivPayment}>
                                    <div className={classes.secondCastDetailDivPayment}>
                                        <div className={classes.detailLeftCard}>
                                            <div className={classes.detailCardP} >
                                                <p>Hourly Rate : </p>
                                            </div>
                                        </div>
                                        <div className={classes.detailCenterCard}>
                                            <div className={classes.detailCardP} >
                                                <p  className={classes.colorOrange} >{cast.hourly_rate + " " + cast.currency}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                cast.daily_rate && cast.daily_rate.to!=0 &&
                                <div className={classes.secondDivPayment} style={{"marginTop": "-16px"}}>
                                    <div className={classes.secondCastDetailDivPayment}>
                                        <div className={classes.detailLeftCard}>
                                            <div className={classes.detailCardP} >
                                                <p>Daily Rate : </p>
                                            </div>
                                        </div>
                                        <div className={classes.detailCenterCard}>
                                            <div className={classes.detailCardP} >
                                                <p  className={classes.colorOrange} >{cast.daily_rate + " " + cast.currency}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                cast.monthly_rate && cast.monthly_rate.to!=0 &&
                                <div className={classes.secondDivPayment} style={{"marginTop": "-16px"}}>
                                    <div className={classes.secondCastDetailDivPayment}>
                                        <div className={classes.detailLeftCard}>
                                            <div className={classes.detailCardP} >
                                                <p>Monthly Rate : </p>
                                            </div>
                                        </div>
                                        <div className={classes.detailCenterCard}>
                                            <div className={classes.detailCardP} >
                                                <p  className={classes.colorOrange} >{cast.monthly_rate+ " " + cast.currency}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                cast.yearly_rate && cast.yearly_rate.to!=0 &&
                                <div className={classes.secondDivPayment} style={{"marginTop": "-16px"}}>
                                    <div className={classes.secondCastDetailDivPayment}>
                                        <div className={classes.detailLeftCard}>
                                            <div className={classes.detailCardP} >
                                                <p>Yearly Rate: </p>
                                            </div>
                                        </div>
                                        <div className={classes.detailCenterCard}>
                                            <div className={classes.detailCardP} >
                                                <p  className={classes.colorOrange} >{cast.yearly_rate+ " " + cast.currency}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>

                        <div className={classes.thirdDiv}>
                            <div>
                                <p>
                                    {cast.payment}
                                </p>
                            </div>
                        </div>

                        <div className={classes.applyBtnDiv}>
                            <div className={classes.dividerCast}></div>
                        </div>
                        <div className={classes.applyBtnDiv}>
                            <h4>Contact info</h4>
                        </div>

                        <div className={classes.contactInfoCover}>

                            <div className={classes.contactInfo}>
                                <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                    <PhoneIcon style={{"color": "#00EBFF"}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                </IconButton>
                                <h3>{cast.user.phone_number}</h3>
                            </div>
                            <div className={classes.contactInfo}>
                                <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                    <PhoneInTalkIcon style={{"color": "#00EBFF"}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                </IconButton>
                                <h3>{cast.optional_phone_number}</h3>
                            </div>

                            <div className={classes.contactInfo}>
                                <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                    <EmailRoundedIcon style={{"color": "#00EBFF"}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                </IconButton>
                                <h3>{cast.email}</h3>
                            </div>

                        </div>

                        <div className={classes.socialDivCover}>

                            <div className={classes.socialIconsProfile}>

                                {
                                    cast.user.facebook &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <FacebookIcon onClick={()=> {this.handleClickGoToSocial(cast.user.facebook,"facebook")}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                        </IconButton>
                                    </div>
                                }

                                {
                                    cast.user.instagram &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <InstagramIcon onClick={()=> {this.handleClickGoToSocial(cast.user.instagram,"instagram")}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                        </IconButton>
                                    </div>
                                }

                                {
                                    cast.user.linkedin &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <LinkedInIcon onClick={()=> {this.handleClickGoToSocial(cast.user.linkedin,"linkedin")}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                        </IconButton>
                                    </div>
                                }

                                {
                                    cast.user.telegram &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <TelegramIcon onClick={()=> {this.handleClickGoToSocial(cast.user.telegram,"telegram")}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                        </IconButton>
                                    </div>
                                }

                                {
                                    cast.user.youtube &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <YouTubeIcon onClick={()=> {this.handleClickGoToSocial(cast.user.youtube,"youtube")}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                        </IconButton>
                                    </div>
                                }

                                {
                                    cast.user.skype &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            {
                                                cast.user.skype &&
                                                <img onClick={()=> {this.handleClickGoToSocial( cast.user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype-active.png" />
                                            }
                                            {
                                                ! cast.user.skype &&
                                                <img onClick={()=> {this.handleClickGoToSocial( cast.user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype.png" />
                                            }
                                            </IconButton>
                                    </div>
                                }

                                {
                                    cast.user.email &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <EmailRoundedIcon onClick={()=> {this.handleClickGoToSocial( cast.user.email,"email")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                        </IconButton>
                                    </div>
                                }

                                {
                                    cast.user.phone_number &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <PhoneRoundedIcon onClick={()=> {this.handleClickGoToSocial( cast.user.phone_number,"phone")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                        </IconButton>
                                    </div>
                                }


                            </div>

                            <div className={classes.bookmarkIconsProfile}>
                                {
                                    this.props.appInitData.castBookmarks.includes(cast.id.toString()) &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <BookmarkRoundedIcon onClick={()=> {this.handleClickMinesToBookmarks(cast.id)}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                        </IconButton>
                                    </div>
                                }

                                {
                                    !this.props.appInitData.castBookmarks.includes(cast.id.toString()) &&
                                    <div>
                                        <IconButton className={classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <BookmarkBorderRoundedIcon onClick={()=> {this.handleClickAddToBookmarks(cast.id)}} fontSize={window.innerWidth>1400?"large":"medium"} />
                                        </IconButton>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className={classes.applyBtnDiv}>
                            <div className={classes.dividerCast}></div>
                        </div>

                        <div className={classes.fourthDiv}>
                            <div>
                                {
                                    cast.expire_date>0 &&
                                    <p>Casting close {cast.expire_date} days later</p>
                                }
                                {
                                    cast.expire_date<=0 &&
                                    <p>Casting closed</p>
                                }
                            </div>
                            <div className={classes.detailRightCard}>

                                {
                                    cast.expire_date>0  && !this.props.appInitData.castApplies.includes(cast.id.toString()) && cast.user.id!==auth.user.id &&
                                    <Button  onClick={()=> {this.handleClickApply(cast.id)}} variant="contained"  size="small" className={classes.searchButton} color="default" component="span">
                                        Apply
                                    </Button>
                                }

                                {
                                    ( this.props.appInitData.castApplies.includes(cast.id.toString())) &&

                                    <Button  disabled variant="contained"  className={classes.searchButton} color="default" size="small" component="span">
                                        Applied
                                    </Button>
                                }

                                {
                                    (cast.expire_date<=0 ) &&
                                    <Button  disabled variant="contained"  className={classes.searchButton} color="default" size="small" component="span">
                                        Expired
                                    </Button>
                                }



                            </div>
                        </div>

                    </div>
                }

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth : state.auth,
        cast : state.selectedCast.data,
        loading : state.loadingProps.loading,
        casts : state.casts.data,
        tempCasts : state.tempCasts,
        appInitData : state.appInitData,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{initSelectedCast,changeBottomNavStatus,initTempToCasts,changeLoadingStatus,getCastDetail,applyCast,bookmarkCast})(ShowCastContainer));
