import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/es/Checkbox/Checkbox";
import {changeLoadingStatus, updateProfile} from "../actions";
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "12px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "13px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "13px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "17px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "22px",
        }
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    firstDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    firstRightDivEditProfile:{
        "marginRight": "5%",
        "color": "#00ebff"
    },
    secondDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%",
        "flexDirection": "column",
    },
    secondLeftDivEditProfile:{
        "padding": "0 0 3% 5%"
    },
    secondRightDivEditProfile:{},
    itemCover:{
        "display": "flex",
        "justifyContent": "space-between",
        "width": "100%",
        "alignItems": "center"
    },
    DividerEditProfile:{
        "width": "92%",
        "borderBottom": "1px solid lightgray"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    jkjl:{},
});


const CustomCheckbox = withStyles({
    root: {
        color: "#fff",
        '&$checked': {
            color: "#fea257",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

class EditCategoriesComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            subgroups:[],
            userSubgroups:[],
        };
    }

    componentDidMount(){
        const { user,appInitData } = this.props;
        let subgroups=[]
        let userSubgroups=[]
        appInitData.groups.length>0 && appInitData.groups.map((group) => {
            if(group.id===user.group.id){
                group.subgroups.map( (subgroup)=>{
                    subgroups.push(subgroup)
                } )
            }
        })
        user.subgroups.length>0 &&  user.subgroups.map( (subgroup)=>{
            userSubgroups.push(subgroup.id)
        } )
        this.setState({
            subgroups:subgroups,
            userSubgroups:userSubgroups,
        })
    }

    handleGoBackSetting(){
        this.props.history.push(`/setting`)
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleChange = name => event => {
        let userSubgroups =this.state.userSubgroups
        if(event.target.checked){
            userSubgroups.push(name)
        }
        if(!event.target.checked){
            let index = userSubgroups.indexOf(name);
            if (index !== -1) userSubgroups.splice(index, 1);
        }
        this.setState({
            userSubgroups:userSubgroups,
        })
    };

    handleSaveSetting(){
        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.updateProfile(this.state,user.id,auth.apiToken, this.props.history);
    }

    render() {
        const { classes,loading } = this.props;
        const { userSubgroups,subgroups} = this.state;
        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                   <div className={classes.firstDivEditProfile}>
                       <di onClick={ ()=> {this.handleGoBackSetting()}} className={classes.firstLeftDivEditProfile}>
                           <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                               <ArrowBackIosIcon fontSize="small" />
                           </IconButton>
                       </di>

                       <div className={classes.firstRightDivEditProfile}>
                           <h5 onClick={ ()=> {this.handleSaveSetting()}}>Save</h5>
                       </div>

                   </div>

                    {
                        subgroups.length>0 && subgroups.map( (subgroup,index)=>{
                            return (
                                <div key={index} className={classes.secondDivEditProfile}>

                                    <div className={classes.itemCover}>
                                        <div className={classes.secondLeftDivEditProfile}>
                                            <h3>{subgroup.title}</h3>
                                        </div>

                                        <div className={classes.secondRightDivEditProfile}>
                                            <FormControlLabel
                                                control={
                                                    <CustomCheckbox name={subgroup.id} checked={userSubgroups.includes(subgroup.id)} onChange={this.handleChange(subgroup.id)} value={subgroup.id} />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.DividerEditProfile} ></div>
                                </div>
                            );
                        } )
                    }

                </div>
                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>
            </div>

        );
    }
}

EditCategoriesComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{updateProfile,changeLoadingStatus})(EditCategoriesComponent));