import React, { Component } from 'react';
import AppBar from "./components/sections/AppBarComponent";
import {connect} from "react-redux";
import {changeAlertStatus, changeLoadingStatus, checkAuth, deleteCast} from "./actions";
import NoMatch from "./containers/NoMatchContainer";
import {Route ,Switch , withRouter} from "react-router-dom";
import SnackBarComponent from "./components/sections/SnackBarComponent";
import {withStyles} from "@material-ui/core/styles/index";
import LoginContainer from "./containers/LoginContainer";
import BottomNavigationComponent from "./components/sections/BottomNavigationComponent";
import HomeContainer from "./containers/HomeContainer";
import CastContainer from "./containers/CastContainer";
import ProfileContainer from "./containers/ProfileContainer";
import ShowProfileContainer from "./containers/ShowProfileContainer";
import ShowCastContainer from "./containers/ShowCastContainer";
import MyActivityContainer from "./containers/MyActivityContainer";
import ShowMyCastContainer from "./containers/ShowMyCastContainer";
import SettingContainer from "./containers/SettingContainer";
import EditProfileComponent from "./components/EditProfileComponent";
import EditCategoriesComponent from "./components/EditCategoriesComponent";
import EditMeasurementComponent from "./components/EditMeasurementComponent";
import EditConnectionComponent from "./components/EditConnectionComponent";
import EditRateComponent from "./components/EditRateComponent";
import SearchContainer from "./containers/SearchContainer";
import AddNewCastComponent from "./components/AddNewCastComponent";
import PrivateRoute from "./containers/PrivateRoute";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import SearchResultContainer from "./containers/SearchResultContainer";
import ShowProfileSearchContainer from "./containers/ShowProfileSearchContainer";
import AddResumeComponent from "./components/AddResumeComponent";
import PaymentComponent from "./components/PaymentComponent";
import MessageComponent from "./components/MessageComponent";
import LoginStep1Container from "./containers/LoginStep1Container";
import LoginStep2Container from "./containers/LoginStep2Container";
import EditPrivacyComponent from "./components/EditPrivacyComponent";


const styles = theme => ({

    root: {
        "fontFamily": "En",
        backgroundColor:"#222",
        color:"white",
        "position": "relative",
        [theme.breakpoints.up('md')]: {
            "margin": "0 auto",
        },
        [theme.breakpoints.up('lg')]: {
            "margin": "0 auto",
        },
    },
    container :{
        "paddingTop": "10vh",
        "position": "relative",
        [theme.breakpoints.up('xs')]: { //Iphone
            "paddingTop": "17vw",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "paddingTop": "17vw",
        },[theme.breakpoints.up('md')]: {
            "paddingTop": "10vw",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "paddingTop": "10vw",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "paddingTop": "7vw",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "paddingTop": "5vw",
        },
    },
    dialogCover:{
        "backgroundColor": "#ff8018",
        "color": "white",
        "boxShadow": "0px 5px 20px 0 black"
    },
    dialogColor:{
        "color": "white",
    },
    ll:{},

});


class App extends Component {

    componentWillMount() {
        let apiToken = localStorage.getItem('blooomshoApiToken');
        this.props.checkAuth(apiToken,this.props.history,window.innerWidth);
    }


    handleClose = () => {
       this.props.changeAlertStatus(false,"","",null,"")
    };

    handleOkBtn = () => {
        const {actionType,itemId}=this.props.alertStatus
        if(actionType==="cast"){
            this.props.deleteCast(itemId,this.props.auth.apiToken)
            this.props.changeAlertStatus(false,"","",this.props.alertStatus.id,"cast")
            this.props.changeLoadingStatus(true)
        }

    };

    render() {
        const { classes,isAuthenticated ,alertStatus} = this.props;
        return (
            <div className={classes.root} >
                <AppBar auth={this.props.isAuthenticated}/>
                <div id="container" className={classes.container} >
                    <Switch>
                        <Route path="/user/login"  component={LoginContainer}/>
                        <PrivateRoute path="/user/select/category" auth={isAuthenticated} component={LoginStep1Container}/>
                        <PrivateRoute path="/user/add/:id/detail" auth={isAuthenticated} component={LoginStep2Container}/>
                        <PrivateRoute path="/casts" auth={isAuthenticated} component={CastContainer}/>
                        <PrivateRoute path="/casts" auth={isAuthenticated} component={CastContainer}/>
                        <PrivateRoute path="/profile" auth={isAuthenticated} component={ProfileContainer}/>
                        <PrivateRoute path="/user/:id/profile" auth={isAuthenticated} component={ShowProfileContainer}/>
                        <PrivateRoute path="/cast/:id/detail" auth={isAuthenticated} component={ShowCastContainer}/>
                        <PrivateRoute path="/all/my/activity" auth={isAuthenticated} component={MyActivityContainer}/>
                        <PrivateRoute path="/my/:id/cast" auth={isAuthenticated} component={ShowMyCastContainer}/>
                        <PrivateRoute path="/setting" auth={isAuthenticated} component={SettingContainer}/>
                        <PrivateRoute path="/add/new/cast" auth={isAuthenticated} component={AddNewCastComponent}/>
                        <PrivateRoute path="/page/user/search" auth={isAuthenticated} component={SearchContainer}/>
                        <PrivateRoute path="/search/result/user" auth={isAuthenticated} component={SearchResultContainer}/>
                        <PrivateRoute path="/search/user/:id/profile" auth={isAuthenticated} component={ShowProfileSearchContainer}/>
                        <PrivateRoute path="/edit/profile" auth={isAuthenticated} component={EditProfileComponent}/>
                        <PrivateRoute path="/edit/categories" auth={isAuthenticated} component={EditCategoriesComponent}/>
                        <PrivateRoute path="/edit/measurement" auth={isAuthenticated} component={EditMeasurementComponent}/>
                        <PrivateRoute path="/edit/connections" auth={isAuthenticated} component={EditConnectionComponent}/>
                        <PrivateRoute path="/edit/privacy" auth={isAuthenticated} component={EditPrivacyComponent}/>
                        <PrivateRoute path="/add/resume" auth={isAuthenticated} component={AddResumeComponent}/>
                        <PrivateRoute path="/payment" auth={isAuthenticated} component={PaymentComponent}/>
                        <PrivateRoute path="/messages" auth={isAuthenticated} component={MessageComponent}/>
                        <PrivateRoute path="/edit/rates" auth={isAuthenticated} component={EditRateComponent}/>
                        <PrivateRoute path="/" exact={true} auth={isAuthenticated} component={HomeContainer}/>
                        <Route  component={NoMatch}/>
                    </Switch>
                </div>
                <BottomNavigationComponent />
                <SnackBarComponent />
                <Dialog
                    open={alertStatus.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                    classes={{paper:classes.dialogCover}}
                >
                    <DialogTitle id="responsive-dialog-title">{alertStatus.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.dialogColor}>
                            {alertStatus.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.dialogColor} autoFocus onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button className={classes.dialogColor} onClick={this.handleOkBtn} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated : state.auth.isAuthenticated,
        auth : state.auth,
        alertStatus : state.alertStatus,
    }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps ,{checkAuth,changeAlertStatus,deleteCast,changeLoadingStatus} )(App)))
