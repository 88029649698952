import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/es/Switch/Switch";
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import {changeBottomNavStatus, changeLoadingStatus, logout, updateProfile} from "../actions";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {LogOut} from 'react-feather';


const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
    },
    mainDiv:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "center",
        "justifyContent": "flex-start",
        "flexDirection": "column",
        "flexWrap": "wrap",
        "width": "100%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "12px"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "12px"
        },[theme.breakpoints.up('md')]: {
            "width": "50%",
            "fontSize": "13px"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "50%",
            "fontSize": "13px"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "50%",
            "fontSize": "17px"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "50%",
            "fontSize": "24px"
        },
    },
    firstDivSetting:{
        "height": "15vh",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "space-between",
        "marginBottom": "8%",
        "& h5":{
            "margin": "0"
        },
        "& h1":{
            "color": "#fea257"
        },
    },
    secondDivSetting:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",
    },
    secondDivCoverSetting:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    secondDivLeftSetting:{
        "display": "flex",
        "justifyContent": "flex-start",
        "alignItems": "center",
        "width": "80%",
        "color": "white"
    },
    secondDivLeftSettingCompleted:{
        "display": "flex",
        "justifyContent": "flex-start",
        "alignItems": "center",
        "width": "80%",
        "color": "#fea257"
    },
    secondDivRightSetting:{},
    secondDivIconSetting:{},
    thirdDivSetting:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    thirdDivCoverSetting:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "92%"
    },
    thirdDivLeftSetting:{
        "& h4":{
            "margin": "7px 0"
        },
        "& h6":{
            "color": "#999",
            "margin": "0px 0"
        },
    },
    thirdDivRightSetting:{
        "marginRight": "-13px"
    },
    fourthDivSetting:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    fourthDivCoverSetting:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "row"
    },
    fourthDivLeftSetting:{
        "display": "flex",
        "justifyContent": "flex-start",
        "alignItems": "center",
        "width": "91%",
        "color": "#d62525"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    fourthDivIconSetting:{},
    firstDivSetting1:{},
    copyRightDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",
        marginTop: "40px",
        "& h4":{
            "color": "#999",
            margin: "0px 0",
        },
        "& h5":{
            "color": "#999",
            margin: "0px 0",
        },
    },

});


const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#fea257',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#fea257',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


class SettingContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            status:true,
            cv_flag:true,
            percent:20
        };
    }

    componentWillMount(){
        this.props.changeBottomNavStatus(null,8)
    }

    componentDidMount(){
        const {status,cv_flag,hourly_rate,instagram,height,subgroups,name,cv} =this.props.user;
        let percent=20;
        if(cv){percent=percent+10}if(subgroups){percent=percent+20}if(height){percent=percent+20}if(instagram){percent=percent+20}if(hourly_rate){percent=percent+10}
        this.setState({
            status:status==="on"?true:false,
            cv_flag:cv_flag==="on"?true:false,
            percent:percent
        })
    }

    handleChangeStatus = name => event => {
        this.setState({
            [name]: event.target.checked
        })
        const { user,auth} = this.props;
        let data={
            status:event.target.checked?"on":"off",
        }
        this.props.changeLoadingStatus(true);
        this.props.updateProfile(data,user.id,auth.apiToken, this.props.history);

    };

    handleChangeCV = name => event => {
        this.setState({
            [name]: event.target.checked
        })
        const { user,auth} = this.props;
        let data={
            cv_flag:event.target.checked?"on":"off",
        }
        this.props.changeLoadingStatus(true);
        this.props.updateProfile(data,user.id,auth.apiToken, this.props.history);
    };

    handleGoToEditProfileBtn(){
        this.props.history.push(`/edit/profile`)
    }

    handleGoToEditCategoriesBtn(){
        this.props.history.push(`/edit/categories`)
    }

    handleGoToEditMeasurementBtn(){
        this.props.history.push(`/edit/measurement`)
    }

    handleGoToEditConnectionsBtn(){
        this.props.history.push(`/edit/connections`)
    }

    handleGoToAddResumeBtn(){
        this.props.history.push(`/add/resume`)
    }

    handleGoToEditRatesBtn(){
        this.props.history.push(`/edit/rates`)
    }

    handleGoToEditPrivacyBtn(){
        this.props.history.push(`/edit/privacy`)
    }

    handleLogoutBtn(){
        this.props.changeLoadingStatus(true)
        this.props.logout(this.props.history,this.props.auth.apiToken)
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    render() {
        const {user,loading,classes} = this.props;
        const { status,cv_flag,percent } = this.state;
        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                    <div className={classes.firstDivSetting}>
                        <h1>{percent} %</h1>
                        <h5>Please complete your account</h5>
                    </div>

                    <div className={classes.secondDivSetting}>

                        <div className={classes.secondDivCoverSetting}>

                            <div className={(user.name==="" || user.name===null) ?classes.secondDivLeftSetting:classes.secondDivLeftSettingCompleted}>
                                <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <CheckRoundedIcon fontSize="small" />
                                </IconButton>
                                <h5>Edit Profile</h5>
                            </div>

                            <div onClick={()=> {this.handleGoToEditProfileBtn()}} className={classes.secondDivRightSetting}>
                                <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <ArrowForwardIosRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>

                        <div className={classes.secondDivCoverSetting}>

                            <div className={user.subgroups && user.subgroups.length>0?classes.secondDivLeftSettingCompleted:classes.secondDivLeftSetting}>
                                <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <CheckRoundedIcon fontSize="small" />
                                </IconButton>
                                <h5>Edit Categories</h5>
                            </div>

                            <div onClick={()=> {this.handleGoToEditCategoriesBtn()}} className={classes.secondDivRightSetting}>
                                <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <ArrowForwardIosRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>

                        {
                            [1,2].includes(user.group.id) &&
                            <div className={classes.secondDivCoverSetting}>

                                <div className={user.height?classes.secondDivLeftSettingCompleted:classes.secondDivLeftSetting}>
                                    <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                        <CheckRoundedIcon fontSize="small" />
                                    </IconButton>
                                    <h5>Edit Measurements</h5>
                                </div>

                                <div onClick={()=> {this.handleGoToEditMeasurementBtn()}} className={classes.secondDivRightSetting}>
                                    <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                        <ArrowForwardIosRoundedIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </div>

                        }


                        <div className={classes.secondDivCoverSetting}>

                            <div className={user.instagram?classes.secondDivLeftSettingCompleted:classes.secondDivLeftSetting}>
                                <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <CheckRoundedIcon fontSize="small" />
                                </IconButton>
                                <h5>Connections</h5>
                            </div>

                            <div onClick={()=> {this.handleGoToEditConnectionsBtn()}} className={classes.secondDivRightSetting}>
                                <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <ArrowForwardIosRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>


                        {
                            [1,2,4,6].includes(user.group.id) &&
                            <div className={classes.secondDivCoverSetting}>

                                <div className={user.hourly_rate?classes.secondDivLeftSettingCompleted:classes.secondDivLeftSetting}>
                                    <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                        <CheckRoundedIcon fontSize="small" />
                                    </IconButton>
                                    <h5>Edit Rates</h5>
                                </div>

                                <div onClick={()=> {this.handleGoToEditRatesBtn()}} className={classes.secondDivRightSetting}>
                                    <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                        <ArrowForwardIosRoundedIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </div>
                        }


                        <div className={classes.secondDivCoverSetting}>

                            <div className={classes.secondDivLeftSettingCompleted}>
                                <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <CheckRoundedIcon fontSize="small" />
                                </IconButton>
                                <h5>Edit Privacy</h5>
                            </div>

                            <div onClick={()=> {this.handleGoToEditPrivacyBtn()}} className={classes.secondDivRightSetting}>
                                <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <ArrowForwardIosRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>


                        {
                            [1,2,4,6].includes(user.group.id) &&
                            <div className={classes.secondDivCoverSetting}>

                                <div className={user.cv?classes.secondDivLeftSettingCompleted:classes.secondDivLeftSetting}>
                                    <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                        <CheckRoundedIcon fontSize="small" />
                                    </IconButton>
                                    <h5>Add resume</h5>
                                </div>

                                <div onClick={()=> {this.handleGoToAddResumeBtn()}} className={classes.secondDivRightSetting}>
                                    <IconButton className={classes.secondDivIconSetting} aria-haspopup="true"  color="inherit">
                                        <ArrowForwardIosRoundedIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </div>
                        }


                    </div>

                    <div className={classes.thirdDivSetting}>

                        <div className={classes.thirdDivCoverSetting}>

                            <div className={classes.thirdDivLeftSetting}>
                                <h4>Display my account</h4>
                                <h6>Allow your profile to be visible in the search listing</h6>
                            </div>

                            <div className={classes.thirdDivRightSetting}>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={status}
                                            onChange={this.handleChangeStatus('status')}
                                            value={status}
                                        />
                                    }
                                />
                            </div>
                        </div>

                    </div>

                    <div className={classes.thirdDivSetting}>

                        <div className={classes.thirdDivCoverSetting}>

                            <div className={classes.thirdDivLeftSetting}>
                                <h4>Display my CV</h4>
                                <h6>Allow all user to see your CV</h6>
                            </div>

                            <div className={classes.thirdDivRightSetting}>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={cv_flag}
                                            onChange={this.handleChangeCV('cv_flag')}
                                            value={cv_flag}
                                        />
                                    }
                                />
                            </div>
                        </div>

                    </div>

                    <div className={classes.fourthDivSetting}>

                        <div className={classes.fourthDivCoverSetting}>

                            <div onClick={()=> {this.handleLogoutBtn()}} className={classes.fourthDivLeftSetting}>
                                <h4>Logout</h4>
                                <IconButton className={classes.fourthDivIconSetting} aria-haspopup="true"  color="inherit">
                                    <LogOut size={20} />
                                </IconButton>
                            </div>

                        </div>

                    </div>

                    <div className={classes.copyRightDiv}>
                        <h4>Copyright 2020 Bloomsho. All Rights Reserved.</h4>
                        <h5>Ver 1.2</h5>
                    </div>

                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>

        );
    }
}

SettingContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{changeBottomNavStatus,logout,updateProfile,changeLoadingStatus})(SettingContainer));