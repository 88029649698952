import React, { Component  } from 'react';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles/index";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import {baseUrl} from "../constants/constant";

const styles = (theme) => ({
    mainDivCover:{
        "width": "46%",
        "display": "flex",
        "marginTop": "10px",
        "height": "254px",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        "backgroundColor": "#2f2f2f",
        "borderRadius": "5px",
        "boxShadow": "0 1px 3px 0px rgba(0,0,0,.5)",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "95%",
            "height": "220px",
            "fontSize": "11px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "90%",
            "height": "225px",
            "fontSize": "12px",
        },[theme.breakpoints.up('md')]: {
            "width": "48%",
            "height": "200px",
            "fontSize": "9px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "48%",
            "height": "200px",
            "fontSize": "9px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "48%",
            "height": "225px",
            "fontSize": "12px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "48%",
            "height": "310px",
            "fontSize": "18px",
        },
    },
    firstDiv:{
        "display": "flex",
        "justifyContent": "flex-start",
        "alignItems": "center",
        "width": "100%",
        "height": "25%",
    },
    secondDiv:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "100%",
        "height": "25%",
    },
    thirdDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "height": "30%",
        "& div":{
            "width": "85%",
            "textAlign": "justify",
            "color": "#a0a0a0",
        }
    },
    fourthDiv:{
        "width": "97%",
        "height": "20%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between",
        "paddingLeft": "4%"
    },
    secondCastDetailDiv:{
    "display": "flex",
    "width": "50%",
    "justifyContent": "space-around",
    },
    colorOrange:{
        "color": "#ff8630",
    },

});


class CastComponent extends Component {

    constructor(props){
        super(props);
        this.state ={

        }
    }

    handleGoToCastDetailBtn(id){
        this.props.history.push(`/cast/${id}/detail`)
    }

    render() {

        const {classes,cast}=this.props;

        return (

            <div className={classes.mainDivCover}>

                {
                    cast &&
                    <div onClick={()=> {this.handleGoToCastDetailBtn(cast.id)}} className={classes.firstDiv}>
                        <div>
                            <IconButton aria-haspopup="true"  color="inherit">
                                <Avatar alt="Profile photo" src={cast.user?baseUrl+cast.user.main_photo:"/images/no-user.png"} />
                            </IconButton>
                        </div>
                        <div>
                            <div className={classes.titleCard} >
                                <h3>
                                    {cast.title &&cast.title.substr(0,30)}
                                    {cast.title && cast.title.length>30 && " ..."}
                                </h3>
                            </div>
                        </div>
                    </div>
                }

                {
                    cast &&
                    <div onClick={()=> {this.handleGoToCastDetailBtn(cast.id)}} className={classes.secondDiv}>
                        <div className={classes.secondCastDetailDiv}>
                            <div className={classes.detailLeftCard}>
                                <div className={classes.detailCardP} >
                                    <p>Type : </p>
                                    <p>Gender : </p>
                                </div>
                            </div>
                            <div className={classes.detailCenterCard}>
                                <div className={classes.detailCardP} >
                                    <p  className={classes.colorOrange} >{cast.group?cast.group.title:""}</p>
                                    <p  className={classes.colorOrange} >{cast.gender}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.secondCastDetailDiv}>
                            <div className={classes.detailLeftCard}>
                                <div className={classes.detailCardP} >
                                    <p>Location : </p>
                                    <p>Age : </p>
                                </div>
                            </div>
                            <div className={classes.detailCenterCard}>
                                <div className={classes.detailCardP} >
                                    <p  className={classes.colorOrange} >{cast.city?cast.city.title:""}</p>
                                    <p  className={classes.colorOrange} >{cast.age?cast.age.replace(",","-"):""}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    cast &&
                    <div onClick={()=> {this.handleGoToCastDetailBtn(cast.id)}} className={classes.thirdDiv}>
                        <div>
                            <p>
                                {cast.description && cast.description.substr(0,80)}
                                {cast.description && cast.description.length>80 && " ..."}
                            </p>
                        </div>
                    </div>
                }

                {
                    cast &&
                    <div onClick={()=> {this.handleGoToCastDetailBtn(cast.id)}} className={classes.fourthDiv}>
                        <div>
                            <p>{cast.created_at}</p>
                        </div>
                        {/*<div className={classes.detailRightCard}>
                        <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                            <TurnedInNotRoundedIcon fontSize={window.innerWidth>1400?"large":"medium"} />
                        </IconButton>
                    </div>*/}
                    </div>
                }


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

export default withStyles(styles)(connect(mapStateToProps,null)(CastComponent));
