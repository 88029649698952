import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Slider from "@material-ui/core/es/Slider/Slider";
import {changeLoadingStatus, updateProfile} from "../actions";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "12px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "13px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "13px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "17px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "22px",
        }
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    firstDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    firstRightDivEditProfile:{
        "marginRight": "5%",
        "color": "#00ebff"
    },
    thirdDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column"
    },
    thirdDivCoverEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
    },
    thirdDivInputRangeEditProfile:{
        width: "81%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "2px auto 20px",
    },
    thirdDivRangeEditProfile: {
        color: "#ff8630",
        width: "100%",
        margin: "-12px auto",
    },

    currencyDivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    currencyCoverDivEditRate:{
        "display": "flex",
        "justifyContent": "space-around",
        "alignItems": "center",
        "width": "70%",
        "border": "1px solid lightgray",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "padding": "2%",
        "boxShadow": "0 1px 3px 1px black"
    },
    currencyItem1DivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "43%",
        "border": "1px solid white",
        "height": "32px",
        "borderRadius": "5px",
        "backgroundColor": "white",
        "color": "#222222",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "32px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "40px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "33px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "38px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
        }
    },
    currencyItem2DivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "43%",
        "border": "1px solid #222222",
        "height": "32px",
        "borderRadius": "5px",
        "backgroundColor": "#222222",
        "color": "white",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "32px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "40px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "33px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "38px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    jkjl:{},
});


class EditRateComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            yearlyRate:25,
            monthlyRate:25,
            dailyRate:25,
            hourlyRate:25,
            currency:"USD",
        };
        this.handelRangeHourlyRate = this.handelRangeHourlyRate.bind(this);
        this.handelRangeDailyRate = this.handelRangeDailyRate.bind(this);
        this.handelRangeMonthlyRate = this.handelRangeMonthlyRate.bind(this);
        this.handelRangeYearlyRate = this.handelRangeYearlyRate.bind(this);
    }

    componentDidMount(){
        const {yearly_rate,monthly_rate,daily_rate,hourly_rate,currency} =this.props.user;
        this.setState({
            yearlyRate:yearly_rate===0 || yearly_rate===null?0:yearly_rate,
            monthlyRate:monthly_rate===0 || monthly_rate===null?0:monthly_rate,
            dailyRate:daily_rate===0 || daily_rate===null?0:daily_rate,
            hourlyRate:hourly_rate===0 || hourly_rate===null?0:hourly_rate,
            currency:currency,
        })
        if(currency==="EUR"){
            this.handleClickCurrency(2,1)
        }
        if(currency==="USD"){
            this.handleClickCurrency(1,2)
        }
    }

    handleGoBackSetting(){
        this.props.history.push(`/setting`)
    }

    handelRangeHourlyRate(e,value){
        this.setState({
            hourlyRate:value
        })
        let ele= document.getElementById(`hourlyRateP`);
        ele.innerText="Hourly Rate : " +this.state.hourlyRate
    }

    handelRangeDailyRate(e,value){
        this.setState({
            dailyRate:value
        })
        let ele= document.getElementById(`dailyRateP`);
        ele.innerText="Daily Rate : " +this.state.dailyRate
    }

    handelRangeMonthlyRate(e,value){
        this.setState({
            monthlyRate:value
        })
        let ele= document.getElementById(`monthlyRateP`);
        ele.innerText="Monthly Rate : " +this.state.monthlyRate
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handelRangeYearlyRate(e,value){
        this.setState({
            yearlyRate:value
        })
        let ele= document.getElementById(`yearlyRateP`);
        ele.innerText="Yearly Rate : " +this.state.yearlyRate
    }

    handleClickCurrency = (idCur,idNext) => {
        let eleA= document.getElementById(`currency${idCur}`);
        let eleB= document.getElementById(`currency${idNext}`);
        eleA.style.backgroundColor= "white"
        eleA.style.color= "#222222"
        eleA.style.borderColor= "white"

        eleB.style.backgroundColor= "#222222"
        eleB.style.color= "white"
        eleB.style.borderColor= "#222222"
        this.setState({
            currency:idNext===2?"USD":"EUR"
        })
    }

    handleSaveSetting(){
        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.updateProfile(this.state,user.id,auth.apiToken, this.props.history);
    }

    render() {
        const { classes ,loading} = this.props;
        const { hourlyRate,dailyRate,monthlyRate,yearlyRate} = this.state;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                   <div className={classes.firstDivEditProfile}>
                       <di onClick={ ()=> {this.handleGoBackSetting()}} className={classes.firstLeftDivEditProfile}>
                           <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                               <ArrowBackIosIcon fontSize="small" />
                           </IconButton>
                       </di>

                       <div className={classes.firstRightDivEditProfile}>
                           <h5 onClick={ ()=> {this.handleSaveSetting()}}>Save</h5>
                       </div>

                   </div>

                    <div className={classes.thirdDivEditProfile}>

                        <div className={classes.currencyDivEditRate}>
                            <div className={classes.currencyCoverDivEditRate}>
                                <div id="currency1" onClick={ ()=> {this.handleClickCurrency(1,2)}} className={classes.currencyItem1DivEditRate}><h2>USD</h2></div>
                                <div id="currency2" onClick={ ()=> {this.handleClickCurrency(2,1)}} className={classes.currencyItem2DivEditRate}><h2>EUR</h2></div>
                            </div>
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="hourlyRateP">Hourly Rate : {hourlyRate==0?"negotiation":hourlyRate}</p>
                            </div>
                            <Slider onChange={ this.handelRangeHourlyRate }  value={hourlyRate} className={classes.thirdDivRangeEditProfile}   min={0} max={200} defaultValue={hourlyRate}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="dailyRateP">Daily Rate : {dailyRate==0?"negotiation":dailyRate}</p>
                            </div>
                            <Slider onChange={ this.handelRangeDailyRate}  value={dailyRate} className={classes.thirdDivRangeEditProfile}   min={0} max={500} defaultValue={dailyRate}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="monthlyRateP">Monthly Rate : {monthlyRate==0?"negotiation":monthlyRate}</p>
                            </div>
                            <Slider onChange={ this.handelRangeMonthlyRate }  value={monthlyRate} className={classes.thirdDivRangeEditProfile}   min={0} max={50000} defaultValue={monthlyRate}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="yearlyRateP">Yearly Rate : {yearlyRate==0?"negotiation":yearlyRate}</p>
                            </div>
                            <Slider onChange={ this.handelRangeYearlyRate } value={yearlyRate} className={classes.thirdDivRangeEditProfile}   min={0} max={5000000} defaultValue={yearlyRate}  />
                        </div>


                    </div>

                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>
            </div>

        );
    }
}

EditRateComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{updateProfile,changeLoadingStatus})(EditRateComponent));