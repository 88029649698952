import React ,{Component,PureComponent} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import Slide from "@material-ui/core/es/Slide/Slide";
import CloseIcon from '@material-ui/icons/Close';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {baseUrl} from "../constants/constant";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/es/DialogContentText/DialogContentText";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {addPhotoUser, changeLoadingStatus, deletePhotoUser, showUserProfile} from "../actions";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './../styles/css/App.css';
import Button from "@material-ui/core/es/Button/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {Plus,Settings} from 'react-feather';

const styles = theme => ( {

    mainDivMobile:{
        "min-height": "92vh",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "width": "100%",
        "paddingBottom": "60px",
        [theme.breakpoints.up('xs')]: { //Iphone
            "display": "flex",
            "fontSize": "11px"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "display": "flex",
            "fontSize": "13px"
        },[theme.breakpoints.up('md')]: {
            "display": "none",
            "width": "80%",
            "fontSize": "14px"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "display": "none",
        }
    },

    mainDivWeb:{
        "width": "100%",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "paddingBottom": "60px",
        "justifyContent": "center",
        "flexDirection": "column",
        [theme.breakpoints.up('xs')]: { //Iphone
            "display": "none",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "display": "none",
        },[theme.breakpoints.up('md')]: {
            "display": "flex",
            "fontSize": "14px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "display": "flex",
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "display": "flex",
            "fontSize": "12px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "display": "flex",
            "fontSize": "15px",
        }

    },

    firstDivProfile:{
        "overflow": "hidden",
        "position": "relative",
        "alignItems": "center",
        "flexDirection": "column",
        "backgroundSize": "cover",
        "justifyContent": "center",
        "backgroundPosition": "center",
        "borderRadius": "5px",
        /*"boxShadow": "inset 0px -37px 20px 0px #222222f2",*/
        "marginBottom": "1%",
        /* "width": "98%",
         "height": "50vh",*/
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "98vw",
            "height": "130vw",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "98vw",
            "height": "130vw",
        },[theme.breakpoints.up('md')]: {
            "width": "98vw",
            "height": "130vw",
        }
        ,
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "19vw",
            "height": "25vw",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "19vw",
            "height": "25vw",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "19vw",
            "height": "25vw",
        }
    },
    detailDivProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "90%",
        "height": "fit-content",
        [theme.breakpoints.up('md')]: {
            "height": "20%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "20%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "25%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "30%",
        }
    },
    secondDetailDivProfile:{
        "display": "flex",
        "width": "100%",
        "justifyContent": "space-around",
    },
    colorOrange:{
        "color": "#ff8630",
    },
    coverDetailDivProfile:{
        "display": "flex",
        "justifyContent": "flex-start",
        "flexDirection": "column",
        "alignItems": "center",
        "width": "100%",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        }
    },
    titleDetailDivProfile:{
        "textAlign": "center",
        "width": "68%",


    },
    aboutMeProfile:{
        "textAlign": "center",
        "width": "80%",
        "marginBottom": "11px",
    },
    detailIconsProfile:{
        "color": "#5accd2"
    },
    socialIconsColorProfile:{
        "color": "#ffffff73",
        [theme.breakpoints.down('lg')]: {//Tab
            "height": "5vh",
            "width": "5vh"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "5vh",
            "width": "3vh"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "5vh",
            "width": "3vh"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "4vh",
            "width": "5vh"
        }
    },
    socialIconsColorProfileActive:{
        "color": "#fffbf0",
        [theme.breakpoints.down('lg')]: {//Tab
            "height": "5vh",
            "width": "5vh"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "5vh",
            "width": "3vh"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "5vh",
            "width": "3vh"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "4vh",
            "width": "5vh"
        }
    },
    socialIconsProfile:{
        "display": "flex",
        "marginTop": "1%",
        "flexWrap": "wrap",
    },
    photosTitleProfile:{
        "width": "98%",
        "fontSize": "20px",
        "textAlign": "center",
        "borderRadius": "5px",
        "marginBottom": "5px",
        "backgroundColor": "#333",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    othersPhotosDivProfile:{
        "width": "98%",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "row",
        "justifyContent": "space-evenly",
        "flexWrap": "wrap",
        [theme.breakpoints.up('md')]: {
            "width": "49%",
        },
    },
    othersPhotosProfile:{
        "width": "16vw",
        "height": "22vw",
        "backgroundSize": "cover",
        "backgroundPosition": "center",
        "borderRadius": "1px",
        "margin": "1px 0",
        [theme.breakpoints.down('md')]: {
            "width": "32vw",
            "height": "42vw",
        },
    },
    firstSectionDivProfile:{
        "display": "flex",
        "justifyContent": "space-around",
        "alignItems": "center",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "50%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "50%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "50%",
        }
    },
    detailCardProfileWeb:{
        "height": "100%",
        "display": "flex",
        "justifyContent": "space-evenly",
        "alignItems": "end",
        "flexDirection": "column",
        "& p":{
            "margin": "1px 0"
        },
    },
    socialDivCover:{
        "display": "flex",
        "justifyContent": "space-between",
        "width": "90%"
    },
    appBarDialog: {
        position: 'relative',
        "backgroundColor": "#222222",
        "boxShadow": "0 0 0 0 #222222",
        "paddingTop": "11vh",
        "height": "40px",
        "flexDirection": "row",
        "justifyContent": "flex-end",
    },
    titleDialog: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    containerProfileA: {
        "marginTop": "7px",
        "paddingBottom": "20%",
    },
    coverPhotoDialog: {
        "width": "100%",
        "display": "flex",
        "minHeight": "100%",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "backgroundColor": "#222222",
        "paddingTop": "8vh",
        "paddingBottom": "20%"

    },coverUploadPhotoDialog: {
        "width": "100%",
        "display": "flex",
        "minHeight": "100%",
        "alignItems": "center",
        "paddingTop": "8vh",
        "paddingBottom": "20%",
        "justifyContent": "start",
        "backgroundColor": "#222222",
        "flexDirection": "column"

    },
    dialogPhotoProfile: {
        "width": "40vw",
        "height": "53vw",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        "boxShadow": "0px 4px 6px 1px black",
        "borderRadius": "5px",
        [theme.breakpoints.up('xs')]: {
            "width": "80vw",
            "height": "106vw",
        },
        [theme.breakpoints.up('sm')]: {//Tab
            "width": "80vw",
            "height": "106vw",
        },
        [theme.breakpoints.up('md')]: {
            "width": "53vw",
            "height": "70vw",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "52vh",
            "height": "70vh",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "52vh",
            "height": "70vh",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "52vh",
            "height": "70vh",
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    categoryDivCover:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "85%",
        "marginBottom": "1vw"
    },
    categoryDivPhoto:{
        "width": "15%",
        "paddingRight": "2%",
        "& img":{
            "width": "3vw",
            [theme.breakpoints.down('md')]: {//Tab
                "width": "12vw",
            },
        }
    },
    categoryDivTitle:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "start",
        "flexDirection": "column",
        "width": "70%",
        "& h3":{
            "margin": "0"
        },
        "& div":{
            "color": "#969090",
            [theme.breakpoints.up('lg')]: {//Tab
                "fontSize": "10px"
            },
            [theme.breakpoints.up('xl')]: {//lap
                "fontSize": "10px"
            },
            [theme.breakpoints.up('xxl')]: {//Llap
                "fontSize": "11px"
            }
        }
    },
    categoryDivView:{
        "display": "flex",
        "flexDirection": "column",
        "width": "15%",
        "justifyContent": "center",
        "alignItems": "center",
        "& h4":{
            "margin": "0"
        },
    },
    resumeDivCover:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "47%",
        "margin": "1% 0 1% 0",
        [theme.breakpoints.down('md')]: {
            "flexDirection": "column",
            "width": "100%",
        }
    },
    resumeDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "54%",
        "backgroundColor": "#333",
        "borderRadius": "10px",
        "border": "1px solid lightgray",
        [theme.breakpoints.down('md')]: {
            "margin": "1% 0",
            "width": "90%",
        }
    },
    disabledDivCover:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "41%",
        "backgroundColor": "red",
        "borderRadius": "10px",
        "& h4":{
            "margin": "4%",
            "textAlign": "center"
        },
        [theme.breakpoints.down('md')]: {
            "margin": "1% 0",
            "width": "90%",
        }
    },

    activeDivCover:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "41%",
        "backgroundColor": "#19ce34",
        "borderRadius": "10px",
        "& h5":{
            "margin": "1vw",
            "textAlign": "center",
            [theme.breakpoints.down('md')]: {
                "margin": "5vw 4vw",
            }
        },
        [theme.breakpoints.down('md')]: {
            "margin": "1% 0",
            "width": "90%",
        }
    },
    deleteDiv:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    button: {
        "margin": "8px 5vw",
        "backgroundColor": "#dc1a1a",
        "fontFamily": "En",
    },
    buttonUpload: {
        "fontFamily": "En",
        "margin": "25% 0",
    },
    toolBarDiv:{
        "width": "100%",
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center"
    },
    skypeIcon:{
        "width": "5vw",
        [theme.breakpoints.up('md')]: {
            "width": "3vh",
        },
        [theme.breakpoints.up('xl')]: {
            "width": "3vh",
        }
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    IconProfile:{
        margin: "-8px 0 10px 0",
        "color": "#5accd2",
        textAlign: "center"
    },
    LL:{},
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProfileContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            open:false,
            dialogPhotoSrc:"",
            dialogPhotoKey:"",
            dialogPhotoPureUrl:"",
            openA:false,
            openPhoto:false,
            about_meD:"",
            base64Photo: null,
            src: null,
            crop: {
                unit: 'vw',
                width: 30,
                aspect: 3 / 4,
            },
        };
    }

    componentWillMount(){
        this.props.changeLoadingStatus(true)
        this.props.showUserProfile(this.props.user.id,this.props.auth.apiToken)
    }

    handleClickOpen = (url,pureUrl,key) => {
        this.setState({
            open:true,
            dialogPhotoSrc:url,
            dialogPhotoKey:key,
            dialogPhotoPureUrl:pureUrl,
        })
    };

    handleClose = () => {
        this.setState({
            open:false,
            dialogPhotoSrc:"",
        })
    };

    handleClickOpenPhoto = () => {
        this.setState({
            openPhoto:true,
        })
    };

    handleClosePhoto = () => {
        this.setState({
            openPhoto:false,
            src:null,
            croppedImageUrl:null,
        })
    };

    handleGoToSettingBtn(){
        this.props.history.push(`/setting`)
    }

    handleClickPayment(){
        this.props.history.push(`/payment`)
    }

    handleOpenAboutMe = (text) => {
        this.setState({
            openA:true,
            about_meD:text,
        })
    };

    handleCloseAboutMe = () => {
        this.setState({
            openA:false,
            about_meD:"",
        })
    };

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width*scaleX;
        canvas.height = crop.height*scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );

        const base64Image = canvas.toDataURL('image/jpeg');
        this.setState({ base64Photo: base64Image});

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    handleClickAddPhotos = () => {
        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.addPhotoUser(user.id,this.state.base64Photo.replace("data:image/jpeg;base64,",""),"other",auth.apiToken);
        this.setState({
            openPhoto:false,
            src:null,
            croppedImageUrl:null,
        })

    };

    handleDeleteCastBtn(){

        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.deletePhotoUser(user.id,this.state.dialogPhotoKey,this.state.dialogPhotoPureUrl,"other",auth.apiToken);
        this.setState({
            open:false,
            dialogPhotoSrc:'',
            dialogPhotoKey:'',
            dialogPhotoPureUrl:'',
        })
    }

    handleClickGoToSocial = (username,type) => {
        if(username && type==="instagram"){
            window.open(`https://www.instagram.com/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="telegram"){
            window.open(`https://t.me/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="facebook"){
            window.open(`https://m.facebook.com/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="linkedin"){
            window.open(username,"_blank").focus();
        }
        if(username && type==="phone"){
            window.open(`tel:${username}`,"_blank").focus();
        }
        if(username && type==="email"){
            window.open(`mailto: ${username}`,"_blank").focus();
        }
        if(username && type==="youtube"){
            window.open(`https://www.youtube.com/user/${username.replace("@","")}`,"_blank").focus();
        }
        if(username && type==="skype"){
            window.open(`skype:${username}?userinfo`,"_blank").focus();
        }
    };

    handleViewResume() {
        const {user} = this.props;
        if(user.cv){
            window.open(baseUrl+`download/${user.id}/resume`, '_blank').focus();
        }
    };

    render() {
        const { classes,user,loading  } = this.props;
        const { open,dialogPhotoSrc,openA,about_meD,crop, croppedImageUrl, src ,openPhoto } = this.state;
        return (

            <div className={classes.containerProfileA}>

                {
                    user &&
                    <div  className={classes.mainDivMobile}>

                        <div  className={classes.firstDivProfile} style={{backgroundImage:`url('${user.main_photo?baseUrl+user.main_photo:"/images/no-user.png"}')`,boxShadow:`${user.main_photo?"inset 0px -37px 20px 0px #222222f2":"none"}`}}>

                        </div>

                        <div className={classes.coverDetailDivProfile}>

                            <div className={classes.detailDivProfile}>

                                <div>
                                    <IconButton onClick={()=> {this.handleClickOpenPhoto()}} className={classes.detailIconsProfile} aria-haspopup="true"  color="inherit">
                                        <Plus size={22} />
                                    </IconButton>
                                    <h5 className={classes.IconProfile} >Add photo</h5>
                                </div>

                                <div className={classes.titleDetailDivProfile}>
                                    <h2> {user.name + " " + user.last_name}</h2>
                                </div>

                                <div>
                                    <IconButton onClick={()=> {this.handleGoToSettingBtn()}} className={classes.detailIconsProfile} aria-haspopup="true"  color="inherit">
                                        <Settings size={22}/>
                                    </IconButton>
                                    <h5 className={classes.IconProfile}>Setting</h5>
                                </div>

                            </div>


                            <div className={classes.categoryDivCover}>

                                <div className={classes.categoryDivPhoto}>
                                    <img src={user.group?baseUrl+user.group.photo:""} alt=""/>
                                </div>
                                <div className={classes.categoryDivTitle}>
                                    <h3>{user.group?user.group.title:"N/A"}</h3>
                                    <div>
                                        {user.subgroups.length>0 &&  user.subgroups.map( (subgroup,index)=> {return (user.subgroups.length===index+1?subgroup.title:subgroup.title + " - " )} )}
                                        {user.subgroups.length===0 && "Not selected" }
                                    </div>
                                </div>
                                <div className={classes.categoryDivView}>
                                    <IconButton className={classes.socialIconsColorProfileActive} aria-haspopup="true"  color="inherit">
                                        <VisibilitySharpIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                    <h4>{user.count_view}</h4>
                                </div>

                            </div>

                            <div className={classes.detailDivProfile}>
                                <div className={classes.secondDetailDivProfile}>
                                    <div className={classes.detailLeftCard}>
                                        <div className={classes.detailCardP} >
                                            <p>Country : </p>
                                            <p>City : </p>
                                            <p>Gender: </p>
                                            <p>Age: </p>
                                        </div>
                                    </div>
                                    <div className={classes.detailCenterCard}>
                                        <div className={classes.detailCardP} >
                                            <p  className={classes.colorOrange} >{user.country?user.country.title.substring(0, 15):""}</p>
                                            <p  className={classes.colorOrange} >{user.city?user.city.title:""}</p>
                                            <p  className={classes.colorOrange} >{user.gender?user.gender:"N/A"}</p>
                                            <p  className={classes.colorOrange} >{user.age?user.age+" years":"N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.secondDetailDivProfile}>
                                    <div className={classes.detailLeftCard}>
                                        <div className={classes.detailCardP} >
                                            <p>Hourly Rate : </p>
                                            <p>Daily Rate : </p>
                                            <p>Monthly Rate : </p>
                                            <p>Yearly Rate : </p>
                                        </div>
                                    </div>
                                    <div className={classes.detailCenterCard}>
                                        <div className={classes.detailCardP} >
                                            <p  className={classes.colorOrange} >{user.hourly_rate && user.hourly_rate!=0?user.hourly_rate+" "+ user.currency:"Negotiation"}</p>
                                            <p  className={classes.colorOrange} >{user.daily_rate && user.daily_rate!=0?user.daily_rate+" "+ user.currency:"Negotiation"}</p>
                                            <p  className={classes.colorOrange} >{user.monthly_rate && user.monthly_rate!=0?user.monthly_rate+" "+ user.currency:"Negotiation"}</p>
                                            <p  className={classes.colorOrange} >{user.yearly_rate && user.yearly_rate!=0?user.yearly_rate+" "+ user.currency:"Negotiation"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.socialIconsProfile}>
                                <div>
                                    <IconButton className={user.facebook?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <FacebookIcon onClick={()=> {this.handleClickGoToSocial(user.facebook,"facebook")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton className={user.instagram?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <InstagramIcon onClick={()=> {this.handleClickGoToSocial(user.instagram,"instagram")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton className={user.linkedin?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <LinkedInIcon onClick={()=> {this.handleClickGoToSocial(user.linkedin,"linkedin")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton className={user.telegram?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <TelegramIcon onClick={()=> {this.handleClickGoToSocial(user.telegram,"telegram")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton className={user.youtube?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <YouTubeIcon onClick={()=> {this.handleClickGoToSocial(user.youtube,"youtube")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton className={user.skype?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        {
                                            user.skype &&
                                            <img onClick={()=> {this.handleClickGoToSocial(user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype-active.png" />
                                        }
                                        {
                                            !user.skype &&
                                            <img onClick={()=> {this.handleClickGoToSocial(user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype.png" />
                                        }
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton className={user.email?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <EmailRoundedIcon onClick={()=> {this.handleClickGoToSocial(user.email,"email")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                    </IconButton>
                                </div>
                                <div>
                                    <IconButton className={user.phone_number?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                        <PhoneRoundedIcon onClick={()=> {this.handleClickGoToSocial(user.phone_number,"phone")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                    </IconButton>
                                </div>
                            </div>

                        </div>

                        <div className={classes.resumeDivCover}>

                            <div onClick={ ()=> {this.handleViewResume()}} className={classes.resumeDiv}>
                                <IconButton   className={classes.socialIconsColorProfileActive} aria-haspopup="true"  color="inherit">
                                    <EventNoteIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                </IconButton>
                                <h4>View resume</h4>
                            </div>


                            {
                                user.credit_date_day>0 &&
                                <div  className={classes.activeDivCover}>
                                    <h5>Your account will be active for another {user.credit_date_day} days</h5>
                                    <IconButton className={classes.socialIconsColorProfileActive} aria-haspopup="true"  color="inherit">
                                        <CheckCircleRoundedIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                            }

                            {
                                user.credit_date_day===0  &&
                                <div onClick={()=> {this.handleClickPayment()}}  className={classes.disabledDivCover}>
                                    <IconButton className={classes.activeDiv} aria-haspopup="true"  color="inherit">
                                        <WarningRoundedIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                    <h5>Your account is disabled. please charge your account to stay active</h5>
                                    <IconButton className={classes.socialIconsColorProfileActive} aria-haspopup="true"  color="inherit">
                                        <ArrowForwardRoundedIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                            }



                        </div>

                        <div className={classes.photosTitleProfile}>
                            <p>
                                Photos
                            </p>
                        </div>

                        <div className={classes.othersPhotosDivProfile}>
                            <div style={{"justifyContent": "flex-start"}} className={classes.othersPhotosDivProfile}>

                                {
                                    user.photos && user.photos.split(";").map((photo , index) => {
                                        return (
                                            <div key={index} onClick={()=> {this.handleClickOpen(baseUrl+photo,photo,index)}} className={classes.othersPhotosProfile} style={{backgroundImage:`url('${baseUrl+photo}')`}}></div>
                                        )
                                    })
                                }

                            </div>
                            {
                                !user.photos &&
                                <div><h3>No Photos</h3></div>
                            }
                        </div>

                    </div>
                }


                {
                    user &&
                    <div  className={classes.mainDivWeb}>

                        <div className={classes.firstSectionDivProfile}>

                            <div  className={classes.firstDivProfile} style={{backgroundImage:`url('${user.main_photo?baseUrl+user.main_photo:"/images/no-user.png"}')`}}>

                            </div>

                            <div className={classes.coverDetailDivProfile}>

                                <div className={classes.detailDivProfile}>
                                    <div>
                                        <IconButton onClick={()=> {this.handleClickOpenPhoto()}} className={classes.detailIconsProfile} aria-haspopup="true"  color="inherit">
                                            <Plus size={22} />
                                        </IconButton>
                                        <h5 className={classes.IconProfile} >Add photo</h5>
                                    </div>

                                    <div className={classes.titleDetailDivProfile}>
                                        <h3>{user.name + " " + user.last_name}</h3>
                                    </div>

                                    <div>
                                        <IconButton onClick={()=> {this.handleGoToSettingBtn()}} className={classes.detailIconsProfile} aria-haspopup="true"  color="inherit">
                                            <Settings size={22}/>
                                        </IconButton>
                                        <h5 className={classes.IconProfile}>Setting</h5>
                                    </div>

                                </div>

                                <div className={classes.categoryDivCover}>

                                    <div className={classes.categoryDivPhoto}>
                                        <img src={user.group?baseUrl+user.group.photo:""} alt=""/>
                                    </div>
                                    <div className={classes.categoryDivTitle}>
                                        <h3>{user.group?user.group.title:"N/A"}</h3>
                                        <div>
                                            {user.subgroups.length>0 &&  user.subgroups.map( (subgroup,index)=> {return (user.subgroups.length===index+1?subgroup.title:subgroup.title + " - " )} )}
                                            {user.subgroups.length===0 && "Not selected" }
                                        </div>
                                    </div>
                                    <div className={classes.categoryDivView}>
                                        <IconButton className={classes.socialIconsColorProfileActive} aria-haspopup="true"  color="inherit">
                                            <VisibilitySharpIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                        </IconButton>
                                        <h4>{user.count_view}</h4>
                                    </div>

                                </div>

                                <div className={classes.detailDivProfile}>
                                    <div className={classes.secondDetailDivProfile}>
                                        <div className={classes.detailLeftCard}>
                                            <div className={classes.detailCardProfileWeb} >
                                                <p>Country : </p>
                                                <p>City : </p>
                                                <p>Gender: </p>
                                                <p>Age: </p>
                                                <p>Hourly Rate : </p>
                                                <p>Daily Rate : </p>
                                                <p>Monthly Rate : </p>
                                                <p>Yearly Rate : </p>

                                            </div>
                                        </div>
                                        <div className={classes.detailCenterCard}>
                                            <div className={classes.detailCardProfileWeb} >
                                                <p  className={classes.colorOrange} >{user.country?user.country.title.substring(0, 15):""}</p>
                                                <p  className={classes.colorOrange} >{user.city?user.city.title:""}</p>
                                                <p  className={classes.colorOrange} >{user.gender?user.gender:"N/A"}</p>
                                                <p  className={classes.colorOrange} >{user.age?user.age+" years":"N/A"}</p>
                                                <p  className={classes.colorOrange} >{user.hourly_rate && user.hourly_rate!=0?user.hourly_rate+" "+ user.currency:"Negotiation"}</p>
                                                <p  className={classes.colorOrange} >{user.daily_rate && user.daily_rate!=0?user.daily_rate+" "+ user.currency:"Negotiation"}</p>
                                                <p  className={classes.colorOrange} >{user.monthly_rate && user.monthly_rate!=0?user.monthly_rate+" "+ user.currency:"Negotiation"}</p>
                                                <p  className={classes.colorOrange} >{user.yearly_rate && user.yearly_rate!=0?user.yearly_rate+" "+ user.currency:"Negotiation"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.socialIconsProfile}>
                                    <div>
                                        <IconButton className={user.facebook?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <FacebookIcon onClick={()=> {this.handleClickGoToSocial(user.facebook,"facebook")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                        </IconButton>
                                    </div>
                                    <div>
                                        <IconButton className={user.instagram?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <InstagramIcon onClick={()=> {this.handleClickGoToSocial(user.instagram,"instagram")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                        </IconButton>
                                    </div>
                                    <div>
                                        <IconButton className={user.linkedin?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <LinkedInIcon onClick={()=> {this.handleClickGoToSocial(user.linkedin,"linkedin")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                        </IconButton>
                                    </div>
                                    <div>
                                        <IconButton className={user.telegram?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <TelegramIcon onClick={()=> {this.handleClickGoToSocial(user.telegram,"telegram")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                        </IconButton>
                                    </div>
                                    <div>
                                        <IconButton className={user.youtube?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <YouTubeIcon onClick={()=> {this.handleClickGoToSocial(user.youtube,"youtube")}} fontSize={window.innerWidth>1400?"medium":"small"} />
                                        </IconButton>
                                    </div>
                                    <div>
                                        <IconButton className={user.skype?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            {
                                                user.skype &&
                                                <img onClick={()=> {this.handleClickGoToSocial(user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype-active.png" />
                                            }
                                            {
                                                !user.skype &&
                                                <img onClick={()=> {this.handleClickGoToSocial(user.skype,"skype")}} className={classes.skypeIcon} src="/images/skype.png" />
                                            }
                                        </IconButton>
                                    </div>
                                    <div>
                                        <IconButton className={user.email?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <EmailRoundedIcon onClick={()=> {this.handleClickGoToSocial(user.email,"email")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                        </IconButton>
                                    </div>
                                    <div>
                                        <IconButton className={user.phone_number?classes.socialIconsColorProfileActive:classes.socialIconsColorProfile} aria-haspopup="true"  color="inherit">
                                            <PhoneRoundedIcon onClick={()=> {this.handleClickGoToSocial(user.phone_number,"phone")}} fontSize={window.innerWidth>1400?"medium":"small"}/>
                                        </IconButton>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className={classes.resumeDivCover}>

                            {
                                user.credit_date_day>0 &&
                                <div  className={classes.activeDivCover}>
                                    <h5>Your account will be active for another {user.credit_date_day} days</h5>
                                    <IconButton className={classes.socialIconsColorProfileActive} aria-haspopup="true"  color="inherit">
                                        <CheckCircleRoundedIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                            }

                            {
                                user.credit_date_day===0 &&
                                <div onClick={()=> {this.handleClickPayment()}} className={classes.disabledDivCover}>
                                    <IconButton className={classes.activeDiv} aria-haspopup="true"  color="inherit">
                                        <WarningRoundedIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                    <h6>Your account is disabled. please charge your account to stay active</h6>
                                    <IconButton className={classes.socialIconsColorProfileActive} aria-haspopup="true"  color="inherit">
                                        <ArrowForwardRoundedIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                                    </IconButton>
                                </div>
                            }

                           <div onClick={ ()=> {this.handleViewResume()}} className={classes.resumeDiv}>
                               <IconButton  className={classes.socialIconsColorProfileActive} aria-haspopup="true"  color="inherit">
                                   <EventNoteIcon fontSize={window.innerWidth>1400?"medium":"small"} />
                               </IconButton>
                               <h4>View resume</h4>
                           </div>

                        </div>

                        <div className={classes.photosTitleProfile}>
                            <p>
                                Photos
                            </p>
                        </div>

                        <div className={classes.othersPhotosDivProfile}>
                            {
                                user.photos &&
                                <div style={{"justifyContent": "flex-start", "width": "fit-content","margin":"auto"}}  className={classes.othersPhotosDivProfile}>

                                    {
                                        user.photos && user.photos.split(";").map((photo , index) => {
                                            return (
                                                <div key={index} onClick={()=> {this.handleClickOpen(baseUrl+photo,photo,index)}} className={classes.othersPhotosProfile} style={{backgroundImage:`url('${baseUrl+photo}')`}}></div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                !user.photos &&
                                <div><h3>No Photos</h3></div>
                            }
                        </div>

                    </div>
                }


                <div>
                    <Dialog classes={{root:classes.rootDialog}} fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                        <AppBar className={classes.appBarDialog}>
                            <Toolbar className={classes.toolBarDiv}>
                                <div className={classes.deleteDiv}>
                                    <Button
                                        onClick={()=> {this.handleDeleteCastBtn()}}
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<DeleteIcon />}
                                    >
                                        Delete
                                    </Button>
                                </div>
                                <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>

                        </AppBar>

                        <div className={classes.coverPhotoDialog}>

                            <TransformWrapper
                                defaultScale={1}
                                positionX={60}
                                positionY={80}
                            >
                                <TransformComponent>
                                    <div  className={classes.dialogPhotoProfile} style={{backgroundImage:`url('${dialogPhotoSrc}')`}}></div>
                                </TransformComponent>
                            </TransformWrapper>

                        </div>
                    </Dialog>
                </div>


                <div>
                    <Dialog classes={{root:classes.rootDialog}} fullScreen open={openPhoto} onClose={this.handleClosePhoto} TransitionComponent={Transition} >
                        <AppBar className={classes.appBarDialog}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={this.handleClosePhoto} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>

                        <div className={classes.coverUploadPhotoDialog}>
                            <div className="divFile">
                                <input type="file" accept="image/*" onChange={this.onSelectFile} />
                            </div>
                            <div className="App">

                                {src && (
                                    <ReactCrop
                                        src={src}
                                        crop={crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                )}
                                {croppedImageUrl && (
                                    <img alt="Crop" className="imgCropMee" src={croppedImageUrl} />
                                )}

                            </div>
                            {croppedImageUrl && (
                                <div>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        className={classes.buttonUpload}
                                        startIcon={<CloudUploadIcon fontSize={window.innerWidth>1400?"medium":"medium"} />}
                                        onClick={()=> {this.handleClickAddPhotos()}}
                                    >
                                        Upload
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Dialog>
                </div>

                <Dialog
                    open={openA}
                    onClose={this.handleCloseAboutMe}
                    aria-labelledby="alert-dialog-titleD"
                    aria-describedby="alert-dialog-description"
                    className={classes.fontEn}
                >
                    <DialogTitle id="alert-dialog-titleD">{"About me"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.fontEn} id="alert-dialog-description">
                            {about_meD}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>


        );
    }
}

ProfileContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{deletePhotoUser,showUserProfile,changeLoadingStatus,addPhotoUser})(ProfileContainer));