import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import {changeLoadingStatus, updateProfile} from "../actions";
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import './../styles/css/main.css';

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "11px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "10px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "13px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "15px",
        }
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    firstDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    firstRightDivEditProfile:{
        "marginRight": "5%",
        "color": "#00ebff"
    },
    fourthDivTitleEditConnection:{
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "marginBottom": "10%",
        "marginTop": "-10%",
        "& h3":{
            "marginBottom": "0",
            "color": "#fffbf0"
        },
    },
    secondDivCoverEditConnection:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
        "position": "relative",
    },
    secondDivInputEditConnection:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "30px",
            "paddingLeft": "27%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "30px",
            "paddingLeft": "27%",
        },[theme.breakpoints.up('md')]: {
            "height": "30px",
            "paddingLeft": "27%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "35px",
            "paddingLeft": "24%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "23%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
            "paddingLeft": "20%",
        }
    },
    secondDivEditConnection:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondDivInputPlaceEditConnection:{
        "position": "absolute",
        "top": "0",
        "left": "4%",
        "margin": "2% 1%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "margin": "3% 1%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "margin": "2% 1%",
        },[theme.breakpoints.up('md')]: {
            "margin": "3% 1%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "margin": "3% 1%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "margin": "2% 1%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "margin": "2% 1%",
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    jkjl:{},
});


class EditConnectionComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            linkedin:null,
            facebook:null,
            instagram:null,
            telegram:null,
            youtube:null,
            skype:null,
            contact_email:null,
            contact_phone:null,
        };
    }

    componentDidMount(){
        const {linkedin,facebook,instagram,youtube,skype,telegram,contact_email,contact_phone} =this.props.user;
        this.setState({
            linkedin: linkedin,
            facebook:facebook,
            instagram:instagram,
            telegram:telegram,
            youtube:youtube,
            skype:skype,
            contact_email:contact_email,
            contact_phone:contact_phone,
        })
    }

    handleGoBackSetting(){
        this.props.history.push(`/setting`)
    }

    handleChangeInputs = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        })
    };

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleSaveSetting(){
        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.updateProfile(this.state,user.id,auth.apiToken, this.props.history);
    }

    render() {
        const {  classes,user,loading  } = this.props;
        const {youtube,telegram,instagram,facebook,linkedin,skype,contact_email,contact_phone} = this.state;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                   <div className={classes.firstDivEditProfile}>
                       <di onClick={ ()=> {this.handleGoBackSetting()}} className={classes.firstLeftDivEditProfile}>
                           <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                               <ArrowBackIosIcon fontSize="small" />
                           </IconButton>
                       </di>

                       <div className={classes.firstRightDivEditProfile}>
                           <h5 onClick={ ()=> {this.handleSaveSetting()}}>Save</h5>
                       </div>

                   </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.fourthDivEditProfile}>

                            <div className={classes.fourthDivTitleEditConnection}>
                                <h3>please inter your social accounts</h3>
                            </div>

                        </div>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={facebook} name="facebook" className={classes.secondDivInputEditConnection}  type="text" placeholder="@example" />
                            <h4 className={classes.secondDivInputPlaceEditConnection}>Facebook :</h4>
                        </div>

                    </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={instagram} name="instagram" className={classes.secondDivInputEditConnection}  type="text" placeholder="@example" />
                            <h4 className={classes.secondDivInputPlaceEditConnection}>Instagram :</h4>
                        </div>

                    </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={youtube} name="youtube" className={classes.secondDivInputEditConnection}  type="text" placeholder="@example" />
                            <h4 className={classes.secondDivInputPlaceEditConnection}>Youtube :</h4>
                        </div>

                    </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={linkedin} name="linkedin" className={classes.secondDivInputEditConnection}  type="text" placeholder="profile url" />
                            <h4 className={classes.secondDivInputPlaceEditConnection}>Linkedin :</h4>
                        </div>

                    </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={skype} name="skype" className={classes.secondDivInputEditConnection}  type="text" placeholder="skype name" />
                            <h4 className={classes.secondDivInputPlaceEditConnection}>Skype :</h4>
                        </div>

                    </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={telegram} name="telegram" className={classes.secondDivInputEditConnection}  type="text" placeholder="@example" />
                            <h4 className={classes.secondDivInputPlaceEditConnection}>Telegram :</h4>
                        </div>

                    </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={contact_phone} name="contact_phone" style={{"paddingLeft": "39%"}} className={classes.secondDivInputEditConnection}  type="text" placeholder="+15********" />
                            <h4 className={classes.secondDivInputPlaceEditConnection}>Contact Phone :</h4>
                        </div>

                    </div>


                    {
                        this.props.user.phone_number &&
                        <div className={classes.secondDivEditConnection}>

                            <div className={classes.secondDivCoverEditConnection}>
                                <input disabled  value={this.props.user.phone_number} name="phone_number" style={{"paddingLeft": "40%"}} className={classes.secondDivInputEditConnection}  type="text" />
                                <h4 className={classes.secondDivInputPlaceEditConnection}>Phone Number :</h4>
                            </div>

                        </div>
                    }

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={contact_email} name="contact_email" style={{"paddingLeft": "38%"}} className={classes.secondDivInputEditConnection}  type="text" placeholder="example@gmail.com" />
                            <h4 className={classes.secondDivInputPlaceEditConnection}>Contact Email :</h4>
                        </div>

                    </div>


                    {
                        this.props.user.email &&
                        <div className={classes.secondDivEditConnection}>

                            <div className={classes.secondDivCoverEditConnection}>
                                <input disabled  value={this.props.user.email} name="email" className={classes.secondDivInputEditConnection}  type="text" />
                                <h4 className={classes.secondDivInputPlaceEditConnection}>Email :</h4>
                            </div>

                        </div>
                    }

                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>

        );
    }
}

EditConnectionComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{updateProfile,changeLoadingStatus})(EditConnectionComponent));