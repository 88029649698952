import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import {changeLoadingStatus, updateProfile} from "../actions";
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import {baseUrlAPI} from "../constants/constant";
import axios from "axios/index";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "11px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "10px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "13px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "15px",
        }
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    firstDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },
    firstRightDivEditProfile:{
        "marginRight": "5%",
        "color": "#00ebff"
    },
    secondDivEditConnection:{
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "90%",
        },
    },
    fourthDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "width": "91%"
    },
    fourthDivTitleEditConnection:{
        "width": "100%",
        "border": "1px solid #333",
        "margin": "2% 0",
        "display": "flex",
        "boxShadow": "0 2px 5px 0px black",
        "alignItems": "flex-start",
        "borderRadius": "5px",
        "justifyContent": "space-between",
        "flexDirection": "column",
        "backgroundColor": "#2b2b2b",
        "& h4":{
            "marginLeft": "3%",
            "color": "#999"
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    coverTitle:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "95%",
        "paddingLeft": "3%",
        "& h3":{
            color: "#f5a339",
        },
        "& h4":{
            color: "white",
        }
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    noNotif:{
        "display": "flex",
        "justifyContent": "center",
        "width": "100%"
    },
});


class MessageComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            messages:[]
        };
    }


    componentDidMount(){
        this.props.changeLoadingStatus(true);
        const { user,auth} = this.props;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.apiToken}`
        }
        axios.get(`${baseUrlAPI}/user/${user.id}/messages`,{headers})
            .then(response => {
                if(response.data.status==="success") {
                    this.setState({
                        messages:response.data.data,
                    })

                }else {
                }
                this.props.changeLoadingStatus(false);
            })
            .catch(error => {
                this.props.changeLoadingStatus(true);
            })
    }

    handleGoBackSetting(){
        this.props.history.push(`/`)
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    render() {
        const {  classes,loading  } = this.props;
        const {messages} = this.state;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                   <div className={classes.firstDivEditProfile}>
                       <di onClick={ ()=> {this.handleGoBackSetting()}} className={classes.firstLeftDivEditProfile}>
                           <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                               <ArrowBackIosIcon fontSize="small" />
                           </IconButton>
                       </di>

                   </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.fourthDivEditProfile}>


                            {
                                messages && messages.length>0 && messages.map( (message,index)=>{
                                    return (
                                        <div key={index} className={classes.fourthDivTitleEditConnection}>
                                            <div className={classes.coverTitle}>
                                                <h3>{message.title}</h3>
                                                <h4>{message.created_at}</h4>
                                            </div>
                                            <h4>{message.message}</h4>
                                        </div>
                                    )
                                } )
                            }

                            {
                                messages && messages.length===0 &&
                                <div className={classes.noNotif}><h3>No Notification</h3></div>
                            }


                        </div>

                    </div>



                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>

        );
    }
}

MessageComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{changeLoadingStatus})(MessageComponent));