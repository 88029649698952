import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import CardComponent from "../components/CardComponent";
import {changeBottomNavStatus, changeLoadingStatusRed, getUsers, initUsers, initUsersToTemp} from "../actions";
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios/index";
import {baseUrlAPI} from "../constants/constant";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
    },
    mainDiv:{
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "space-evenly",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "width": "100%",
        [theme.breakpoints.up('md')]: {
            "width": "80%",
        },
    },
    mainDivInfinit:{
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "flex-start",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "width": "100%",
        [theme.breakpoints.up('md')]: {
            "width": "80%",
        },
    },
    coverSpinner:{
        "width": "100%",
        "display": "flex",
        "justifyContent": "center"
    },
    newPosts:{
        "position": "fixed",
        "backgroundColor": "white",
        "color": "black",
        "borderRadius": "30px",
        "padding": "0px 20px",
        "top": "12%"
    }
});


class HomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {

        };
        this.componentCleanup = this.componentCleanup.bind(this);
    }

    componentWillMount(){
        if (this.props.users.nextPage===1){
            this.props.getUsers(1,this.props.auth.apiToken,this.props.history,null,false)
        }
        this.props.changeBottomNavStatus(0)

    }

    componentDidMount(){
        window.addEventListener('beforeunload', this.componentCleanup);
        setInterval(() =>{
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.props.auth.apiToken}`
                }
                const  postData = {};
                if(true){
                    postData.group_id=null;
                    postData.subgroup_id=null;
                    postData.country_id=null;
                    postData.city_id=null;
                    postData.age=null;
                    postData.gender=null;
                    postData.optional=null;
                }

                axios.post(`${baseUrlAPI}/user/custom/search?page=1`,postData,{headers})
                    .then(response => {
                        if(response.data.status==="success") {
                            let newPosts= document.getElementById(`newPosts`);
                            if(response.data.data[0].total===this.props.users.total){
                                newPosts.style.display= "none"
                            }else{
                                newPosts.style.display= "block"
                            }
                        }else {

                        }
                    })
                    .catch(error => {

                    })


            }
            , 5000);
    }

    componentWillUnmount(){
        // this.props.initUsersToTemp(this.props.users.data,this.props.users.nextPage,this.props.users.hasMore)
        // this.props.initUsers()
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup() {
        this.props.initUsers()
    }

    handleClickReload = () => {
       window.location.reload();
    };


    render() {
        const { classes,users } = this.props;

        return (
            <div className={classes.cover}>
                <div id="newPosts" style={{display:"none"}} className={classes.newPosts}>
                    <p onClick={()=> {this.handleClickReload()}}>
                        New Posts
                    </p>
                </div>
                <div  className={classes.mainDiv}>

                    <InfiniteScroll
                        className={classes.mainDivInfinit}
                        pageStart={0}
                        loadMore={()=> { users.data && users.data.length !== 0 && this.props.getUsers(users.nextPage,this.props.auth.apiToken,this.props.history,null,false);}}
                        hasMore={users.hasMore}
                        loader={<div className={classes.coverSpinner} ><CircularProgress style={{color:"#00EBFF"}} /></div>}
                    >
                        {  users.data && users.data.map((user , index) => {
                            return <CardComponent user={user} key={index} history={this.props.history} />
                            })
                        }
                    </InfiniteScroll>

                </div>

            </div>

        );
    }
}

HomeContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        users : state.users,
        tempUsers : state.tempUsers,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{getUsers,initUsers,initUsersToTemp,changeBottomNavStatus})(HomeContainer));