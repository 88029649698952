import * as types from "../constants/ActionTypes";


const initialState = {
    value : 0,
    valueTop : 0,
}


const bottomNav = (state = initialState , action) => {
  switch (action.type) {
          case types.BOTTOM_NAV_STATUS:
          return {
              ...state,
              value :action.value!==null?action.value:state.value,
              valueTop :action.valueTop!==null?action.valueTop:state.valueTop,
          }
      default:
          return state;
  }
}


export default bottomNav;
