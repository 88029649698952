import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Slider from "@material-ui/core/es/Slider/Slider";
import {changeLoadingStatus, updateProfile} from "../actions";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "12px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "11px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "11px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "12px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "17px",
        }
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    firstDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
    },
    metricDivEditProfile:{
        "display": "flex",
        "justifyContent": "flex-start",
        "alignItems": "center",
        "width": "100%",
        "& p":{
            "marginRight": "1%",
        }
    },
    firstRightDivEditProfile:{
        "marginRight": "5%",
        "color": "#00ebff"
    },
    thirdDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column"
    },
    thirdDivCoverEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
    },
    thirdDivInputRangeEditProfile:{
        width: "81%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "2px auto 20px",
    },
    thirdDivRangeEditProfile: {
        color: "#ff8630",
        width: "100%",
        margin: "-12px auto",
    },
    fourthDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "85%"
    },
    fourthDivTitleEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "& h3":{
            "marginBottom": "0",
            "color": "#fffbf0"
        },
    },
    fourthDivCaptionEditProfile:{
        "width": "93%",
        "& p":{
            "color": "#A7A7A7",
            "textAlign": "center",
        },
    },
    fourthDivInputCoverEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    fourthDivInputEditProfile:{
        "width": "100%",
        "height": "150px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "2%"
    },
    fourthDivCharEditProfile:{
        width: "81%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "2px auto 20px",
    },
    fourthDivCharTitleEditProfile:{
        "& p":{
            "whiteSpace": "nowrap"
        }
    },
    fourthDivCharItemCoverEditProfile:{
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "flex-start",
        "flexWrap": "wrap"
    },
    fourthDivCharItemEditProfile:{
        "border": "1px solid #00EBFF",
        "height": "25px",
        "width": "fit-content",
        "padding": "1% 5%",
        "borderRadius": "5px",
        "color": "#00EBFF",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2%"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    fourthDivCharSearch:{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "10px auto 20px",
        "& div":{
            "& p":{
                "color": "white",
                "paddingLeft": "4%"
            },
        },
    },
    fourthDivCharItemCoverSearch:{
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "start",
        "flexWrap": "wrap",
        [theme.breakpoints.up('xs')]: { //Iphone
            fontSize: 11,
        },[theme.breakpoints.up('sm')]: {//Iphone +
            fontSize: 12,
        },
        [theme.breakpoints.up('lg')]: {//Tab
            fontSize: 12,
        },
        [theme.breakpoints.up('xl')]: {//lap
            fontSize: 15,
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            fontSize: 16,
        }
    },
    fourthDivCharItemSearchActive:{
        "border": "1px solid #00EBFF",
        "height": "25px",
        "minWidth": "12%",
        "padding": "1% 5%",
        "borderRadius": "5px",
        "color": "#05080b",
        "backgroundColor": "#00EBFF",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2%",
        width: "fit-content",
        "flexWrap": "nowrap"
    },
    fourthDivCharItemSearch:{
        "border": "1px solid #00EBFF",
        "height": "25px",
        "minWidth": "12%",
        "padding": "1% 5%",
        "borderRadius": "5px",
        "color": "#00EBFF",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2%",
        width: "fit-content",
        "flexWrap": "nowrap"
    },
    currencyDivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    currencyCoverDivEditRate:{
        "display": "flex",
        "justifyContent": "space-around",
        "alignItems": "center",
        "width": "70%",
        "border": "1px solid lightgray",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "padding": "2%",
        "boxShadow": "0 1px 3px 1px black"
    },
    currencyItem1DivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "43%",
        "border": "1px solid white",
        "height": "32px",
        "borderRadius": "5px",
        "backgroundColor": "white",
        "color": "#222222",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "32px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "40px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "33px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "38px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
        }
    },
    currencyItem2DivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "43%",
        "border": "1px solid #222222",
        "height": "32px",
        "borderRadius": "5px",
        "backgroundColor": "#222222",
        "color": "white",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "32px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "40px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "33px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "38px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
        }
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    jkjl:{},
});


class EditMeasurementComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            waist:25,
            jacket:25,
            neck:25,
            weight:25,
            height:25,
            metric:"cm/kg",
            optionals:"",
            optionalsId:[],
            userOptionalsId:[],
            optionalsType:[],
        };
        this.handelRangeHeight = this.handelRangeHeight.bind(this);
        this.handelRangeWeight = this.handelRangeWeight.bind(this);
        this.handelRangeNeck = this.handelRangeNeck.bind(this);
        this.handelRangeJacket = this.handelRangeJacket.bind(this);
        this.handelRangeWaist = this.handelRangeWaist.bind(this);
    }

    componentDidMount(){
        const {waist,jacket,neck,weight,height,metric} =this.props.user;
        this.setState({
            waist:waist===null?10:waist,
            jacket:jacket===null?10:jacket,
            neck:neck===null?10:neck,
            weight:weight===null?40:weight,
            height:height===null?100:height,
            metric:metric===null?"cm/kg":metric,
        })

        if(metric==="in/lb"){
            this.handleClickMetric(2,1)
        }
        if(metric==="cm/kg"){
            this.handleClickMetric(1,2)
        }

        let selectedType= this.props.appInitData.groups.filter( (group)=> {
            if(group.title===this.props.user.group.title){
                return group
            }
        } )
        let optionalsType=[];
        selectedType[0].others && selectedType[0].others.map( (optional)=> {
            if(!optionalsType.includes(optional.type)){
                optionalsType.push(optional.type)
            }
        } )
        let userOptionalsId=[];
        this.props.user.optionals && this.props.user.optionals.map( (optional)=>{
            userOptionalsId.push(optional.id)
        } )
        this.setState({
            optionals:selectedType[0].others,
            optionalsType:optionalsType,
            userOptionalsId:userOptionalsId,
        })
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleGoBackSetting(){
        this.props.history.push(`/setting`)
    }

    handelRangeHeight(e,value){
        this.setState({
            height:value
        })
        let ele= document.getElementById(`heightP`);
        ele.innerText="Height : " +this.state.height
    }

    handelRangeWeight(e,value){
        this.setState({
            weight:value===0?"Not set":value
        })
        let ele= document.getElementById(`weightP`);
        ele.innerText="Weight : " +this.state.weight
    }

    handelRangeNeck(e,value){
        this.setState({
            neck:value
        })
        let ele= document.getElementById(`neckP`);
        ele.innerText="Neck : " +this.state.neck
    }

    handelRangeJacket(e,value){
        this.setState({
            jacket:value
        })
        let ele= document.getElementById(`jacketP`);
        ele.innerText="Jacket : " +this.state.jacket
    }

    handelRangeWaist(e,value){
        this.setState({
            waist:value
        })
        let ele= document.getElementById(`waistP`);
        ele.innerText="Waist : " +this.state.waist
    }

    handleClickChar = (IDs,count,optionalId) => {
        let optionalsIdsArray=this.state.userOptionalsId
        let finalOptionalsIdsArray=[];
        optionalsIdsArray.map( (item)=>{
            if(!IDs.includes(item)){
                finalOptionalsIdsArray.push(item)
            }
        } )
        if(!finalOptionalsIdsArray.includes(optionalId)){
            finalOptionalsIdsArray.push(optionalId)
        }
        this.setState({
            userOptionalsId:finalOptionalsIdsArray,
        })

        let eleA= document.getElementById(`${optionalId}charType`);
        eleA.style.backgroundColor= "#00EBFF"
        eleA.style.color= "#05080b"
        IDs.map( (id) => {
            if(optionalId!==id){
                let ele= document.getElementById(`${id}charType`);
                ele.style.backgroundColor= "#222"
                ele.style.color= "#00EBFF"
            }
        })
    }

    handleClickMetric = (idCur,idNext) => {
        let eleA= document.getElementById(`metric${idCur}`);
        let eleB= document.getElementById(`metric${idNext}`);
        eleA.style.backgroundColor= "white"
        eleA.style.color= "#222222"
        eleA.style.borderColor= "white"

        eleB.style.backgroundColor= "#222222"
        eleB.style.color= "white"
        eleB.style.borderColor= "#222222"
        this.setState({
            metric:idNext===2?"cm/kg":"in/lb"
        })
    }

    handleSaveSetting(){
        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.updateProfile(this.state,user.id,auth.apiToken, this.props.history);
    }

    render() {
        const { classes,user,loading } = this.props;
        const { height,weight,neck,jacket,waist,optionalsType,optionals,userOptionalsId,metric} = this.state;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                   <div className={classes.firstDivEditProfile}>
                       <di onClick={ ()=> {this.handleGoBackSetting()}} className={classes.firstLeftDivEditProfile}>
                           <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                               <ArrowBackIosIcon fontSize="small" />
                           </IconButton>
                       </di>

                       <div className={classes.firstRightDivEditProfile}>
                           <h5 onClick={ ()=> {this.handleSaveSetting()}}>Save</h5>
                       </div>

                   </div>

                    <div className={classes.thirdDivEditProfile}>


                        <div className={classes.fourthDivEditProfile}>

                            <div className={classes.fourthDivTitleEditProfile}>
                                <h3>Measurement</h3>
                            </div>

                            <div className={classes.fourthDivCaptionEditProfile}>
                                <p>
                                    Setting your measurements allows your profile to be found in search results.Do your best to be as accurate possible and true to yourself.
                                </p>

                            </div>

                        </div>

                        <div className={classes.fourthDivEditProfile}>

                            <div className={classes.currencyDivEditRate}>
                                <div className={classes.currencyCoverDivEditRate}>
                                    <div id="metric1" onClick={ ()=> {this.handleClickMetric(1,2)}} className={classes.currencyItem1DivEditRate}><h2>cm/kg</h2></div>
                                    <div id="metric2" onClick={ ()=> {this.handleClickMetric(2,1)}} className={classes.currencyItem2DivEditRate}><h2>in/lb</h2></div>
                                </div>
                            </div>

                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div className={classes.metricDivEditProfile}>
                                <p id="heightP">Height :{height}</p>
                                <p >{metric==="cm/kg"?"cm":"inch"}</p>
                            </div>
                            <Slider onChange={ this.handelRangeHeight } value={height===null?100:height} className={classes.thirdDivRangeEditProfile}   min={100} max={250} defaultValue={height===null || height==="Not set"?0:height}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div className={classes.metricDivEditProfile}>
                                <p id="weightP">Weight :{weight}</p>
                                <p >{metric==="cm/kg"?"Kg":"Lbs"}</p>
                            </div>
                            <Slider onChange={ this.handelRangeWeight} value={weight===null?40:weight} className={classes.thirdDivRangeEditProfile}   min={40} max={260} defaultValue={weight===null || weight==="Not set"?0:weight}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div className={classes.metricDivEditProfile}>
                                <p id="neckP">Neck :{neck}</p>
                                <p >{metric==="cm/kg"?"cm":"inch"}</p>
                            </div>
                            <Slider onChange={ this.handelRangeNeck } value={neck===null?10:neck} className={classes.thirdDivRangeEditProfile}   min={10} max={100} defaultValue={neck===null || neck==="Not set"?0:neck}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div className={classes.metricDivEditProfile}>
                                <p id="jacketP">Jacket :{jacket}</p>
                                <p >{metric==="cm/kg"?"cm":"inch"}</p>
                            </div>
                            <Slider onChange={ this.handelRangeJacket } value={jacket===null?10:jacket} className={classes.thirdDivRangeEditProfile}   min={10} max={100} defaultValue={jacket===null || jacket==="Not set"?0:jacket}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div className={classes.metricDivEditProfile}>
                                <p id="waistP">Waist :{waist}</p>
                                <p> {metric==="cm/kg"?"cm":"inch"}</p>
                            </div>
                            <Slider onChange={ this.handelRangeWaist } value={waist===null?10:waist} className={classes.thirdDivRangeEditProfile}   min={10} max={100} defaultValue={waist===null || waist==="Not set"?0:waist}  />
                        </div>

                        <div className={classes.fourthDivEditProfile}>

                            <div className={classes.fourthDivTitleEditProfile}>
                                <h3>Characteristics</h3>
                            </div>

                        </div>

                        <div className={classes.fourthDivEditProfile}>

                            {
                                optionals && optionalsType.length>0 && optionalsType.map( (type)=>{
                                    let count=0;
                                    let IDs=[];
                                    optionals.map( (optional)=>{  if(optional.type===type){count=count+1}   } )
                                    return (
                                        <div className={classes.fourthDivCharSearch}>
                                            <div className={classes.fourthDivCharTitleEditProfile}>
                                                <p >{type}</p>
                                            </div>
                                            <div className={classes.fourthDivCharItemCoverSearch}>

                                                {
                                                    optionals && optionals.map( (optional,index)=>{
                                                        if(optional.type===type){
                                                            IDs.push(optional.id);
                                                            return (
                                                                <div key={index} id={optional.id+"charType"} onClick={ ()=> {this.handleClickChar(IDs,count,optional.id)}} className={userOptionalsId.includes(optional.id)?classes.fourthDivCharItemSearchActive:classes.fourthDivCharItemSearch}>{optional.title}</div>
                                                            )
                                                        }
                                                    } )
                                                }

                                            </div>
                                        </div>
                                    )


                                } )

                            }

                            {
                                optionalsType.length===0 &&
                                <h6>There is no optionals</h6>
                            }
                        </div>

                    </div>

                </div>
                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>
            </div>

        );
    }
}

EditMeasurementComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{updateProfile,changeLoadingStatus})(EditMeasurementComponent));