import * as types from "../constants/ActionTypes";


const initialState = {
    settings : null,
    countries : null,
    groups : null,
    castBookmarks : null,
    userBookmarks : null,
    castApplies: null,
}


const appInitData = (state = initialState , action) => {
  switch (action.type) {
      case types.RECEIVE_INIT:
          return {
              ...state,
              groups :action.data.groups,
              countries :action.data.countries,
              settings :action.data.settings,
              castBookmarks :action.data.cast_favorites_id,
              userBookmarks :action.data.user_favorites_id,
              castApplies :action.data.cast_applies_id,
          }
      case types.ADD_BOOKMARKS_CAST:
          return {
              ...state,
              castBookmarks :action.bookmarks,
          }
      case types.ADD_BOOKMARKS_USER:
          return {
              ...state,
              userBookmarks :action.userBookmarks,
          }
      case types.APPLY_FOR_CAST:
          return {
              ...state,
              castApplies :action.appliesId,
          }
      default:
          return state;
  }
}


export default appInitData;
