import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import {
    changeLoadingStatus, changeLoadingStatusRed, changeSnackStatus, updatePraivacy, updatePrivacy,
    updateProfile
} from "../actions";
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import './../styles/css/main.css';
import Button from "@material-ui/core/es/Button/Button";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import TextField from "@material-ui/core/es/TextField/TextField";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import {baseUrlAPI} from "../constants/constant";
import axios from "axios/index";

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "11px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "13px",
        },[theme.breakpoints.up('md')]: {
            "fontSize": "10px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "fontSize": "10px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "fontSize": "13px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "fontSize": "15px",
        }
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    firstDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    firstRightDivEditProfile:{
        "marginRight": "5%",
        "color": "#00ebff"
    },
    fourthDivTitleEditConnection:{
        "width": "63%",
        "display": "flex",
        "marginTop": "-5%",
        "alignItems": "center",
        "marginBottom": "3%",
        "justifyContent": "center",
        "textAlign": "center",
        "& h4":{
            "marginBottom": "0",
            "color": "#fffbf0"
        },
    },
    secondDivCoverEditConnection:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
        "position": "relative",
    },
    secondDivInputEditConnection:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "30px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "30px",
        },[theme.breakpoints.up('md')]: {
            "height": "30px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "35px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "36px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "45px",
        }
    },
    secondDivEditConnection:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondDivInputPlaceEditConnection:{
        "top": "0px",
        "right": "-1%",
        "position": "absolute",
        "backgroundColor": "#05cbdc",
        "height": "32px",
        "padding": "0 5px",
        "borderTopRightRadius": "5px",
        "borderBottomRightRadius": "5px",
        "display": "flex",
        "alignItems": "center",
        "margin": "0",
        "color": "#333333",
        [theme.breakpoints.up('xs')]: { //Iphone

        },[theme.breakpoints.up('sm')]: {//Iphone +

        },[theme.breakpoints.up('md')]: {

        },
        [theme.breakpoints.up('lg')]: {//Tab

        },
        [theme.breakpoints.up('xl')]: {//lap

        },
        [theme.breakpoints.up('xxl')]: {//Llap

        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    updateButton: {
        "backgroundColor": "#00ebff",
        "color": "#222",
        "fontFamily": "En",
        "width": "25%",
        "marginLeft": "5px",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "30px",
            "fontSize": "11px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "30px",
        },[theme.breakpoints.up('md')]: {
            "height": "30px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "35px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "36px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "45px",
        }
    },
    labelEditPrivacy:{
        "display": "flex",
        "justifyContent": "left",
        "width": "84%",
        "& h4":{
            "marginBottom": "0",

        },
    },
});


class EditPrivacyComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            email:null,
            phone_number:null,
            code:null,
            codeId:null,
            open:false,
            dialogLabel:"Phone Number",
            type:"phone",
        };
    }

    componentDidMount(){
        const {phone_number,email} =this.props.user;
        this.setState({
            email:email,
            phone_number:phone_number,
        })
    }

    handleGoBackSetting(){
        this.props.history.push(`/setting`)
    }

    handleChangeInputs = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        })
    };

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleClickUpdate(type){
        if( type==="email" && this.state.email===this.props.user.email){
            this.props.changeSnackStatus(true,"warning",`please enter another email  .`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }else if( type==="phone_number" && this.state.phone_number===this.props.user.phone_number){
            this.props.changeSnackStatus(true,"warning",`please enter another phone number  .`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        } else{
            this.setState({
                type:type,
            })
            let userPhoneNumber=this.state.phone_number
            let userEmail=this.state.email
            let loginType=type
            if(userPhoneNumber!==null && userPhoneNumber.length>10 && loginType==="phone_number"){
                this.props.changeLoadingStatus(true)
                this.sendPhoneNumberPrivacy(userPhoneNumber,userEmail,loginType,this.props.apiToken)
            }else if(userEmail!==null && loginType==="email"){
                this.props.changeLoadingStatus(true)
                this.sendPhoneNumberPrivacy(userPhoneNumber,userEmail,loginType,this.props.apiToken)
            }else if(userPhoneNumber!==null && loginType!=="phone_number" && (userPhoneNumber.length<10)){
                this.props.changeSnackStatus(true,"warning",`please enter your phone number correctly.`,"center","bottom")
                setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
            }else if(userPhoneNumber===null && loginType==="phone_number"){
                this.props.changeSnackStatus(true,"warning",`please enter your phone number.`,"center","bottom")
                setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
            }else if(userEmail===null && loginType==="email"){
                this.props.changeSnackStatus(true,"warning",`please enter your email.`,"center","bottom")
                setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
            }
        }


    }

    handleClose = () => {
        this.setState({
            open:false
        })
    };

    handleClickSend = () => {
        if( this.state.codeId===null && this.state.code===null){
            this.props.changeSnackStatus(true,"warning",`please enter verification code.`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }else{
            this.props.changeLoadingStatus(true)
            this.setState({
                open:false,
            })
            let userPhoneNumber=this.state.phone_number
            let userEmail=this.state.email
            let loginType=this.state.type
            let codeId=this.state.codeId
            let code=this.state.code
            this.props.updatePrivacy(userPhoneNumber,codeId,userEmail,loginType,code,this.props.apiToken)
            this.setState({
                code:null,
                codeId:null
            })
        }

    };

    sendPhoneNumberPrivacy(phoneNumber,userEmail,type,apiToken){
        let data = new FormData();
        data.append('phone_number' , phoneNumber);
        data.append('email' , userEmail);
        data.append('type' , type);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiToken}`
        }

        axios.post(`${baseUrlAPI}/user/custom/update/send/code` , data,{headers})
            .then(response => {
                console.log(response.data)
                if(response.data.status==="success") {
                    this.props.changeLoadingStatus(false)
                    if(response.data.data.verification_code.id==="duplicate"){
                        this.setState({
                            open:true,
                            code:null,
                        })
                        this.props.changeSnackStatus(true,"success",`Verification code has already been sent to ${type==="phone_number"?phoneNumber:userEmail}. Valid for 5 minutes.`,"center","bottom")
                        setTimeout(function(){ this.props.changeSnackStatus(false,"success",'',"center","bottom")}.bind(this), 7000);
                    }else{
                        this.setState({
                            open:true,
                            code:null,
                            codeId:response.data.data.verification_code.id
                        })
                        this.props.changeSnackStatus(true,"success",`please send code sent to ${type==="phone_number"?phoneNumber:userEmail}. Valid for 5 minutes.`,"center","bottom")
                        setTimeout(function(){ this.props.changeSnackStatus(false,"success",'',"center","bottom")}.bind(this), 7000);
                    }
                }else {
                    this.props.changeLoadingStatus(false)
                    this.props.changeSnackStatus(true,"error",response.data.message,"center","bottom")
                    setTimeout(function(){ this.props.changeSnackStatus(false,"success",'',"center","bottom")}.bind(this), 5000);
                }
            })
            .catch(error => {
                this.props.changeLoadingStatus(false)
                this.props.changeSnackStatus(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom")
                setTimeout(function(){ this.props.changeSnackStatus(false,"success",'',"center","bottom")}.bind(this), 5000);
            })
    }






    render() {
        const {  classes,user,loading  } = this.props;
        const {phone_number,email,open,dialogLabel,type} = this.state;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>

                   <div className={classes.firstDivEditProfile}>
                       <di onClick={ ()=> {this.handleGoBackSetting()}} className={classes.firstLeftDivEditProfile}>
                           <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                               <ArrowBackIosIcon fontSize="small" />
                           </IconButton>
                       </di>
                   </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.fourthDivTitleEditConnection}>
                            <h4>Provide your personal information. This won'tbe part of your public profile.</h4>
                        </div>
                    </div>


                    <div className={classes.labelEditPrivacy}>
                        <h4>Phone with country code :</h4>
                    </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={phone_number} name="phone_number" className={classes.secondDivInputEditConnection}  type="text" placeholder="+151********" />
                            <Button  onClick={ ()=> {this.handleClickUpdate("phone_number")}} variant="contained"  className={classes.updateButton} color="default" component="span">
                                Update
                            </Button>
                        </div>

                    </div>


                    <div className={classes.labelEditPrivacy}>
                        <h4>Email :</h4>
                    </div>

                    <div className={classes.secondDivEditConnection}>

                        <div className={classes.secondDivCoverEditConnection}>
                            <input  onChange={this.handleChangeInputs} value={email} name="email" className={classes.secondDivInputEditConnection}  type="text" placeholder="example@gmail.com" />
                            <Button  onClick={ ()=> {this.handleClickUpdate("email")}} variant="contained"  className={classes.updateButton} color="default" component="span">
                                Update
                            </Button>
                        </div>

                    </div>

                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>


                <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose}>
                    <DialogTitle>Verification code</DialogTitle>
                    <DialogContent>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>

                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="code"
                                    name="code"
                                    label="Enter Code"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleChangeInputs}
                                />
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleClickSend} color="primary">
                            Send
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>

        );
    }
}

EditPrivacyComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{changeSnackStatus,updateProfile,changeLoadingStatus,updatePrivacy})(EditPrivacyComponent));