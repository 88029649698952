import * as types from "../constants/ActionTypes";
import users from "./users";


const initialState = {
    data : [],
    nextPage : 1,
    hasMore : true
}


const casts = (state = initialState , action) => {
  switch (action.type) {
      case types.RECEIVE_CASTS:
          const { data,casts} = action;
          return {
              ...state,
              data : state.data.concat(casts) ,
              nextPage:data.current_page + 1,
              hasMore: data.current_page !== data.last_page ,
          }
      case types.INIT_TEMP_TO_CASTS:
          const { tempData,nextPage,hasMore} = action;
          console.log("yyyyyyyyyyy",tempData)
          return {
              ...state,
              data : tempData ,
              nextPage:nextPage,
              hasMore: hasMore,
          }
      case types.INIT_CASTS:
          return {
              ...state,
              data : [] ,
              nextPage:1,
              hasMore: true,
          }
      default:
          return state;
  }
}


export default casts;
