import * as types from "../constants/ActionTypes";


const initialState = {
    data : [],
    nextPage : 1,
    hasMore : true
}


const tempUsers = (state = initialState , action) => {
  switch (action.type) {
      case types.INIT_USERS_TO_TEMP:
          const { userData,nextPage,hasMore} = action;
          return {
              ...state,
              data : userData ,
              nextPage:nextPage,
              hasMore: hasMore ,
          }
      default:
          return state;
  }
}


export default tempUsers;
