import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from "./App";
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react';
import { createMuiTheme } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { jssPreset } from "@material-ui/core/styles";
import WebFont from 'webfontloader';
import * as serviceWorker from './serviceWorker';
import store from "./constants/store";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from "./components/sections/ScrollToTop";

WebFont.load({
    google: {
        families: ['En']
    }});



const persistorI = persistStore(store)

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const theme = createMuiTheme({
    typography: { useNextVariants: true },
    direction: "ltr",
    breakpoints: {
        values: {
            xs: 0,
            sm: 380,
            md: 450,
            lg: 760,
            xl: 1020,
            xxl: 1440,
        },
    },
});

render(
    <Provider store={ store }>
        <PersistGate loading={null} persistor={persistorI}>
            <BrowserRouter basename="/">
                <StylesProvider jss={jss}>
                    <MuiThemeProvider  theme={theme}>
                        <ScrollToTop>
                            <App />
                        </ScrollToTop>
                    </MuiThemeProvider >
                </StylesProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)
serviceWorker.unregister();
