import * as types from "../constants/ActionTypes";
import {baseUrl, baseUrlAPI} from "../constants/constant";
import store from "./../constants/store";
import axios from "axios/index";







/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/***********************************************ALERT-STATUS******************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/




export const  changeAlertStatus =  (open,title,message,id,actionType) => dispatch => {
    dispatch(changeAlertStatusRed(open,title,message,id,actionType))

}

const changeAlertStatusRed = (open,title,message,id,actionType) => ({
    type : types.CHANGE_ALERT_PROPS,
    itemId:id,
    title,
    open,
    message,
    actionType
});





/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/***********************************************BottomNav******************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/




export const  changeBottomNavStatus =  (value,valueTop) => dispatch => {
    dispatch(changeBottomNavStatusRed(value,valueTop))

}

const changeBottomNavStatusRed = (value,valueTop) => ({
    type : types.BOTTOM_NAV_STATUS,
    value,
    valueTop,
});




/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/***********************************************Snack******************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/




export const  changeSnackStatus =  (open,variant,message,horizontal,vertical) => dispatch => {
    dispatch(changeSnackStatusRed(open,variant,message,horizontal,vertical))

}

const changeSnackStatusRed = (open,variant,message,horizontal,vertical) => ({
    type : types.CHANGE_SNACK_PROPS,
    open,
    variant,
    message,horizontal,vertical
});





/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/***********************************************Loading******************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/




export const  changeLoadingStatus =  (status) => dispatch => {
    dispatch(changeLoadingStatusRed(status))

}

export const changeLoadingStatusRed = (status) => ({
    type : types.CHANGE_LOADING_PROPS,
    status:status
});





/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/***********************************************LOGIN******************************************/
/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/



export const sendPhoneNumber = (phoneNumber,userEmail,loginType,step) => dispatch => {

    let data = new FormData();
    data.append('phone_number' , phoneNumber);
    data.append('email' , userEmail);
    data.append('login_type' , loginType);

    axios.post(`${baseUrlAPI}/send` , data)
        .then(response => {
            if(response.data.status==="success") {
                dispatch(sendPhoneNumberRed(response.data.data,step))
                dispatch(changeLoadingStatusRed(false))
                if(response.data.data.verification_code.id==="duplicate"){
                    dispatch(changeSnackStatusRed(true,"success",`Verification code has already been sent to ${loginType==="phone_number"?phoneNumber:userEmail}. Valid for 5 minutes.`,"center","bottom"))
                    setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 7000);
                }else{
                    dispatch(changeSnackStatusRed(true,"success",`please send code sent to ${loginType==="phone_number"?phoneNumber:userEmail}. Valid for 5 minutes.`,"center","bottom"))
                    setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 7000);
                }
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeLoadingStatusRed(false))
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
        })
}

const sendPhoneNumberRed = (data,step) => ({
    type : types.SEND_PHONE_NUMBER_AUTH,
    data:data,
    step:step
});


export const updatePrivacy = (phoneNumber,codeId,userEmail,loginType,verificationCode,apiToken) => dispatch => {

    let data = new FormData();
    data.append('phone_number' , phoneNumber);
    data.append('email' , userEmail);
    data.append('type' , loginType);
    data.append('code' , verificationCode);
    data.append('id' , codeId);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }

    axios.post(`${baseUrlAPI}/user/custom/update/verify/code` , data ,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(completeAccountDataLoginRed(response.data.data.user,0))
                dispatch(changeLoadingStatusRed(false))
                //history.replace('/')
                dispatch(changeSnackStatusRed(true,"success",`Your Profile has been updated.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);

            }else if(response.data.status==="failed") {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Incorrect code!`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeLoadingStatusRed(false))
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
        })
}

const sendCodeRed = (data,step) => ({
    type : types.SEND_CODE_AUTH,
    data:data,
    step:step
});


export const sendCode = (phoneNumber,codeId,userEmail,loginType,verificationCode,history) => dispatch => {

    let data = new FormData();
    data.append('phone_number' , phoneNumber);
    data.append('email' , userEmail);
    data.append('login_type' , loginType);
    data.append('code' , verificationCode);
    data.append('id' , codeId);

    axios.post(`${baseUrlAPI}/web/send/code` , data)
        .then(response => {
            if(response.data.status==="success") {
                if(response.data.data.flag===0){
                    dispatch(sendCodeRed(response.data.data,3))
                    localStorage.setItem('blooomshoApiToken', response.data.data.accessToken);
                    dispatch(setInitRed(response.data.data))
                    dispatch(changeLoadingStatusRed(false))
                    history.replace('/user/select/category')
                    dispatch(changeSnackStatusRed(true,"success",`Welcome to Bloomsho. please complete your account.`,"center","bottom"))
                    setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                }
                if(response.data.data.flag===1){
                    dispatch(sendCodeRed(response.data.data,0))
                    localStorage.setItem('blooomshoApiToken', response.data.data.accessToken);
                    dispatch(setInitRed(response.data.data))
                    dispatch(changeLoadingStatusRed(false))
                    history.replace('/')
                    dispatch(changeSnackStatusRed(true,"success",`Welcome to Bloomsho.`,"center","bottom"))
                    setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                }

            }else if(response.data.status==="failed") {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Incorrect code!`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeLoadingStatusRed(false))
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
        })
}


const setInitRed = (data) => ({
    type : types.RECEIVE_INIT,
    data:data,
});


export const resetLogin = (step) => dispatch => {
    dispatch(resetLoginRed(step))
}

const resetLoginRed = (step) => ({
    type : types.REST_LOGIN,
    step:step
});


export const completeAccountDataLogin = (firstName,lastName,selectedValueGender,age,groupId,countryId,cityId,history,userId,apiToken) => dispatch => {

    let data = new FormData();
    data.append('name' , firstName);
    data.append('last_name' , lastName);
    data.append('gender' , selectedValueGender);
    data.append('age' , age);
    data.append('group_id' , groupId);
    data.append('country_id' , countryId);
    data.append('city_id' , cityId);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }

    axios.post(`${baseUrlAPI}/user/${userId}/update` , data,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(completeAccountDataLoginRed(response.data.data,0))
                dispatch(changeLoadingStatusRed(false))
                history.replace("/")
                dispatch(changeSnackStatusRed(true,"success",`Thanks , Welcome to Bloomsho community.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const completeAccountDataLoginRed = (data,step) => ({
    type : types.COMPLETE_ACCOUNT_AUTH,
    user:data,
    step:step
});



export const logout = (history,apiToken) => dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.get(`${baseUrlAPI}/user/logout`,{headers})
        .then(response => {
            if(response.data.status==="success") {
                if(window.innerWidth<755){
                    dispatch(logoutRed(11))
                }else{
                    dispatch(logoutRed(1))
                }
                dispatch(changeLoadingStatusRed(false))
                history.replace(`/user/login`)
                dispatch(changeSnackStatusRed(true,"success",` GoodBye.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const logoutRed = () => ({
    type : types.LOGOUT,
});


export const  checkAuth =  (apiToken,history,width) => dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.get(`${baseUrlAPI}/user/check/auth`,{headers})
        .then(response => {
            console.log(response)
            if(response.data.status==="Unauthorized") {
                if(width<755){
                    dispatch(logoutRed(11))
                }else{
                    dispatch(logoutRed(1))
                }
                history.replace(`/user/login`)
                dispatch(changeSnackStatusRed(true,"info",`please enter your Phone number and login . `,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else if(response.data.status==="success") {
            }
        })
        .catch(error => {
            if(error.message==="Request failed with status code 401"){
                if(width<755){
                    dispatch(logoutRed(11))
                }else{
                    dispatch(logoutRed(1))
                }
                history.replace(`/user/login`)
                dispatch(changeSnackStatusRed(true,"info",`please enter your Phone number and login . `,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })

}


export const updateProfile= (data,userId,apiToken,history) => dispatch => {


    const  postData = {};
    postData.gender=data.selectedValueGender!==null?data.selectedValueGender:null;
    postData.name=data.name!==null?data.name:null;
    postData.last_name=data.last_name!==null?data.last_name:null;
    postData.contact_email=data.contact_email!==null?data.contact_email:null;
    postData.contact_phone=data.contact_phone!==null?data.contact_phone:null;
    postData.about_me=data.about_me!==null?data.about_me:null;
    postData.age=data.age!==null?data.age:null;
    postData.group_id=data.groupId!==null?data.groupId:null;
    postData.country_id=data.countryId!==null?data.countryId:null;
    postData.city_id=data.cityId!==null?data.cityId:null;
    postData.yearly_rate=data.yearlyRate!==null?data.yearlyRate:null;
    postData.monthly_rate=data.monthlyRate!==null?data.monthlyRate:null;
    postData.daily_rate=data.dailyRate!==null?data.dailyRate:null;
    postData.hourly_rate=data.hourlyRate!==null?data.hourlyRate:null;
    postData.currency=data.currency!==null?data.currency:null;
    postData.waist=data.waist!==null?data.waist:null;
    postData.jacket=data.jacket!==null?data.jacket:null;
    postData.neck=data.neck!==null?data.neck:null;
    postData.weight=data.weight!==null?data.weight:null;
    postData.height=data.height!==null?data.height:null;
    postData.linkedin=data.linkedin!==null?data.linkedin:null;
    postData.facebook=data.facebook!==null?data.facebook:null;
    postData.instagram=data.instagram!==null?data.instagram:null;
    postData.telegram=data.telegram!==null?data.telegram:null;
    postData.youtube=data.youtube!==null?data.youtube:null;
    postData.skype=data.skype!==null?data.skype:null;
    postData.metric=data.metric!==null?data.metric:null;
    postData.status=data.status!==null?data.status:null;
    postData.cv_flag=data.cv_flag!==null?data.cv_flag:null;
    postData.subgroup_id=data.userSubgroups?data.userSubgroups.join(";"):null;
    postData.optional=data.userOptionalsId?data.userOptionalsId.join(","):null;
    /*postData.xx=data.xx!==null?data.xx:null;*/


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/user/${userId}/update` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(updateProfileRed(response.data.data))
                dispatch(changeLoadingStatusRed(false))
                history.replace("/setting")
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const updateProfileRed = (data) => ({
    type : types.UPDATE_PROFILE,
    user:data,
});



/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*****************************************************HOME**************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/

export const getUsers = (nextPage,apiToken,history,searchState,searchFlag) => dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    const  postData = {};
    if(searchFlag===true){
        postData.group_id=searchState.groupId!==null?searchState.groupId:null;
        postData.subgroup_id=searchState.subgroupId!==null?searchState.subgroupId:null;
        postData.country_id=searchState.countryId!==null?searchState.countryId:null;
        postData.city_id=searchState.cityId!==null?searchState.cityId:null;
        postData.age=searchState.age!==null?searchState.age:null;
        postData.gender=searchState.selectedValueGender!==null?searchState.selectedValueGender:null;
        postData.optional=searchState.optionalsId.length!==0?searchState.optionalsId.toString():null;
    }

    axios.post(`${baseUrlAPI}/user/custom/search?page=${nextPage}`,postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                if(searchFlag===true){
                    dispatch(getUsersSearchRed(response.data.data[0],response.data.data[0].data))
                }else{
                    dispatch(getUsersRed(response.data.data[0],response.data.data[0].data))
                }

                dispatch(changeLoadingStatusRed(false))
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const getUsersRed = (data,users) => ({
    type : types.RECEIVE_USERS,
    data:data,
    users:users,
});

const getUsersSearchRed = (data,users) => ({
    type : types.RECEIVE_USERS_SEARCH,
    data:data,
    users:users,
});

export const initUsers = () => ({
    type : types.INIT_USERS,
});

export const initUsersSearch = () => ({
    type : types.INIT_USERS_SEARCH,
});

export const initUsersTempSearch = () => ({
    type : types.INIT_USERS_TEMP_SEARCH,
});


export const initUsersToTemp = (data,nextPage,hasMore) => ({
    type : types.INIT_USERS_TO_TEMP,
    userData:data,
    nextPage:nextPage,
    hasMore:hasMore,
});

export const initUsersToTempSearch = (data,nextPage,hasMore) => ({
    type : types.INIT_USERS_TO_TEMP_SEARCH,
    userData:data,
    nextPage:nextPage,
    hasMore:hasMore,
});

export const initTempToUsers = (data,nextPage,hasMore) => ({
    type : types.INIT_TEMP_TO_USERS,
    tempData:data,
    nextPage:nextPage,
    hasMore:hasMore,
});

export const initTempToUsersSearch = (data,nextPage,hasMore) => ({
    type : types.INIT_TEMP_TO_USERS_SEARCH,
    tempData:data,
    nextPage:nextPage,
    hasMore:hasMore,
});

export const showUserDetail = (userId,apiToken) => dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }


    axios.get(`${baseUrlAPI}/user/get/${userId}/detail`,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(showUserDetailRed(response.data.data))
                dispatch(changeLoadingStatusRed(false))
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const showUserDetailRed = (data) => ({
    type : types.GET_USER_DETAIL,
    data:data,
});


export const showUserProfile = (userId,apiToken) => dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }


    axios.get(`${baseUrlAPI}/user/get/${userId}/detail`,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(showUserProfileRed(response.data.data))
                dispatch(changeLoadingStatusRed(false))
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const showUserProfileRed = (data) => ({
    type : types.GET_USER_PROFILE,
    data:data,
});


export const showUserDetailReset = () => dispatch => {
    dispatch(showUserDetailResetRed())
}

const showUserDetailResetRed = () => ({
    type : types.REST_SELECTED_USER,
});


export const changeSearchState = (data) => dispatch => {
    dispatch(changeSearchStateRed(data))
}

const changeSearchStateRed = (data) => ({
    type : types.CHANGE_USER_SEARCH_DATA,
    data
});



export const bookmarkUser = (bookmarks,apiToken) => dispatch => {

    let postData = new FormData();
    postData.append('favorites' , bookmarks.length>0?bookmarks.toString():"");
    postData.append('type' , "user");


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/store/user/favorites` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(bookmarkUserRed(bookmarks))
                dispatch(changeLoadingStatusRed(false))
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}


const bookmarkUserRed = (bookmarks) => ({
    type : types.ADD_BOOKMARKS_USER,
    userBookmarks:bookmarks
});




export const addPhotoUser = (userId,base64Photo,type,apiToken) => dispatch => {

    let postData = new FormData();
    postData.append('photos[0]' , base64Photo);
    postData.append('type' , type);
    postData.append('user_id' ,userId );
    postData.append('main_photo' ,base64Photo );


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/user/add/photo` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(addPhotoUserRed(response.data.data.user))
                dispatch(changeLoadingStatusRed(false))
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}


const addPhotoUserRed = (userData) => ({
    type : types.ADD_PHOTOS_USER,
    userDataPhoto:userData
});



export const deletePhotoUser = (userId,key,url,type,apiToken) => dispatch => {

    let postData = new FormData();
    postData.append('key' , key);
    postData.append('url' , url);
    postData.append('type' , type);
    postData.append('user_id' ,userId );


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/user/delete/photo` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(deletePhotoUserRed(response.data.data.user))
                dispatch(changeLoadingStatusRed(false))
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}


const deletePhotoUserRed = (userData) => ({
    type : types.ADD_PHOTOS_USER,
    userDataPhoto:userData
});


export const addCVUser = (file,apiToken) => dispatch => {

    let postData = new FormData();
    postData.append('cv' , file);


    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/user/add/cv` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(updateProfileRed(response.data.data.user))
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"success",`Your resume upload successfully.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}


export const deleteCVUser = (apiToken) => dispatch => {

    let postData = new FormData();


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/user/delete/cv` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(updateProfileRed(response.data.data.user))
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"success",`Your resume deleted successfully.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}


/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*****************************************************Cast**********************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/
/*******************************************************************************************************************/


export const getCasts = (nextPage,apiToken,searchState) => dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }

    const  postData = {};
    postData.group_id=searchState.groupId!==null?searchState.groupId:null;
    postData.country_id=searchState.countryId!==null?searchState.countryId:null;
    postData.city_id=searchState.cityId!==null?searchState.cityId:null;
    postData.gender=searchState.selectedValueGender!==null?searchState.selectedValueGender:null;


    axios.post(`${baseUrlAPI}/post/search?page=${nextPage}`,postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(getCastsRed(response.data.data[0],response.data.data[0].data))
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
        })
}

const getCastsRed = (data,casts) => ({
    type : types.RECEIVE_CASTS,
    data:data,
    casts:casts,
});

export const initCasts= () => ({
    type : types.INIT_CASTS,
});

export const initSelectedCast= () => ({
    type : types.INIT_SELECTED_CAST,
});

export const initCastsTemp= () => ({
    type : types.INIT_CASTS_TEMP,
});

export const changeCastSearchState = (data) => dispatch => {
    dispatch(changeCastSearchStateRed(data))
}

const changeCastSearchStateRed = (data) => ({
    type : types.CHANGE_CAST_SEARCH_DATA,
    data
});



export const initCastsToTemp = (data,nextPage,hasMore) => ({
    type : types.INIT_CASTS_TO_TEMP,
    castData:data,
    nextPage:nextPage,
    hasMore:hasMore,
});

export const initTempToCasts = (tempData,nextPage,hasMore) => ({
    type : types.INIT_TEMP_TO_CASTS,
    tempData:tempData,
    nextPage:nextPage,
    hasMore:hasMore,
});



export const getMyCastDetail= (castId,apiToken) => dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }

    axios.get(`${baseUrlAPI}/post/my/posts`,{headers})
        .then(response => {
            if(response.data.status==="success") {
                let selectedCast=response.data.data.casts.filter( (cast)=> {
                    if(cast.id==castId){
                        return cast;
                    }
                } )
                dispatch(getCastDetailRed(selectedCast[0]))
                dispatch(changeLoadingStatusRed(false))
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const getCastDetailRed = (data) => ({
    type : types.GET_CAST_DETAIL,
    data:data,
});

export const getCastDetail= (castId,allCasts) => dispatch => {
    let selectedCast=allCasts.filter( (cast)=>{
        if(cast.id==castId){
            return cast
        }
    } )
    if(selectedCast[0]!==null) {
        dispatch(getCastDetailRed(selectedCast[0]))
        dispatch(changeLoadingStatusRed(false))
    }else {
        dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
        setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
        dispatch(changeLoadingStatusRed(false))
    }
}


export const addNewCast = (data,history,apiToken) => dispatch => {

    const  postData = {};
    postData.group_id=data.groupId;
    postData.subgroup_id=data.subgroupId;
    postData.age=data.age;
    postData.country_id=data.countryId;
    postData.city_id=data.cityId;
    postData.expire_date=data.durationVal;
    postData.gender=data.selectedValueGender;
    postData.yearly_rate=data.yearlyRate===0?null:data.yearlyRate;
    postData.monthly_rate=data.monthlyRate===0?null:data.monthlyRate;
    postData.daily_rate=data.dailyRate===0?null:data.dailyRate;
    postData.hourly_rate=data.hourlyRate===0?null:data.hourlyRate;
    postData.phone_number=data.phone_number;
    postData.optional_phone_number=data.optional_phone_number;
    postData.desc=data.description;
    postData.payment=data.payment;
    postData.title=data.title;
    postData.email=data.email;
    postData.currency=data.currency;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/post/store` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(addNewCastRed(data))
                dispatch(changeLoadingStatusRed(false))
                history.replace("/all/my/activity")
                dispatch(changeSnackStatusRed(true,"success",`cast published successfully.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const addNewCastRed = (data) => ({
    type : types.ADD_CAST,
    castData:data,
});


export const deleteCast = (id,apiToken) => dispatch => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/post/${id}/delete` , [],{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(deleteCastRed(response.data.data.casts))
                dispatch(changeAlertStatus(false,"",``,null,""))
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"success",`cast deleted successfully.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeAlertStatus(false,"",``,null,""))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}

const deleteCastRed = (castsData) => ({
    type : types.DELETE_CAST,
    castsData:castsData,
});





export const applyCast = (id,apiToken) => dispatch => {

    let postData = new FormData();
    postData.append('post_id' , id);


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/store/user/applies` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                let appliesId=[];
                response.data.data.applies.map( (apply)=> appliesId.push(apply.post?apply.post.id.toString():null))
                dispatch(applyCastRed(response.data.data.applies,appliesId))
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"success",`Your apply has been successfully filed.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }else {
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
                dispatch(changeLoadingStatusRed(false))
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}


const applyCastRed = (appliesData,appliesId) => ({
    type : types.APPLY_FOR_CAST,
    appliesData:appliesData,
    appliesId:appliesId,
});


export const bookmarkCast = (bookmarks,apiToken) => dispatch => {

    let postData = new FormData();
    postData.append('favorites' , bookmarks.length>0?bookmarks.toString():"");
    postData.append('type' , "cast");


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiToken}`
    }
    axios.post(`${baseUrlAPI}/store/user/favorites` , postData,{headers})
        .then(response => {
            if(response.data.status==="success") {
                dispatch(bookmarkCastRed(bookmarks))
                dispatch(changeLoadingStatusRed(false))
            }else {
                dispatch(changeLoadingStatusRed(false))
                dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.`,"center","bottom"))
                setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            }
        })
        .catch(error => {
            dispatch(changeSnackStatusRed(true,"error",`Something went wrong. Please try again.check your internet connection`,"center","bottom"))
            setTimeout(function(){ dispatch(changeSnackStatusRed(false,"success",'',"center","bottom"))}, 5000);
            dispatch(changeLoadingStatusRed(false))
        })
}


const bookmarkCastRed = (bookmarks) => ({
    type : types.ADD_BOOKMARKS_CAST,
    bookmarks:bookmarks
});