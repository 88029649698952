import { createStore } from 'redux';
import reducer from './../reducers/index';
import {applyMiddleware} from "redux";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';
import {createLogger} from "redux-logger";
import {persistReducer} from "redux-persist";

const persistConfig = {
    key: 'root',
    storage,
}


const middleware = [ thunk ];
middleware.push(createLogger());

const persistedReducer = persistReducer(persistConfig, reducer)


const store = createStore(
    persistedReducer,
    applyMiddleware(...middleware)
);
export default store;