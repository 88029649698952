import React ,{Component,PureComponent} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import Slider from "@material-ui/core/es/Slider/Slider";
import Radio from "@material-ui/core/es/Radio/Radio";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import AppBar from "@material-ui/core/es/AppBar/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import Button from "@material-ui/core/es/Button/Button";
import 'react-image-crop/dist/ReactCrop.css';
import './../styles/css/App.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {addPhotoUser, changeLoadingStatus, deletePhotoUser, updateProfile} from "../actions";
import {baseUrl} from "../constants/constant";
import Slide from "@material-ui/core/es/Slide/Slide";
import CloseIcon from '@material-ui/icons/Close';
import ReactCrop from 'react-image-crop';
import Backdrop from "@material-ui/core/es/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Input from "@material-ui/core/es/Input/Input";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './../styles/css/main.css';

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('md')]: {
            "width": "50%",
        },
    },
    firstDivEditProfile:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    firstRightDivEditProfile:{
        "marginRight": "5%",
        "color": "#00ebff"
    },
    secondDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondUpDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondUpPhotoDivEditProfile:{
        "width": "150px",
        "height": "200px",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        "borderRadius": "5px",
        "boxShadow": "0 2px 6px 0 #000000c9",
        "position": "relative",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('md')]: {
            "width": "50vw",
            "height": "66vw"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "20vw",
            "height": "27vw"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "20vw",
            "height": "27vw"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "20vw",
            "height": "27vw"
        }
    },
    secondUpPhotoIconBtnDivEditProfile:{
        "position": "absolute",
        "bottom": "0",
    },
    secondUpPhotoDeleteIconBtnDivEditProfile:{
        "position": "absolute",
        "bottom": "0",
        "right": "1px",
        "color": "#dc1c1c",
    },
    secondUpPhotoIconDivEditProfile:{
        "height": "35px",
        "width": "35px",
    },
    secondDownDivEditProfile:{
        "& h5":{
            "color": "#A7A7A7",
            "margin": "7px",
        },
    },
    thirdDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column"
    },
    thirdDivCoverEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
    },
    thirdDivInputEditProfile:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "2%",
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
        }
    },
    thirdDivInputRangeEditProfile:{
        width: "81%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "2px auto 20px",
    },
    thirdDivRangeEditProfile: {
        color: "#ff8630",
        width: "100%",
        margin: "-12px auto",
    },
    thirdDivGenderEditProfile: {
        "width": "81%"
    },
    thirdDivGenderCoverEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "85%",
    },
    thirdDivGenderRadioEditProfile: {
        color: "#ff8630 !important",
    },
    thirdDivGenderRadioLabelEditProfile:
        {
            "marginLeft": "-7px",
            "marginRight": "5px",
        }
    ,
    fourthDivEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "width": "85%"
    },
    fourthDivTitleEditProfile:{
        "& h3":{
            "marginBottom": "0",
            "color": "#fea257"
        },
    },
    fourthDivCaptionEditProfile:{
        "& p":{
            "color": "#A7A7A7",
            "textAlign": "justify",
            "fontSize": "10px",
            [theme.breakpoints.up('xs')]: { //Iphone
                "fontSize": "9px",
            },[theme.breakpoints.up('sm')]: {//Iphone +
                "fontSize": "11px",
            },[theme.breakpoints.up('md')]: {
                "fontSize": "11px",
            },
            [theme.breakpoints.up('lg')]: {//Tab
                "fontSize": "11px",
            },
            [theme.breakpoints.up('xl')]: {//lap
                "fontSize": "14px",
            },
            [theme.breakpoints.up('xxl')]: {//Llap
                "fontSize": "20px",
            }
        },
    },
    fourthDivInputCoverEditProfile:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    fourthDivInputEditProfile:{
        "width": "100%",
        "height": "150px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "2%"
    },
    appBarDialog: {
        position: 'relative',
        "backgroundColor": "#222222",
        "boxShadow": "0 0 0 0 #222222",
        "paddingTop": "11vh",
        "height": "40px",
        "flexDirection": "row",
        "justifyContent": "flex-end",
    },
    titleDialog: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    coverUploadPhotoDialog: {
        "width": "100%",
        "display": "flex",
        "minHeight": "100%",
        "alignItems": "center",
        "paddingTop": "8vh",
        "paddingBottom": "20%",
        "justifyContent": "start",
        "backgroundColor": "#222222",
        "flexDirection": "column"

    },
    buttonUpload: {
        "fontFamily": "En",
        "margin": "25% 0",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    countryDiv:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "85%",
        "border": "1px solid dimgray",
        "borderRadius": "4px",
        "height": "30px",
        "color": "#ff8630",
        "marginTop": "10px",
        "backgroundColor": "#101010",
        "& div":
            {
                "margin": "0 10px",
                "width": "80%",
            },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
        }
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        "width": "100%"
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    jkjl:{},
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class EditProfileComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            age:25,
            selectedValueGender:"Male",
            base64Photo: null,
            src: null,
            openPhoto:false,
            name:null,
            last_name:null,
            email:null,
            about_me:null,
            open2:false,
            open3:false,
            open4:false,
            countries:null,
            cities:null,
            city:"Your City or State",
            country:"Your Country",
            group:"Your Category",
            countryId:null,
            groupId:null,
            cityId:null,
            crop: {
                unit: 'vw',
                width: 30,
                aspect: 3 / 4,
            },
        };
        this.handelRangeAge = this.handelRangeAge.bind(this);
    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleChangeInputs = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        })
    };

    componentDidMount(){
        this.setState({
            age:this.props.user.age,
            name:this.props.user.name,
            last_name:this.props.user.last_name,
            about_me:this.props.user.about_me,
            cityId:this.props.user.city.id,
            countryId:this.props.user.country.id,
            city:this.props.user.city.title,
            country:this.props.user.country.title,
            group:this.props.user.group.title,
            selectedValueGender:this.props.user.gender,
        })
    }

    handleGoBackSetting(){
        this.props.history.push(`/setting`)
    }

    handelRangeAge(e,value){
        this.setState({
            age:value
        })
        let ele= document.getElementById(`ageP`);
        ele.innerText="Age : " +this.state.age
    }

    handleChangeGender(value){
        this.setState({
            selectedValueGender:value
        })
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width*scaleX;
        canvas.height = crop.height*scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );

        const base64Image = canvas.toDataURL('image/jpeg');
        this.setState({ base64Photo: base64Image});

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    handleClickOpenPhoto = () => {
        this.setState({
            openPhoto:true,
        })
    };

    handleClosePhoto = () => {
        this.setState({
            openPhoto:false,
            src:null,
            croppedImageUrl:null,
        })
    };

    handleClickAddPhotos = () => {
        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.addPhotoUser(user.id,this.state.base64Photo.replace("data:image/jpeg;base64,",""),"main_photo",auth.apiToken);
        this.setState({
            openPhoto:false,
            src:null,
            croppedImageUrl:null,
        })

    };

    handleDeleteCastBtn(){

        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.deletePhotoUser(user.id,null,null,"main_photo",auth.apiToken);
        this.setState({
            open:false,
            dialogPhotoSrc:'',
            dialogPhotoKey:'',
            dialogPhotoPureUrl:'',
        })
    }

    handleSaveSetting(){
        const { user,auth} = this.props;
        this.props.changeLoadingStatus(true);
        this.props.updateProfile(this.state,user.id,auth.apiToken, this.props.history);
    }

    handleClickOpen2 = () => {
        this.setState({
            open2:true
        })
    };

    handleClickOpen3 = () => {
        this.setState({
            open3:true
        })
    };

    handleClickOpen4 = () => {
        this.setState({
            open4:true
        })
    };

    handleClose2 = () => {
        this.setState({
            open2:false
        })
        if (this.state.country!=="Your Country"){
            let ele= document.getElementById(`countrySelect`);
            ele.innerText=this.state.country
        }
    };

    handleClose3 = () => {
        this.setState({
            open3:false
        })
        if (this.state.country!=="Your City"){
            let ele= document.getElementById(`citySelect`);
            ele.innerText=this.state.city
        }
    };

    handleClose4 = () => {
        this.setState({
            open4:false
        })
        if (this.state.country!=="Your City"){
            let ele= document.getElementById(`citySelect`);
            ele.innerText=this.state.city
        }
    };

    handleChangeCountry = (event) => {
        let selectedCountry = this.props.appInitData.countries.filter( (country)=> {
            if(country.title===event.target.value){
                return country
            }
        } )

        this.setState({
            country:selectedCountry[0].title,
            countryId:selectedCountry[0].id,
            cities:selectedCountry[0].cities
        })
    };

    handleChangeCity = (event) => {
        let selectedCity = this.state.cities.filter( (city)=> {
            if(city.title===event.target.value){
                return city
            }
        } )
        this.setState({
            city:selectedCity[0].title,
            cityId:selectedCity[0].id
        })
    };

    handleChangeCategory= (event) => {
        let selectedCategory = this.props.appInitData.groups.filter( (group)=> {
            if(group.title===event.target.value){
                return group
            }
        } )
        this.setState({
            group:selectedCategory[0].title,
            groupId:selectedCategory[0].id
        })
    };


    render() {
        const { classes,user,loading,appInitData } = this.props;
        const { selectedValueGender,age,crop, croppedImageUrl, src,name ,last_name,openPhoto,open2,open3,open4,city,country ,group,cities} = this.state;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>


                    <div className={classes.firstDivEditProfile}>
                       <di onClick={ ()=> {this.handleGoBackSetting()}} className={classes.firstLeftDivEditProfile}>
                           <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                               <ArrowBackIosIcon fontSize="small" />
                           </IconButton>
                       </di>

                       <div className={classes.firstRightDivEditProfile}>
                           <h5 onClick={ ()=> {this.handleSaveSetting()}}>Save</h5>
                       </div>

                   </div>

                    <div className={classes.secondDivEditProfile}>

                        <div className={classes.secondUpDivEditProfile}>
                            <div  className={classes.secondUpPhotoDivEditProfile} style={{backgroundImage:`url('${user.main_photo?baseUrl+user.main_photo:"/images/no-user.png"}')`}}>

                                <IconButton onClick={()=> {this.handleClickOpenPhoto()}}  className={classes.secondUpPhotoIconBtnDivEditProfile} aria-haspopup="true"  color="inherit">
                                    <AddAPhotoRoundedIcon  className={classes.secondUpPhotoIconDivEditProfile} fontSize="small" style={{"color": "#00ebff"}} />
                                </IconButton>

                                <IconButton onClick={()=> {this.handleDeleteCastBtn()}}  className={classes.secondUpPhotoDeleteIconBtnDivEditProfile} aria-haspopup="true"  color="inherit">
                                    <DeleteIcon  className={classes.secondUpPhotoIconDivEditProfile} fontSize="small" />
                                </IconButton>

                            </div>

                        </div>

                        <div className={classes.secondDownDivEditProfile}>
                            <h5>Add your profile photo. You can change it later</h5>
                        </div>

                    </div>

                    <div className={classes.thirdDivEditProfile}>

                        <div className={classes.thirdDivCoverEditProfile}>
                            <input onChange={this.handleChangeInputs} value={name}  name="name" className={classes.thirdDivInputEditProfile}  type="text" placeholder="Name" />
                        </div>

                        <div className={classes.thirdDivCoverEditProfile}>
                            <input onChange={this.handleChangeInputs} value={last_name} name="last_name" className={classes.thirdDivInputEditProfile}  type="text" placeholder="Last Name" />
                        </div>

                        {/*<div  className={classes.countryDiv}>
                            <div onClick={this.handleClickOpen4} id="countrySelect" >{group}</div>
                            <IconButton onClick={this.handleClickOpen4} className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                <ArrowDropDownIcon fontSize="small" />
                            </IconButton>
                            <Dialog disableBackdropClick disableEscapeKeyDown open={open4} onClose={this.handleClose4}>
                                <DialogTitle>Choose Your category</DialogTitle>
                                <DialogContent>
                                    <form className={classes.container}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-dialog-select-label2">Category</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label2"
                                                id="demo-dialog-select2"
                                                value={group}
                                                onChange={this.handleChangeCategory}
                                                input={<Input />}
                                            >
                                                {
                                                    appInitData.groups && appInitData.groups.map((group , index) => {
                                                        return <MenuItem key={index} value={group.title}>{group.title}</MenuItem>
                                                    })
                                                }

                                            </Select>
                                        </FormControl>
                                    </form>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose4} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={this.handleClose4} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>*/}

                        <div  className={classes.countryDiv}>
                            <div onClick={this.handleClickOpen2} id="countrySelect" >{country}</div>
                            <IconButton onClick={this.handleClickOpen2} className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                <ArrowDropDownIcon fontSize="small" />
                            </IconButton>
                            <Dialog disableBackdropClick disableEscapeKeyDown open={open2} onClose={this.handleClose2}>
                                <DialogTitle>Choose Your country</DialogTitle>
                                <DialogContent>
                                    <form className={classes.container}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-dialog-select-label2">Country</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label2"
                                                id="demo-dialog-select2"
                                                value={country}
                                                onChange={this.handleChangeCountry}
                                                input={<Input />}
                                            >
                                                {
                                                    appInitData.countries && appInitData.countries.map((country , index) => {
                                                        return <MenuItem key={index} value={country.title}>{country.title}</MenuItem>
                                                    })
                                                }

                                            </Select>
                                        </FormControl>
                                    </form>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose2} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={this.handleClose2} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div  className={classes.countryDiv}>
                            <div onClick={this.handleClickOpen3} id="citySelect" >{city}</div>
                            <IconButton onClick={this.handleClickOpen3} className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                <ArrowDropDownIcon fontSize="small" />
                            </IconButton>
                            <Dialog disableBackdropClick disableEscapeKeyDown open={open3} onClose={this.handleClose3}>
                                <DialogTitle>Choose Your City</DialogTitle>
                                <DialogContent>
                                    <form className={classes.container}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-dialog-select-label3">City</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label3"
                                                id="demo-dialog-select2"
                                                value={city}
                                                onChange={this.handleChangeCity}
                                                input={<Input />}
                                            >
                                                {
                                                    cities && cities.map((city , index) => {
                                                        return <MenuItem key={index} value={city.title}>{city.title}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </form>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose3} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={this.handleClose3} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="ageP">Age : {age}</p>
                            </div>
                            <Slider onChange={ this.handelRangeAge } value={age} className={classes.thirdDivRangeEditProfile}   min={1} max={100} defaultValue={age}  />
                        </div>

                        <div  className={classes.thirdDivGenderEditProfile}>
                            Gender
                        </div>

                        <div  className={classes.thirdDivGenderCoverEditProfile}>
                            <div className={classes.thirdDivGenderCoverEditProfile}>
                                <Radio
                                    classes={{root: classes.thirdDivGenderRadioEditProfile}}
                                    checked={selectedValueGender === 'Male'}
                                    onChange={()=>{this.handleChangeGender("Male")}}
                                    value="Male"
                                    name="radio-button-demo"
                                    size="small"
                                />
                                <p className={classes.thirdDivGenderRadioLabelEditProfile}>Male</p>
                            </div>
                            <div className={classes.thirdDivGenderCoverEditProfile}>
                                <Radio
                                    classes={{root: classes.thirdDivGenderRadioEditProfile}}
                                    checked={selectedValueGender === 'Female'}
                                    onChange={()=>{this.handleChangeGender("Female")}}
                                    value="Female"
                                    name="radio-button-demo"
                                    size="small"
                                />
                                <p className={classes.thirdDivGenderRadioLabelEditProfile}>Female</p>
                            </div>
                            <div className={classes.thirdDivGenderCoverEditProfile}>
                                <Radio
                                    classes={{root: classes.thirdDivGenderRadioEditProfile}}
                                    checked={selectedValueGender === 'Others'}
                                    onChange={()=>{this.handleChangeGender("Others")}}
                                    value="Others"
                                    name="radio-button-demo"
                                    size="small"
                                />
                                <p className={classes.thirdDivGenderRadioLabelEditProfile}>Others</p>
                            </div>
                        </div>

                        <div className={classes.fourthDivEditProfile}>

                            <div className={classes.fourthDivTitleEditProfile}>
                                <h3>Biography</h3>
                            </div>

                            <div className={classes.fourthDivCaptionEditProfile}>
                                <p>
                                   1. Tell us something interesting about yourself that potential clients might like to know when searching for a model.
                                </p>

                                <p>
                                    2. Tell us about some of the brands/company with whom you have done modeling.
                                </p>

                                <p>
                                    3. Why did you become a model?
                                </p>

                                <p>
                                    4. Please share anything else that you believe will make you stand out and shine when a potential client reads your bio.
                                </p>
                            </div>


                            <div className={classes.fourthDivInputCoverEditProfile}>
                                <textarea onChange={this.handleChangeInputs} name="about_me" className={classes.fourthDivInputEditProfile} placeholder="Bio" cols="30" rows="30">{user.about_me}</textarea>
                            </div>

                        </div>


                    </div>

                </div>

                <div>
                    <Dialog fullScreen open={openPhoto} onClose={this.handleClosePhoto} TransitionComponent={Transition} >
                        <AppBar className={classes.appBarDialog}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={this.handleClosePhoto} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>

                        <div className={classes.coverUploadPhotoDialog}>
                            <div className="divFile">
                                <input type="file" accept="image/*" onChange={this.onSelectFile} />
                            </div>
                            <div className="App">

                                {src && (
                                    <ReactCrop
                                        src={src}
                                        crop={crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                )}
                                {croppedImageUrl && (
                                    <img alt="Crop" className="imgCropMee" src={croppedImageUrl} />
                                )}

                            </div>
                            {croppedImageUrl && (
                                <div>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        className={classes.buttonUpload}
                                        startIcon={<CloudUploadIcon fontSize={window.innerWidth>1400?"medium":"small"} />}
                                        onClick={()=> {this.handleClickAddPhotos()}}
                                    >
                                        Upload
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Dialog>
                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>

            </div>

        );
    }
}

EditProfileComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        user : state.auth.user,
        apiToken : state.auth.apiToken,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{deletePhotoUser,addPhotoUser,updateProfile,changeLoadingStatus})(EditProfileComponent));