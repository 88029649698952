import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import Button from "@material-ui/core/es/Button/Button";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import Input from "@material-ui/core/es/Input/Input";
import Radio from "@material-ui/core/es/Radio/Radio";
import Slider from "@material-ui/core/es/Slider/Slider";
import {addNewCast, changeLoadingStatus, changeSnackStatus} from "../actions";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import './../styles/css/main.css';

const styles = theme => ( {
    cover:{
        "min-height": "92vh",
        "display": "flex",
        "overflow": "hidden",
        "alignItems": "flex-start",
        "justifyContent": "center",
        "flexDirection": "row",
        "flexWrap": "wrap",
        "paddingBottom": "12vh",
        "width": "100%",
    },
    mainDiv:{
        "width": "100%",
        "display": "flex",
        "overflow": "hidden",
        "flexWrap": "wrap",
        "minHeight": "92vh",
        "alignItems": "center",
        "flexDirection": "column",
        "justifyContent": "flex-start",
        [theme.breakpoints.up('xs')]: { //Iphone
            "fontSize": "12px"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "fontSize": "12px"
        },[theme.breakpoints.up('md')]: {
            "width": "50%",
            "fontSize": "13px"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "50%",
            "fontSize": "13px"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "50%",
            "fontSize": "13px"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "50%",
            "fontSize": "15px"
        },
    },
    firstDivAddNewCast:{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "width": "90%"
    },
    firstLeftDivAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
    },
    firstRightDivAddNewCast:{
        "marginRight": "5%"
    },
    secondDivAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondUpDivAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        "width": "100%"
    },
    secondUpPhotoDivAddNewCast:{
        "width": "150px",
        "height": "200px",
        "backgroundPosition": "center",
        "backgroundSize": "cover",
        "borderRadius": "5px",
        "boxShadow": "0 2px 6px 0 #000000c9",
        "position": "relative",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "50vw",
            "height": "66vw"
        },[theme.breakpoints.up('md')]: {
            "width": "50vw",
            "height": "66vw"
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "20vw",
            "height": "27vw"
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "20vw",
            "height": "27vw"
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "20vw",
            "height": "27vw"
        }
    },
    secondUpPhotoIconBtnDivAddNewCast:{
        "position": "absolute",
        "bottom": "0",
    },
    secondUpPhotoIconDivAddNewCast:{
        "height": "35px",
        "width": "35px",
    },
    secondDownDivAddNewCast:{
        "& h5":{
            "color": "#A7A7A7",
            "margin": "7px",
        },
    },
    thirdDivAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column"
    },
    thirdDivCoverAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
    },
    thirdDivInputAddNewCast:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "2%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
        }
    },
    thirdDivInputRangeAddNewCast:{
        width: "81%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "2px auto 20px",
    },
    thirdDivRangeAddNewCast: {
        color: "#ff8630",
        width: "100%",
        margin: "-12px auto",
    },
    thirdDivGenderAddNewCast: {
        "width": "81%"
    },
    thirdDivGenderCoverAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "85%",
    },
    thirdDivGenderRadioAddNewCast: {
        color: "#ff8630 !important",
    },
    thirdDivGenderRadioLabelAddNewCast:
        {
            "marginLeft": "-7px",
            "marginRight": "5px",
        }
    ,
    fourthDivAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "flex-start",
        "flexDirection": "column",
        "width": "85%"
    },
    fourthDivTitleAddNewCast:{
        "& h3":{
            "marginBottom": "0",
            "color": "#fea257"
        },
    },
    fourthDivCaptionAddNewCast:{
        "& p":{
            "color": "#A7A7A7",
            "textAlign": "justify",
            "fontSize": "12px",
            [theme.breakpoints.up('xs')]: { //Iphone
                "fontSize": "12px",
            },[theme.breakpoints.up('sm')]: {//Iphone +
                "fontSize": "13px",
            },[theme.breakpoints.up('md')]: {
                "fontSize": "13px",
            },
            [theme.breakpoints.up('lg')]: {//Tab
                "fontSize": "13px",
            },
            [theme.breakpoints.up('xl')]: {//lap
                "fontSize": "17px",
            },
            [theme.breakpoints.up('xxl')]: {//Llap
                "fontSize": "22px",
            }
        },
    },
    fourthDivInputCoverAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "85%",
        "margin": "2% 0"

    },
    fourthDivInputAddNewCast:{
        "width": "100%",
        "height": "150px",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "2%"
    },
    secondDivCoverAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "margin": "2% 0",
        "width": "85%",
        "position": "relative",
    },
    secondDivInputAddNewCast:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "rgba(0, 0, 0, 0.87)",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "25%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "21%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "21%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "22%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "17%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "15%",
        }
    },
    secondDivInput1AddNewCast:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "rgba(0, 0, 0, 0.87)",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "36%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "30%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "33%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "33%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "26%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "21%",
        }
    },
    secondDivInput2AddNewCast:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "rgba(0, 0, 0, 0.87)",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "36%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "30%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "33%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "33%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "26%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "21%",
        }
    },
    secondDivInput3AddNewCast:{
        "width": "97%",
        "height": "30px",
        "borderRadius": "5px",
        "backgroundColor": "rgba(0, 0, 0, 0.87)",
        "color": "white",
        "border": "1px solid #5f5d5d",
        "paddingLeft": "27%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "40px",
            "paddingLeft": "25%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
            "paddingLeft": "21%",
        },[theme.breakpoints.up('md')]: {
            "height": "35px",
            "paddingLeft": "21%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "41px",
            "paddingLeft": "22%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "40px",
            "paddingLeft": "17%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "46px",
            "paddingLeft": "15%",
        }
    },
    secondDivInputPlaceAddNewCast:{
        "position": "absolute",
        "top": "0",
        "left": "4%",
        "margin": "2% 1%",
        "color": "#fea257",
        "pointerEvents": "none",
        [theme.breakpoints.up('xs')]: { //Iphone
            "margin": "3% 1%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "margin": "2% 1%",
        },[theme.breakpoints.up('md')]: {
            "margin": "3% 1%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "margin": "3% 1%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "margin": "2% 1%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "margin": "2% 1%",
        }
    },
    secondDivInputArrowAddNewCast:{
        "position": "absolute",
        "top": "0",
        "right": "0",
        "color": "#fea257"
    },
    arrowIcon:{
        "backgroundColor": "#f0f8ff00",
        "width": "20%",
    },
    paperRoot:{
        "width": "40%",
        "maxWidth": "50%",
        [theme.breakpoints.up('xs')]: { //Iphone
            "width": "80%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "width": "80%",
            "maxWidth": "100%",
        },[theme.breakpoints.up('md')]: {
            "width": "80%",
            "maxWidth": "100%",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "width": "40%",
            "maxWidth": "50%",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "width": "40%",
            "maxWidth": "50%",
        }
    },
    fontEn:{
        "fontFamily": "En",
        "& h2":{
            "fontFamily": "En",
        },
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        "width": "100%"
    },
    otherTitleCoverDivAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "82%",
    },
    fourthDivCharAddNewCast:{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "10px auto 20px",
        "& div":{
            "& p":{
                "color": "white",
                "paddingLeft": "4%"
            },
        },
    },
    fourthDivCharItemCoverAddNewCast:{
        "width": "100%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-evenly",
        "flexWrap": "wrap"
    },
    fourthDivAgeItemAddNewCast:{
        "color": "black",
        "minWidth": "12%",
        "border": "1px solid black",
        "height": "25px",
        "margin": "2%",
        "display": "flex",
        "padding": "1% 5%",
        "alignItems": "center",
        "borderRadius": "5px",
        "justifyContent": "center",
        "backgroundColor": "white",
        "boxShadow": "0px 2px 4px 0px black"
    },
    currencyDivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%"
    },
    currencyCoverDivEditRate:{
        "display": "flex",
        "justifyContent": "space-around",
        "alignItems": "center",
        "width": "70%",
        "border": "1px solid lightgray",
        "borderRadius": "5px",
        "backgroundColor": "#333",
        "padding": "2%",
        "boxShadow": "0 1px 3px 1px black"
    },
    currencyItem1DivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "43%",
        "border": "1px solid white",
        "height": "32px",
        "borderRadius": "5px",
        "backgroundColor": "white",
        "color": "#222222",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "32px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "40px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "33px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "38px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
        }
    },
    currencyItem2DivEditRate:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "43%",
        "border": "1px solid #222222",
        "height": "32px",
        "borderRadius": "5px",
        "backgroundColor": "#222222",
        "color": "white",
        [theme.breakpoints.up('xs')]: { //Iphone
            "height": "32px",
        },[theme.breakpoints.up('sm')]: {//Iphone +
            "height": "35px",
        },[theme.breakpoints.up('md')]: {
            "height": "40px",
        },
        [theme.breakpoints.up('lg')]: {//Tab
            "height": "33px",
        },
        [theme.breakpoints.up('xl')]: {//lap
            "height": "38px",
        },
        [theme.breakpoints.up('xxl')]: {//Llap
            "height": "50px",
        }
    },
    thirdDivInputRangeEditProfile:{
        width: "81%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        "margin": "2px auto 20px",
    },
    thirdDivRangeEditProfile: {
        color: "#ff8630",
        width: "100%",
        margin: "-12px auto",
    },
    AddNewCastBtnCoverDivAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "82%",
    },
    AddNewCastBtnDivAddNewCast:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "backgroundColor": "#04d2e4",
        "padding": "3%",
        "borderRadius": "5px",
        "color": "#222222",
        "boxShadow": "0 3px 4px 1px black",
        "& h6":{
            "margin": "0",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    coverProgress:{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "width": "100%",
        "flexDirection": "column",

    },
    backdropImg:{
        width: "40vw",
        [theme.breakpoints.up('md')]: {//Iphone +
            width: "15vw",
        }
    },
    searchButton: {
        "backgroundColor": "#00ebff",
        "color": "#222",
        "fontFamily": "En",
        "width": "100%",
    },
    jkjl:{},
});


class AddNewCastComponent extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            open:false,
            open1:false,
            open2:false,
            open3:false,
            open4:false,
            type:"",
            groupId:null,
            subgroups:null,
            age:null,
            currency:"USD",
            category:"",
            subgroupId:null,
            country:"",
            countryId:null,
            cities:null,
            city:"",
            cityId:null,
            duration:"",
            durationVal:null,
            selectedValueGender:"Male",
            yearlyRate:null,
            monthlyRate:null,
            dailyRate:null,
            hourlyRate:null,
            phone_number:null,
            optional_phone_number:null,
            c:null,
            payment:null,
            title:null,
            email:null,
        };
        this.handelRangeHourlyRate = this.handelRangeHourlyRate.bind(this);
        this.handelRangeDailyRate = this.handelRangeDailyRate.bind(this);
        this.handelRangeMonthlyRate = this.handelRangeMonthlyRate.bind(this);
        this.handelRangeYearlyRate = this.handelRangeYearlyRate.bind(this);
    }

    componentDidMount(){

    }

    handleCloseBackDrop = () => {
        this.props.changeLoadingStatus(false)
    };

    handleClickPublish = () => {
        const {apiToken,user}=this.props.auth
        const {age,email,title,description,phone_number,durationVal,groupId,countryId,cityId,subgroupId}=this.state
        if(age!==null && email!==null && title!==null && description!==null&& phone_number!==null && groupId!==null && durationVal!==null && subgroupId!==null && countryId!==null && cityId!==null ){
            this.props.changeLoadingStatus(true)
            this.props.addNewCast(this.state,this.props.history,apiToken)
        }else if(email==null || title==null|| description==null|| phone_number==null || groupId==null || durationVal==null || subgroupId==null || countryId==null || cityId==null ){
            this.props.changeSnackStatus(true,"warning",`please complete your cast.`,"center","bottom")
            setTimeout(() =>{ this.props.changeSnackStatus(false,"success",'',"center","bottom")}, 5000);
        }
    }

    handleChangeInputs = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        })
    };

    handleChangeGender(value){
        this.setState({
            selectedValueGender:value
        })
    }

    handleClickOpen = () => {
        this.setState({
            open:true
        })
    };

    handleClickOpen1 = () => {
        this.setState({
            open1:true
        })
    };

    handleClickOpen2 = () => {
        this.setState({
            open2:true
        })
    };

    handleClickOpen3 = () => {
        this.setState({
            open3:true
        })
    };

    handleClickOpen4 = () => {
        this.setState({
            open4:true
        })
    };

    handleClose = () => {
        this.setState({
            open:false
        })
    };

    handleClose1 = () => {
        this.setState({
            open1:false
        })
    };

    handleClose2 = () => {
        this.setState({
            open2:false,
        })
    };

    handleClose3 = () => {
        this.setState({
            open3:false,
        })
    };

    handleClose4 = () => {
        this.setState({
            open4:false,
        })
    };

    handleChangeType = (event) => {
        let selectedType= this.props.appInitData.groups.filter( (group)=> {
            if(group.title===event.target.value){
                return group
            }
        } )

        this.setState({
            type:selectedType[0].title,
            groupId:selectedType[0].id,
            subgroups:selectedType[0].subgroups,
            category:""
        })
    };

    handleChangeCategory = (event) => {
        let selectedSubgroup = this.state.subgroups.filter( (subgroup)=> {
            if(subgroup.title===event.target.value){
                return subgroup
            }
        } )
        this.setState({
            category:selectedSubgroup[0].title,
            subgroupId:selectedSubgroup[0].id
        })

        this.setState({
            category:event.target.value
        })
    };

    handleChangeCountry = (event) => {
        let selectedCountry = this.props.appInitData.countries.filter( (country)=> {
            if(country.title===event.target.value){
                return country
            }
        } )

        this.setState({
            country:selectedCountry[0].title,
            countryId:selectedCountry[0].id,
            cities:selectedCountry[0].cities
        })
    };

    handleChangeCity = (event) => {
        let selectedCity = this.state.cities.filter( (city)=> {
            if(city.title===event.target.value){
                return city
            }
        } )
        this.setState({
            city:selectedCity[0].title,
            cityId:selectedCity[0].id
        })
    };

    handleChangeDuration = (event) => {
        let durationVal=7;
        if(event.target.value==="One week"){durationVal=7}
        if(event.target.value==="Two week"){durationVal=14}
        if(event.target.value==="One month"){durationVal=30}
        if(event.target.value==="Three months"){durationVal=90}

        this.setState({
            duration:event.target.value,
            durationVal:durationVal,
        })
    };

    handleClickAge = (id,count,value) => {
        let eleA= document.getElementById(`ageType${id}`);
        eleA.style.backgroundColor= "#00EBFF"
        eleA.style.color= "#05080b"
        for(let i=0;i<=count-1;i++){
            if(i!==id){
                let ele = document.getElementById(`ageType${i}`);
                ele.style.backgroundColor= "#fff"
                ele.style.color= "#222"
            }
        }
        this.setState({
            age:value,
        })
    }

    handelRangeHourlyRate(e,value){
        this.setState({
            hourlyRate:value,
        })
        let ele= document.getElementById(`hourlyRateP`);
        ele.innerText="Hourly Rate : " +this.state.hourlyRate
    }

    handelRangeDailyRate(e,value){
        this.setState({
            dailyRate:value,
        })
        let ele= document.getElementById(`dailyRateP`);
        ele.innerText="Daily Rate : " +this.state.dailyRate
    }

    handelRangeMonthlyRate(e,value){
        this.setState({
            monthlyRate:value,
        })
        let ele= document.getElementById(`monthlyRateP`);
        ele.innerText="Monthly Rate : " +this.state.monthlyRate
    }

    handelRangeYearlyRate(e,value){
        this.setState({
            yearlyRate:value,
        })
        let ele= document.getElementById(`yearlyRateP`);
        ele.innerText="Yearly Rate : " +this.state.yearlyRate
    }

    handleClickCurrency = (idCur,idNext) => {
        let eleA= document.getElementById(`currency${idCur}`);
        let eleB= document.getElementById(`currency${idNext}`);
        eleA.style.backgroundColor= "white"
        eleA.style.color= "#222222"
        eleA.style.borderColor= "white"

        eleB.style.backgroundColor= "#222222"
        eleB.style.color= "white"
        eleB.style.borderColor= "#222222"
        let currency=idCur===1?"USD":"EUR";
        this.setState({
            currency:currency
        })
    }


    render() {

        const { loading,classes,appInitData } = this.props;
        const {cities,subgroups, open,open1,open2,open3,open4,type ,country,category,city,duration,selectedValueGender,hourlyRate,dailyRate,monthlyRate,yearlyRate} = this.state;

        return (
            <div className={classes.cover}>

                <div  className={classes.mainDiv}>


                    <div className={classes.secondDivAddNewCast}>

                        <div className={classes.secondDownDivAddNewCast}>
                            <h5>Add your cast. You can change it later</h5>
                        </div>

                    </div>


                    <div className={classes.thirdDivAddNewCast}>

                        <div className={classes.thirdDivCoverAddNewCast}>
                            <input onChange={this.handleChangeInputs} name="title"  className={classes.thirdDivInputAddNewCast}  type="text" placeholder="Title" />
                        </div>

                        <div className={classes.fourthDivInputCoverAddNewCast}>
                            <textarea onChange={this.handleChangeInputs} name="description" className={classes.fourthDivInputAddNewCast} placeholder="Description" cols="30" rows="30"></textarea>
                        </div>

                        <div className={classes.thirdDivCoverAddNewCast}>
                            <input onChange={this.handleChangeInputs} name="phone_number"  className={classes.thirdDivInputAddNewCast}  type="text" placeholder="Phone number" />
                        </div>

                        <div className={classes.thirdDivCoverAddNewCast}>
                            <input onChange={this.handleChangeInputs}  name="optional_phone_number" className={classes.thirdDivInputAddNewCast}  type="text" placeholder="Additional phone number" />
                        </div>

                        <div className={classes.thirdDivCoverAddNewCast}>
                            <input onChange={this.handleChangeInputs} name="email" className={classes.thirdDivInputAddNewCast}  type="text" placeholder="Email" />
                        </div>


                        <div onClick={this.handleClickOpen} className={classes.secondDivCoverAddNewCast}>
                            <input className={classes.secondDivInputAddNewCast} value={type} type="text" disabled />
                            <p className={classes.secondDivInputPlaceAddNewCast}>Type :</p>
                            <div className={classes.secondDivInputArrowAddNewCast}>
                                <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                    <KeyboardArrowDownRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open} onClose={this.handleClose}>
                                <DialogTitle className={classes.fontEn}>Select type</DialogTitle>
                                <DialogContent>

                                    <form className={classes.container}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-dialog-select-label">Type</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label"
                                                id="demo-dialog-select"
                                                value={type}
                                                onChange={this.handleChangeType}
                                                input={<Input />}
                                            >
                                                {
                                                    appInitData.groups && appInitData.groups.map((group , index) => {
                                                       return <MenuItem key={index} value={group.title}>{group.title}</MenuItem>
                                                    })
                                                }

                                            </Select>
                                        </FormControl>
                                    </form>

                                </DialogContent>
                                <DialogActions>
                                    <Button className={classes.fontEn} onClick={this.handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button className={classes.fontEn} onClick={this.handleClose} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div onClick={this.handleClickOpen1} className={classes.secondDivCoverAddNewCast}>
                            <input className={classes.secondDivInput1AddNewCast} value={category} type="text" disabled />
                            <p className={classes.secondDivInputPlaceAddNewCast}>Category :</p>
                            <div className={classes.secondDivInputArrowAddNewCast}>
                                <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                    <KeyboardArrowDownRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open1} onClose={this.handleClose1}>
                                <DialogTitle className={classes.fontEn}>Select category</DialogTitle>
                                <DialogContent>

                                    <form className={classes.container}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-dialog-select-label1">Category</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label1"
                                                id="demo-dialog-select2"
                                                value={category}
                                                onChange={this.handleChangeCategory}
                                                input={<Input />}
                                            >

                                                {
                                                    subgroups && subgroups.map((subgroup , index) => {
                                                        return <MenuItem key={index} value={subgroup.title}>{subgroup.title}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </form>

                                </DialogContent>
                                <DialogActions>
                                    <Button className={classes.fontEn} onClick={this.handleClose1} color="primary">
                                        Cancel
                                    </Button>
                                    <Button className={classes.fontEn} onClick={this.handleClose1} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div onClick={this.handleClickOpen2} className={classes.secondDivCoverAddNewCast}>
                            <input className={classes.secondDivInput2AddNewCast} value={country} type="text" disabled />
                            <p className={classes.secondDivInputPlaceAddNewCast}>Country :</p>
                            <div className={classes.secondDivInputArrowAddNewCast}>
                                <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                    <KeyboardArrowDownRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open2} onClose={this.handleClose2}>
                                <DialogTitle className={classes.fontEn}>Select country</DialogTitle>
                                <DialogContent>

                                    <form className={classes.container}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-dialog-select-label2">Country</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label2"
                                                id="demo-dialog-select2"
                                                value={country}
                                                onChange={this.handleChangeCountry}
                                                input={<Input />}
                                            >
                                                {
                                                    appInitData.countries && appInitData.countries.map((country , index) => {
                                                        return <MenuItem key={index} value={country.title}>{country.title}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </form>

                                </DialogContent>
                                <DialogActions>
                                    <Button className={classes.fontEn} onClick={this.handleClose2} color="primary">
                                        Cancel
                                    </Button>
                                    <Button className={classes.fontEn} onClick={this.handleClose2} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div onClick={this.handleClickOpen3} className={classes.secondDivCoverAddNewCast}>
                            <input className={classes.secondDivInput3AddNewCast} value={city} type="text" disabled />
                            <p className={classes.secondDivInputPlaceAddNewCast}>City :</p>
                            <div className={classes.secondDivInputArrowAddNewCast}>
                                <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                    <KeyboardArrowDownRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open3} onClose={this.handleClose3}>
                                <DialogTitle className={classes.fontEn}>Select city</DialogTitle>
                                <DialogContent>

                                    <form className={classes.container}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-dialog-select-label3">City</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label3"
                                                id="demo-dialog-select2"
                                                value={city}
                                                onChange={this.handleChangeCity}
                                                input={<Input />}
                                            >
                                                {
                                                    cities && cities.map((city , index) => {
                                                        return <MenuItem key={index} value={city.title}>{city.title}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </form>

                                </DialogContent>
                                <DialogActions>
                                    <Button className={classes.fontEn} onClick={this.handleClose3} color="primary">
                                        Cancel
                                    </Button>
                                    <Button className={classes.fontEn} onClick={this.handleClose3} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div onClick={this.handleClickOpen4} className={classes.secondDivCoverAddNewCast}>
                            <input className={classes.secondDivInput2AddNewCast} value={duration} type="text" disabled />
                            <p className={classes.secondDivInputPlaceAddNewCast}>Duration :</p>
                            <div className={classes.secondDivInputArrowAddNewCast}>
                                <IconButton className={classes.arrowIcon} aria-haspopup="true"  color="inherit">
                                    <KeyboardArrowDownRoundedIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Dialog classes={{paper:classes.paperRoot}} disableBackdropClick disableEscapeKeyDown open={open4} onClose={this.handleClose4}>
                                <DialogTitle className={classes.fontEn}>Select Duration</DialogTitle>
                                <DialogContent>

                                    <form className={classes.container}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-dialog-select-label3">Duration</InputLabel>
                                            <Select
                                                labelId="demo-dialog-select-label3"
                                                id="demo-dialog-select2"
                                                value={duration}
                                                onChange={this.handleChangeDuration}
                                                input={<Input />}
                                            >
                                                <MenuItem value={"One week"}>One week</MenuItem>
                                                <MenuItem value={"Two week"}>Two week</MenuItem>
                                                <MenuItem value={"One month"}>One month</MenuItem>
                                                <MenuItem value={"Three months"}>Three months</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </form>

                                </DialogContent>
                                <DialogActions>
                                    <Button className={classes.fontEn} onClick={this.handleClose4} color="primary">
                                        Cancel
                                    </Button>
                                    <Button className={classes.fontEn} onClick={this.handleClose4} color="primary">
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>


                        <div  className={classes.otherTitleCoverDivAddNewCast}>
                            <h3> Gender</h3>
                        </div>

                        <div  className={classes.thirdDivGenderCoverAddNewCast}>
                            <div className={classes.thirdDivGenderCoverAddNewCast}>
                                <Radio
                                    classes={{root: classes.thirdDivGenderRadioAddNewCast}}
                                    checked={selectedValueGender === 'Male'}
                                    onChange={()=>{this.handleChangeGender("Male")}}
                                    value="Male"
                                    name="radio-button-demo"
                                    size="small"
                                />
                                <p className={classes.thirdDivGenderRadioLabelAddNewCast}>Male</p>
                            </div>
                            <div className={classes.thirdDivGenderCoverAddNewCast}>
                                <Radio
                                    classes={{root: classes.thirdDivGenderRadioAddNewCast}}
                                    checked={selectedValueGender === 'Female'}
                                    onChange={()=>{this.handleChangeGender("Female")}}
                                    value="Female"
                                    name="radio-button-demo"
                                    size="small"
                                />
                                <p className={classes.thirdDivGenderRadioLabelAddNewCast}>Female</p>
                            </div>
                            <div className={classes.thirdDivGenderCoverAddNewCast}>
                                <Radio
                                    classes={{root: classes.thirdDivGenderRadioAddNewCast}}
                                    checked={selectedValueGender === 'Others'}
                                    onChange={()=>{this.handleChangeGender("Others")}}
                                    value="Others"
                                    name="radio-button-demo"
                                    size="small"
                                />
                                <p className={classes.thirdDivGenderRadioLabelAddNewCast}>Others</p>
                            </div>
                            <div className={classes.thirdDivGenderCoverAddNewCast}>
                                <Radio
                                    classes={{root: classes.thirdDivGenderRadioAddNewCast}}
                                    checked={selectedValueGender === 'All'}
                                    onChange={()=>{this.handleChangeGender("All")}}
                                    value="All"
                                    name="radio-button-demo"
                                    size="small"
                                />
                                <p className={classes.thirdDivGenderRadioLabelAddNewCast}>All</p>
                            </div>
                        </div>


                        <div  className={classes.otherTitleCoverDivAddNewCast}>
                            <h3> Age</h3>
                        </div>

                        <div className={classes.fourthDivCharAddNewCast}>

                            <div className={classes.fourthDivCharItemCoverAddNewCast}>
                                <div id="ageType0" onClick={ ()=> {this.handleClickAge(0,8,"All")}} className={classes.fourthDivAgeItemAddNewCast}>All</div>
                                <div id="ageType1" onClick={ ()=> {this.handleClickAge(1,8,"4,10")}} className={classes.fourthDivAgeItemAddNewCast}>4-10</div>
                                <div id="ageType2" onClick={ ()=> {this.handleClickAge(2,8,"10,18")}} className={classes.fourthDivAgeItemAddNewCast}>10-18</div>
                                <div id="ageType3" onClick={ ()=> {this.handleClickAge(3,8,"18,24")}} className={classes.fourthDivAgeItemAddNewCast}>18-24</div>
                                <div id="ageType4" onClick={ ()=> {this.handleClickAge(4,8,"24,30")}} className={classes.fourthDivAgeItemAddNewCast}>24-30</div>
                                <div id="ageType5" onClick={ ()=> {this.handleClickAge(5,8,"30,40")}} className={classes.fourthDivAgeItemAddNewCast}>30-40</div>
                                <div id="ageType6" onClick={ ()=> {this.handleClickAge(6,8,"40,50")}} className={classes.fourthDivAgeItemAddNewCast}>40-50</div>
                                <div id="ageType7" onClick={ ()=> {this.handleClickAge(7,8,"50,200")}} className={classes.fourthDivAgeItemAddNewCast}>+50</div>
                            </div>

                        </div>


                        <div  className={classes.otherTitleCoverDivAddNewCast}>
                            <h3> Payment Detail</h3>
                        </div>

                        <div className={classes.currencyDivEditRate}>
                            <div className={classes.currencyCoverDivEditRate}>
                                <div id="currency1" onClick={ ()=> {this.handleClickCurrency(1,2)}} className={classes.currencyItem1DivEditRate}><h2>USD</h2></div>
                                <div id="currency2" onClick={ ()=> {this.handleClickCurrency(2,1)}} className={classes.currencyItem2DivEditRate}><h2>EUR</h2></div>
                            </div>
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="hourlyRateP">Hourly Rate : {hourlyRate===0?"Negotiation":hourlyRate}</p>
                            </div>
                            <Slider onChange={ this.handelRangeHourlyRate } className={classes.thirdDivRangeEditProfile}   min={0} max={200} defaultValue={hourlyRate}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="dailyRateP">Daily Rate : {dailyRate===0?"Negotiation":dailyRate}</p>
                            </div>
                            <Slider onChange={ this.handelRangeDailyRate} className={classes.thirdDivRangeEditProfile}   min={0} max={500} defaultValue={dailyRate}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="monthlyRateP">Monthly Rate : {monthlyRate===0?"Negotiation":monthlyRate}</p>
                            </div>
                            <Slider onChange={ this.handelRangeMonthlyRate } className={classes.thirdDivRangeEditProfile}   min={0} max={20000} defaultValue={monthlyRate}  />
                        </div>

                        <div className={classes.thirdDivInputRangeEditProfile}>
                            <div>
                                <p id="yearlyRateP">Yearly Rate : {yearlyRate===0?"Negotiation":yearlyRate}</p>
                            </div>
                            <Slider onChange={ this.handelRangeYearlyRate } className={classes.thirdDivRangeEditProfile}   min={0} max={200000} defaultValue={yearlyRate}  />
                        </div>

                        <div className={classes.fourthDivInputCoverAddNewCast}>
                            <textarea onChange={this.handleChangeInputs} name="payment" className={classes.fourthDivInputAddNewCast} placeholder="Payment Description (optional)" cols="30" rows="30"></textarea>
                        </div>

                        <div  className={classes.AddNewCastBtnCoverDivAddNewCast}>
                            <Button  onClick={ ()=> {this.handleClickPublish()}} variant="contained"  className={classes.searchButton} color="default" component="span">
                                Publish Cast
                            </Button>
                        </div>

                    </div>

                </div>

                <Backdrop className={classes.backdrop} open={loading} onClick={this.handleCloseBackDrop}>
                    <div className={classes.coverProgress}>
                        <img className={classes.backdropImg} src="/images/logo.png" alt=""/>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>
            </div>

        );
    }
}

AddNewCastComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        appInitData : state.appInitData,
        auth : state.auth,
        loading : state.loadingProps.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps,{addNewCast,changeLoadingStatus,changeSnackStatus})(AddNewCastComponent));